import { Box, Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { ButtonCustom } from '../../../Components/Buttons/ButtonCustom';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { ItemDetail } from './ItemDetail';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { dateMask, firstName } from '../../../Helpers/Masks';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import GrayButton from '../../../Components/LoginBtns/GrayButton';
import OfferSelectionContext from '../../../Contexts/OfferSelection/OfferSelectionContext';
import MaskMoney from '../OfferSelection/MaskMoney';
import { cleanSteps } from '../../../Services/BiometricDataService';

export const SummaryBodyDetails = () => {
    const { returnToConfig, creditDetails, personName, details } = useContext(OfferSelectionContext);
    const navigate = useNavigate();

    const title = `¡Ya falta poco ${firstName(personName)}!`;
    const description = 'Avancemos con esta configuración.';

    //TODO: Borra avances de biometria
    const handleReset = () =>{
        cleanSteps();
    }


    useEffect(() => {
        const getDetails = async () => {
            await creditDetails();
        };

        getDetails();
    }, []);

    const returnConfig = () => {
        returnToConfig('summary');
    };

    const goToNextPage = () => {
        navigate(RoutesConstants.LOAN_TYPE_OCUPPATION);
    };

    return (
        <Grid container justifyContent="flex-start">
            <DescriptionForm
                title={title}
                description={description}
                fontSizeDescription="24px"
                fontFamilyDescription="Public Sans"
            />

            <ItemDetail
                key={'amount'}
                amount={MaskMoney(details.amount.toLocaleString('es-AR'))}
                description={'Monto total que te vamos a prestar'}
            />
            <ItemDetail
                key={'quantityRate'}
                amount={`${details.quantityRate} cuotas`}
                description={`Pagas la primera cuota el ${dateMask(details.deadline.expirationDate)}`}
            />
            <ItemDetail
                key={'amountRate'}
                amount={MaskMoney(details.amountRate.toLocaleString('es-AR'))}
                description={'Valor cuota por mes'}
                summary
                inverted
            />

            <Grid container>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={9} ml={2}>
                    <ButtonCustom
                        border
                        bg="transparent"
                        color={ColorConstants.BLUE}
                        iconPosition={'start'}
                        icon={<ArrowBackIcon />}
                        padding={'10px 12px 0px 5px'}
                        height={'35px'}
                        text="Cambiar configuración"
                        fontSize="14px"
                        width={'200px'}
                        action={() => returnConfig()}
                    />
                </Grid>
            </Grid>

            <Box width={'100%'} mt={5}>
                <GrayButton text={'¡Lo quiero ahora!'} action={() => goToNextPage()} />
            </Box>

            <BackHomeButton
                action={() => handleReset()}/>
        </Grid>
    );
};
