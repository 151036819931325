import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import ChangePasswordContext from './ChangePasswordContext';
import { getItemService } from '../../Services/LocalStorageService';
import {
    changePasswordRequestEmail,
    tokenTimeValidate,
    changePasswordService,
    resendEmailService
} from '../../Services/ChangePasswordService';
import { ChangePasswordReducer, initialState } from './ChangePasswordReducer';
import Actions, { ChangePasswordConstantsLS } from './Actions';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import jwt from 'jwt-decode';

export const ChangePasswordState = ({ children }) => {
    const [state, dispatch] = useReducer(ChangePasswordReducer, initialState);

    const navigate = useNavigate();

    const changePassword = async (newPassword) => {
        const token = getItemService(ChangePasswordConstantsLS.TokenChangePassword);
        const ecommerceAccountId = jwt(token).Ecommerce;
        await changePasswordService(ecommerceAccountId, newPassword);

        return true;
    };


    const resendEmailRequest = async () => {
        const response = await resendEmailService(state.userEmail);

        return response;
    };

    const changePasswordRequest = async (email) => {
        const response = await changePasswordRequestEmail(email);
        
        dispatch({ type: Actions.SET_USER_EMAIL, payload: email });
        navigate(RoutesConstants.NEW_PASSWORD_SUCCESS_EMAIL_PAGE);

        return response;
    };

    const tokenValidate = () => {
        const token = getItemService(ChangePasswordConstantsLS.TokenChangePassword);

        if (token == null) {
            return false;
        }

        return tokenTimeValidate(token);
    };

    return (
        <ChangePasswordContext.Provider
            value={{
                userEmail: state.userEmail,
                changePassword: changePassword,
                changePasswordRequest: changePasswordRequest,
                tokenValidate: tokenValidate,
                resendEmailRequest: resendEmailRequest,
            }}>
            {children}
        </ChangePasswordContext.Provider>
    );
};

ChangePasswordState.propTypes = {
    children: PropTypes.element
};
