import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MoneyIcon from '../../../../Assets/Icons/MoneyIcon';
import DebitCard from '../../../../Assets/Icons/DebitCard';

const OptionButton = styled(Button)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    backgroundColor: 'white',
    borderRadius: '16px',
    width: '100%',
    margin: '8px 0px',
    padding: '16px',
    ':hover': {
        backgroundColor: '#FFFFFF'
    }
}));

const ButtonMethod = ({ title, description, selected, value, onClick, icon, card }) => {
    const [active, setActive] = useState(false);
    const styleActive = {
        backgroundColor: '#FFFFFF',
        border: '2px solid',
        borderColor: '#47D14B'
    };

    useEffect(() => {
        setActive(selected === value);
    }, [selected]);

    return (
        <OptionButton
            variant="contained"
            card={card}
            sx={active ? styleActive : null}
            onClick={() => onClick(value)}>
            <Grid container alignItems={card ? 'center' : ''}>
                <Grid item xs={2} display="flex">
                    {icon == 'MoneyIcon' ? (
                        <MoneyIcon fontSize="large" />
                    ) : (
                        <DebitCard fontSize="large" />
                    )}
                </Grid>
                <Grid item xs={10} justifyContent='start' justifyItems={'start'}>
                    <Typography
                        fontFamily={'Archivo'}
                        fontWeight={700}
                        color="primary"
                        fontSize="18px"
                        display="flex"
                        textAlign={'start'}
                        justifyContent={'start'}
                        lineHeight="24px">
                        {title}
                    </Typography>
                    <Typography
                        fontFamily={'Archivo'}
                        fontWeight={400}
                        color="secondary"
                        lineHeight="20px"
                        display="flex"
                        textAlign={'start'}>
                        {description}
                    </Typography>
                </Grid>
            </Grid>
        </OptionButton>
    );
};

export default ButtonMethod;
