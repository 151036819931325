import React from 'react';
import MobileLayout from '../../../Components/Layout/MobileLayout';
import { Outlet } from 'react-router-dom';
import { ApprovedLoanState } from '../../../Contexts/ApprovedLoan/ApprovedLoanState';

export const ApprovedLoanPage = () => {
    return (
        <ApprovedLoanState>
            <MobileLayout Form={<Outlet />} approvedLoan />
        </ApprovedLoanState>
    );
};
