import { useState } from 'react';

export const useTimer = (seconds) => {
    const [timerId, setTimerId] = useState();

    const timerAction = (action) => {
        clearTimeout(timerId);

        let id = setTimeout(() => {
            action();
        }, seconds * 1000);

        setTimerId(id);
    };

    return { timerAction };
};
