import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AppContext from '../Contexts/Global/AppContext';
import { RoutesConstants } from './../Constants/RoutesConstants';

const PublicRoute = () => {
    const { authentication } = useContext(AppContext);

    return !authentication ? <Outlet /> : <Navigate to={RoutesConstants.PRIVATE_HOME} />;
};

export default PublicRoute;
