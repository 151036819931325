import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { except_pages_chatBox } from '../../Configs/exceptionPaths';

const AsyncScriptWrapper = ({ children }) => {
    const location = useLocation();
    const [show, setShow] = useState(false);

    useEffect(() => {
        deleteScript();
    }, []);

    useEffect(() => {
        if (
            except_pages_chatBox.includes(location.pathname) ||
            parent.top.document.getElementsByTagName('iframe').length > 0
        ) {
            setShow(false);
            deleteScript();
        } else {
            setShow(true);
        }
    }, [location.pathname]);

    const deleteScript = () => {
        const script = document.body.getElementsByClassName('s1-script');
        if (script.length > 0) {
            script[0]?.remove();
        }
        const chat = document.body.getElementsByClassName('s1-channel-active');
        if (chat.length > 0) {
            chat[0]?.remove();
        }
        const scriptGlobal = parent.top.document.body.getElementsByClassName('s1-script');
        if (scriptGlobal.length > 0) {
            scriptGlobal[0]?.remove();
        }
        const chatGlobal = parent.top.document.body.getElementsByClassName('s1-channel-active');
        if (chatGlobal.length > 0) {
            chatGlobal[0]?.remove();
        }
        const scriptSelf = parent.document.body.getElementsByClassName('s1-script');
        if (scriptSelf.length > 0) {
            scriptSelf[0]?.remove();
        }
        const chatSelf = parent.document.body.getElementsByClassName('s1-channel-active');
        if (chatSelf.length > 0) {
            chatSelf[0]?.remove();
        }
        const scriptSelf2 = parent.parent.document.body.getElementsByClassName('s1-script');
        if (scriptSelf2.length > 0) {
            scriptSelf2[0]?.remove();
        }
        const chatSelf2 = parent.parent.document.body.getElementsByClassName('s1-channel-active');
        if (chatSelf2.length > 0) {
            chatSelf2[0]?.remove();
        }
    };

    return (
        <>
            {!show ? (
                <>{children}</>
            ) : (
                <>
                    <Helmet>
                        <script
                            className="s1-script"
                            src="https://directoarg.s1gateway.com/pe/H5KnDt8t9gbj"
                            async
                            defer></script>
                    </Helmet>
                    {children}
                </>
            )}
        </>
    );
};

AsyncScriptWrapper.displayName = 'AsyncScriptWrapper';

export { AsyncScriptWrapper };
