import React from 'react';
import { Box } from '@mui/material';
import { DescriptionForm } from '../../Components/DescriptionsLayout';
import { QRCode } from './QRCode';
import { useEffect } from 'react';
import { useState } from 'react';
import { getItemService } from '../../Services/LocalStorageService';
import { firstName } from '../../Helpers/Masks';
import BackHomeButton from '../../Components/Buttons/BackHomeButton';

export const RedirectPage = () => {
    const [name, setName] = useState('');

    useEffect(() => {
        let completedName = getItemService('Person')
            ? JSON.parse(getItemService('Person')).personName
            : null;
        if (completedName) {
            completedName = firstName(completedName);
            setName(completedName);
        }
    }, []);

    const title = 'Ingresar desde el celular';
    const description = `${
        name ? name + ', detectamos' : 'Detectamos'
    } que estás ingresando desde tu computadora. Vamos a pedirte que lo hagas nuevamente pero desde tu celular
    para tener una mejor experiencia y acompañarte en ella. Nos volvemos a ver por ahí. ¡Gracias!`;

    return (
        <Box textAlign={'center'}>
            <DescriptionForm title={title} description={description} />
            <QRCode />
            <BackHomeButton />
        </Box>
    );
};
