import React from 'react'
import { Grid, Paper, useTheme, useMediaQuery} from '@mui/material'
import { TitleCustom } from '../../Components/TitleCustom'
import { setItemService } from '../../Services/LocalStorageService'
import { useNavigate } from 'react-router-dom'
import { RoutesConstants } from '../../Constants/RoutesConstants'

export const CompletedLoanCard = ({size = '24px', credit, index, totalCredits}) => {

    const theme = useTheme();
    const desktop  = useMediaQuery(theme.breakpoints.up("sm"));
    const navigate = useNavigate();
    
    const getDetail = async() =>
    {
        setItemService('creditDetailId', credit.requestId);
        setItemService('indexCredit', index + 1 + totalCredits);
        navigate(RoutesConstants.LOAN_ACTIVE_PAGE);
    }

    return (
        <Grid>
            <Paper elevation={1} sx={{borderRadius: '32px', width:'100%', 
                padding: desktop ? '24px' : '18px', mb: 5, cursor: 'pointer'
                }}
            onClick={() => getDetail()}
            >
                <Grid container>
                    <Grid item xs={1} mr={2.8} >
                        <img src={'iconCompletedLoan.svg'} className='completed-loan' />
                    </Grid>
                    <Grid item xs={10}>
                        <TitleCustom
                            size={size}
                            sizeSubtitle = {!desktop && '12px !important'}
                            title={`Préstamo ${index + 1 + totalCredits}: ${credit.commerce}`}
                            subtitle={`Importe total $${credit.creditAmount} / ${credit.quantityRate} cuotas 
                            de $${credit.creditProgress ? credit.creditProgress[0].amountRate : 0}`}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
