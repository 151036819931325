/* eslint-disable no-unused-vars */
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import {
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useContext, useState } from 'react';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import ErrorMessage from '../../../Components/Form/ErrorMessage';
import InformationLabel from '../../../Components/Form/InformationLabel';
import GrayButton from '../../../Components/LoginBtns/GrayButton';
import { ColorConstants } from '../../../Constants/ColorConstants';
import LoanRequestContext from '../../../Contexts/LoanRequest/LoanRequestContext';
import { validationCBU } from '../../../Helpers/Validations';
import ErrorLabel from '../../../Components/Form/ErrorLabel';
import { RoutesConstants } from '../../../Constants/RoutesConstants';

const FormCBU = () => {
    const {
        validateCBU,
        errorResponseCBU,
        getCBU,
        allFailedAttempts,
        setAllFailedAttempts,
        setErrorResponseCBU,
        setFailedAttemptCBU
    } = useContext(LoanRequestContext);
    const [CBU, setCBU] = useState('');
    const [errorCBU, setErrorCBU] = useState(false);
    const [validCBU, setValidCBU] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    const preloadCBU = async () => {
        const response = await getCBU();
        if (response.cbu != null) {
            handleSetCBU(response.cbu);
        }
    };

    useEffect(() => {
        preloadCBU();
    }, []);

    const handleSetCBU = (value) => {
        setValidCBU(validationCBU(value));
        if (value.length < 23) {
            setAllFailedAttempts(false);
            setErrorResponseCBU(false);
            setFailedAttemptCBU(0);
            setErrorCBU(value != '' && value.length != 22);
            setCBU(value);
        }
    };

    const onSubmit = async () => {
        setErrorMessage(null);
        const res = await validateCBU(CBU);
        if (res?.response?.data?.errors) {
            setErrorMessage(res.response.data.errors[0].message);
        }
    };

    const descriptionFormLabel = 'Ingresá los 22 dígitos';
    const notMatchTitle = 'Los datos no coinciden.';
    const notMatchDescription =
        'El CBU ingresado no coincide con tu identidad. Revisá que los datos sean correctos.';
    const notValidTitle = 'No pudimos validar tu CBU.';
    const notValidDescription = 'Te pedimos que revises los datos y lo vuelvas a intentar.';
    const descriptionInvalidCBU = 'El CBU ingresado no es válido';
    const allFailedAttemptsTitle = 'Los datos no coinciden.';
    const allFailedAttemptsDescription = (
        <>
            Por favor, comunicate con nuestro equipo{' '}
            <Link
                href="https://wa.me/5491123700339?text=Hola,%20tengo%20un%20problema%20con%20mi%20CBU"
                target="_blank"
                rel="noreferrer"
                color={ColorConstants.RED}>
                vía WhatsApp
            </Link>{' '}
            para que podamos ayudarte.
        </>
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'space-between',
                height: '380px'
            }}>
            <Box width={'100%'}>
                <FormControl fullWidth sx={{ my: 1, borderRadius: '40px' }}>
                    <InputLabel htmlFor="CBU">Ingresá tu CBU</InputLabel>
                    <OutlinedInput
                        id="CBU"
                        value={CBU}
                        onChange={(e) => handleSetCBU(e.target.value)}
                        sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                        color="info"
                        startAdornment={
                            <InputAdornment position="start">
                                <CreditCardOutlinedIcon />
                            </InputAdornment>
                        }
                        inputProps={{
                            inputMode: 'numeric'
                        }}
                        label="Ingresá tu CBU"
                        error={errorResponseCBU}
                    />
                    {!validCBU ? <ErrorLabel description={descriptionInvalidCBU} /> : null}
                </FormControl>
                {errorCBU && <InformationLabel description={descriptionFormLabel} />}
            </Box>
            <Box width={'100%'}>
                {errorMessage && (
                    <ErrorMessage
                        title={
                            errorMessage?.includes('datos sean correctos')
                                ? 'Los datos no coinciden.'
                                : 'No pudimos validar tu CBU.'
                        }
                        description={errorMessage}
                    />
                )}
                <GrayButton
                    text={'Continuar'}
                    action={() => onSubmit()}
                    disabled={CBU == '' || errorCBU || !validCBU}
                />

                <BackHomeButton />
            </Box>
        </Box>
    );
};

export default FormCBU;
