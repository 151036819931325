import { getData, postData, putData } from './BaseService';

export const getPersonByDNI = async ({ dni, sexTypeId }) => {
    const url = 'person/bydni';
    const query = `?Dni=${dni}&SexTypeId=${sexTypeId}`;
    const response = await getData(url, query);
    return response;
};

export const checkAccountService = async (body) => {
    const url = 'person/checkAccount';
    const response = await postData(url, body);

    return response;
};

export const sendSMSCode = async (body) => {
    const url = 'sms/sendCode';
    let response = await postData(url, body);
    return response;
};

export const siisaValidationService = async ({ id, document, fingerPrint, creditNumber }) => {
    const url = 'general/siisaValidation';
    const path = window.location.pathname;

    if (creditNumber) {
        let response = await postData(url, {
            id: Number(creditNumber),
            document: Number(document),
            fingerPrint,
            path
        });
        return response;
    }

    let response = await postData(url, {
        id: Number(id),
        document: Number(document),
        fingerPrint,
        path
    });
    return response;
};

export const verifySMSCodeService = async (body) => {
    const url = 'sms/verifyCode';
    let response = await postData(url, body);
    return response;
};

export const savePhoneNumberService = async ({ personId, numCelular, email }) => {
    const url = 'register/change/emailPhone';
    if (email) {
        let response = await putData(url, { personId, numCelular, email });
        return response;
    }
    let response = await putData(url, { personId, numCelular });
    return response;
};

export const getOccupationsService = async () => {
    const url = 'occupation';
    let response = await getData(url);
    return response;
};

export const sendPaymentInfomationService = async (paymentData) => {
    const url = 'payment/saveinfo';
    let response = await postData(url, paymentData);
    return response;
};

export const validateCBUService = async ({ pendingRequestId, cbu }) => {
    const url = 'payment/checkcbu';
    const response = await postData(url, { pendingRequestId, cbu });

    return response;
};

export const getCBUService = async (personId) => {
    const url = `payment/cbuinformation/${personId}`;
    const response = await getData(url);

    return response;
};

export const requestLoan = async (body) => {
    const url = 'credit/pending/rating';
    let response = await postData(url, body);
    return response;
};

export const creditDetailsService = async (pendingRequestId) => {
    const url = `credit/pending/details/${pendingRequestId}`;
    let response = await getData(url);
    return response;
};

export const confirmLoanService = async ({ pendingRequestId, debitPayment }) => {
    const url = 'credit/pending/confirm';
    const response = await postData(url, { pendingRequestId, debitPayment });

    return response;
};

export const getDataOfRequestLoan = async (pendingRequestId) => {
    const url = `credit/pending/confirm/details/${pendingRequestId}`;
    let response = await getData(url);
    return response;
};

export const getDetailsOfTyC = async () => {
    const url = `credit/pending/confirm/detailsTyC`;
    let response = await getData(url);
    return response;
};
