import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    getItemService,
    removeItemService,
    setItemService
} from '../../Services/LocalStorageService';
import { getData } from '../../Services/BaseService';
import LoanRequestContext from './LoanRequestContext';
import {
    getCBUService,
    getOccupationsService,
    savePhoneNumberService,
    sendPaymentInfomationService,
    sendSMSCode,
    siisaValidationService,
    validateCBUService,
    verifySMSCodeService
} from '../../Services/LoanRequestService';
import { requestLoan } from '../../Services/LoanRequestService';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { LoanRequestConstantsLS } from './LoanRequestActions';
import { getAllowedMethodsService } from '../../Services/PaymentMethodService';
import { DetectMobile } from '../../Utils/DetectMobile';
import { useLocation } from 'react-router-dom';

export const LoanRequestState = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [options, setOptions] = useState({ provinces: [] });
    const [loanData, setLoanData] = useState(
        getItemService(LoanRequestConstantsLS.LoanData)
            ? JSON.parse(getItemService(LoanRequestConstantsLS.LoanData))
            : null
    );
    const [verifySMSCodeSuccess, setVerifySMSCodeSuccess] = useState(false);
    const [errorToken, setErrorToken] = useState(false);
    const [errorResponseCBU, setErrorResponseCBU] = useState(false);
    const [failedAttemptCBU, setFailedAttemptCBU] = useState(0);
    const [allFailedAttempts, setAllFailedAttempts] = useState(false);
    const [occupationsList, setOccupationsList] = useState([]);
    const [questionTitle, setQuestionTitle] = useState(
        getItemService('QuestionTitle') ? JSON.stringify(getItemService('QuestionTitle')) : []
    );
    const [maxAttempt, setMaxAttempt] = useState(null);
    const [data, setData] = useState(
        getItemService(LoanRequestConstantsLS.PersonToGetLoan)
            ? JSON.parse(getItemService(LoanRequestConstantsLS.PersonToGetLoan))
            : null
    );
    const [paymentInformation, setPaymentInformation] = useState(
        getItemService(LoanRequestConstantsLS.PaymentInformation)
            ? JSON.parse(getItemService(LoanRequestConstantsLS.PaymentInformation))
            : null
    );

    useEffect(() => {
        checkMobile();
    }, [location.pathname]);

    const getInitData = async () => {
        let url = 'province';
        let provinces = await getData(url);
        setOptions({ provinces: provinces });
    };

    const savePerson = async (person) => {
        setItemService(LoanRequestConstantsLS.PersonToGetLoan, JSON.stringify(person));
        setData(person);
        navigate(RoutesConstants.LOAN_CONTACT_INFORMATION_PAGE);
    };

    const checkMobile = async () => {
        const isMobile = await DetectMobile();
        if (isMobile === false) {
            navigate(RoutesConstants.LOAN_REDIRECT_PAGE);
        }
    };

    const siisaValidation = async (pendingRequestId) => {
        const code = getItemService(LoanRequestConstantsLS.SiisaCode);
        const localLoanData = getItemService(LoanRequestConstantsLS.LoanData);

        const response = await siisaValidationService({
            id: pendingRequestId || loanData?.pendingRequestId || localLoanData?.pendingRequestId,
            document: data.dni,
            fingerPrint: code
        });

        if (!response.data.approved) {
            const allowedMethods = await getAllowedMethodsService({
                dni: data.dni,
                sexTypeId: data.sexTypeId
            });

            if (allowedMethods.paymentId == 1) {
                setItemService(LoanRequestConstantsLS.ClientValidation, true);
            } else {
                setItemService(LoanRequestConstantsLS.ClientValidation, false);
            }
        } else {
            setItemService(LoanRequestConstantsLS.ClientValidation, true);
        }
    };

    const saveTotalData = async (form) => {
        const newInformation = {
            ...data,
            phoneNumberComplete: form.area + form.phoneNumber,
            phoneCode: form.area,
            phoneNumber: form.phoneNumber,
            email: form.email,
            provinceId: form.province
        };
        const personToGetLoan = getItemService(LoanRequestConstantsLS.PersonToGetLoan)
            ? JSON.parse(getItemService(LoanRequestConstantsLS.PersonToGetLoan))
            : null;
        setItemService(LoanRequestConstantsLS.PersonToGetLoan, JSON.stringify(newInformation));
        setData(newInformation);

        try {
            let requestLoanData;
            if (!loanData?.recommendation) {
                requestLoanData = await requestLoan({
                    name: data.personName,
                    dni: data.dni,
                    sexTypeId: data.sexTypeId,
                    personId: personToGetLoan?.personId || 0
                });
                await siisaValidation(requestLoanData.data.pendingRequestId);
                setLoanData(requestLoanData.data);
                setItemService(
                    LoanRequestConstantsLS.LoanData,
                    JSON.stringify(requestLoanData.data)
                );
                if (
                    !requestLoanData.data.offer[0]?.maximumAmount ||
                    requestLoanData.data.offer[0]?.maximumAmount < 1000
                ) {
                    navigate(RoutesConstants.LOAN_NO_OFFERS, { replace: true });
                    return;
                }
            } else {
                requestLoanData = { data: loanData };
                await siisaValidation(loanData.pendingRequestId);
            }
            if (requestLoanData?.data?.pendingRequestId) {
                const resp = await sendSMSCode({
                    pendingRequestId: requestLoanData?.data?.pendingRequestId,
                    phoneNumber: form.area + form.phoneNumber
                });
                if (resp?.response?.status >= 400) {
                    return resp?.response?.data?.errors[0]?.message || resp;
                }
            }
            navigate(RoutesConstants.LOAN_TOKEN_PAGE);
        } catch (error) {
            console.log('error loan request', error);
            navigate(RoutesConstants.LOAN_NO_OFFERS);
        }
    };

    const resendSMSCode = async () => {
        setErrorToken(false);
        const response = await sendSMSCode({
            pendingRequestId: loanData.pendingRequestId,
            phoneNumber: data.phoneNumberComplete
        });

        navigate(RoutesConstants.LOAN_TOKEN_PAGE);
    };

    const verifySMSCode = async (code) => {
        if (loanData) {
            try {
                const verifyCode = await verifySMSCodeService({
                    pendingRequestId: loanData.pendingRequestId,
                    code: code
                });
                if (verifyCode?.response?.status >= 400) {
                    setErrorToken(true);
                    setVerifySMSCodeSuccess(false);
                    return;
                }
                await savePhoneNumberService({
                    personId: data.personId,
                    numCelular: data.phoneNumberComplete
                });

                setErrorToken(false);
                setVerifySMSCodeSuccess(true);
            } catch (error) {
                setErrorToken(true);
                setVerifySMSCodeSuccess(false);
            }
        }
    };

    const getOccupations = async () => {
        const response = await getOccupationsService();
        setOccupationsList(response);
    };

    const saveOccupation = (occupationId, question) => {
        setItemService(LoanRequestConstantsLS.PaymentInformation, JSON.stringify({ occupationId }));
        setItemService(LoanRequestConstantsLS.QuestionTitle, question);
        setQuestionTitle(question);
        setPaymentInformation({ occupationId });
    };

    const savePayDay = async (day) => {
        const paymentData = { ...paymentInformation, payDay: day, personId: data.personId };
        await sendPaymentInfomationService(paymentData);
        removeItemService(LoanRequestConstantsLS.QuestionTitle);
        removeItemService(LoanRequestConstantsLS.PaymentInformation);
        navigate(RoutesConstants.LOAN_CBU);
    };

    const getCBU = async () => {
        const response = await getCBUService(data.personId);

        setErrorResponseCBU(false);
        setMaxAttempt(response.attempt);
        return response;
    };

    const validateCBU = async (cbu) => {
        try {
            const cbuValidate = await validateCBUService({
                pendingRequestId: loanData.pendingRequestId,
                cbu
            });
            // eslint-disable-next-line no-undef
            if (!process.env.REACT_APP_CBU_VALIDATE) {
                if (cbuValidate?.response?.status >= 400) {
                    setErrorResponseCBU({ notValid: true });
                    return cbuValidate;
                }

                setErrorResponseCBU(false);
                goToNextCbuPage();

                return;
            } else {
                setErrorResponseCBU(false);
                goToNextCbuPage();
            }
        } catch (error) {
            //* No coincide con el DNI: -> setErrorCBU({ notValid: false });
            //* Error general: no se pudo validar el CBU
            setErrorResponseCBU({ notValid: true });

            /* if (failedAttemptCBU === maxAttempt - 1) {
                setAllFailedAttempts(true);
                setFailedAttemptCBU(failedAttemptCBU + 1);
            } else {
                setFailedAttemptCBU(failedAttemptCBU + 1);
            }
            if (failedAttemptCBU >= maxAttempt) {
                goToNextCbuPage();
            } */
            return error;
        }
    };

    const goToNextCbuPage = () => {
        const goToLastPage = getItemService(LoanRequestConstantsLS.ReturnCbuPage);
        if (goToLastPage) {
            removeItemService(LoanRequestConstantsLS.ReturnCbuPage);
            navigate(RoutesConstants.LOAN_DETAILS_PAGE);
            return;
        }
        navigate(RoutesConstants.LOAN_PAY_METHOD);
    };

    return (
        <LoanRequestContext.Provider
            value={{
                options,
                data,
                verifySMSCodeSuccess,
                errorToken,
                errorResponseCBU,
                occupationsList,
                questionTitle,
                allFailedAttempts,
                getCBU,
                getInitData,
                savePerson,
                saveTotalData,
                verifySMSCode,
                resendSMSCode,
                saveOccupation,
                getOccupations,
                savePayDay,
                validateCBU,
                setErrorToken,
                setErrorResponseCBU,
                setAllFailedAttempts,
                setFailedAttemptCBU
            }}>
            {children}
        </LoanRequestContext.Provider>
    );
};

LoanRequestState.propTypes = {
    children: PropTypes.element
};
