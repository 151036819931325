import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

export const GetIcon = (iconKey) => {
  switch (iconKey){
    case 'email': 
      return <EmailOutlinedIcon/>
    case 'person':
      return <PersonOutlineIcon/>
    case 'dni': 
      return <FeaturedPlayListOutlinedIcon/>
    case 'phone': 
      return <LocalPhoneOutlinedIcon/>
    case 'location': 
      return <LocationOnOutlinedIcon/>
  }
}
