import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../Styles/GlobalStyle.css'

export default function ControlledAccordions({title, information}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion elevation={0} expanded={expanded === 'panel'} 
      onChange={handleChange('panel')} 
      className='mb-30'>
        <AccordionSummary
          sx={{borderBottom: '1px solid #CEE6FF'}}
          expandIcon={<ExpandMoreIcon className="colorIcon"/>}
          aria-controls="panelbh-content"
          id="panelbh-header"
        >
          <Typography className='font-blue font-weight'>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className='accordion-details'>
          <Typography className='font-blue'>
            {information}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
