import { Box } from '@mui/system';
import { useContext } from 'react';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import RegisterContext from '../../../Contexts/Register/RegisterContext';
import FormExpiredLink from './FormExpiredLink';

export const ExpiredLinkRegister = () => {
    const { resendEmailRegisterWithToken } = useContext(RegisterContext);
    const titleForm = 'Tu link está vencido';
    const descriptionForm =
        'Ya pasaron más de los 10 minutos límites para usarlo, pero no te preocupes podemos enviarte uno nuevo a tu mail.';
    return (
        <Box style={{ textAlign: 'center' }}>
            <DescriptionForm title={titleForm} description={descriptionForm} />
            <FormExpiredLink actionSubmit={() => resendEmailRegisterWithToken()} disableEmailField/>
        </Box>
    );
};
