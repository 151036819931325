import React from 'react';
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import LoanActiveContext from '../../Contexts/LoanActive/LoanActiveContext';
import { GetStatusAndColorOfQuote } from '../../Helpers/GetDataOfRequest';
import { moneyMask } from '../../Helpers/Masks';
import DownloadableEvolutionButton from './Downloadable/DownloadableEvolutionButton';

export const LoanEvolution = () => {
    const { creditDetail } = useContext(LoanActiveContext);
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Grid container>
            <Grid container>
                <Typography
                    sx={{
                        fontSize: `${desktop ? '30px' : '22px'}`,
                        color: '#004489',
                        fontWeight: '600'
                    }}>
                    Evolución del préstamo
                </Typography>
            </Grid>
            <div className="divider-loan-active-title"></div>
            <Grid container>
                <Grid item xs={5}>
                    <Typography
                        sx={{
                            fontSize: `${sm ? '13px' : '14px'}`,
                            color: '#004489',
                            fontWeight: '600'
                        }}>
                        Vencimiento
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        sx={{
                            fontSize: `${sm ? '13px' : '14px'}`,
                            color: '#004489',
                            fontWeight: '600'
                        }}>
                        Cuota
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography
                        sx={{
                            textAlign: 'end',
                            fontSize: `${sm ? '13px' : '14px'}`,
                            color: '#004489',
                            fontWeight: '600'
                        }}>
                        Estado
                    </Typography>
                </Grid>
            </Grid>
            <div className="divider-loan-active"></div>
            {creditDetail &&
                creditDetail.credits.creditProgress.map((c) => (
                    <Grid container key={c.id + c.rates}>
                        <Grid item xs={5}>
                            <Typography
                                sx={{
                                    fontSize: `${sm ? '13px' : '14px'}`,
                                    color: '#004489',
                                    fontWeight: '600'
                                }}>
                                {c.date}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{
                                    fontSize: `${sm ? '13px' : '14px'}`,
                                    color: '#004489',
                                    fontWeight: '600'
                                }}>
                                {c.rates}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography
                                sx={{
                                    textAlign: 'end',
                                    fontSize: `${sm ? '13px' : '14px'}`,
                                    color: `${GetStatusAndColorOfQuote(c.status).color}`,
                                    fontWeight: '600'
                                }}>
                                {GetStatusAndColorOfQuote(c.status).textLabel}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography></Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: `${sm ? '13px' : '14px'}`, color: '#004489' }}>
                                Original
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {/* <Typography sx={{textAlign:'end', fontSize: `${sm? '13px': '14px'}`, color: '#004489'}}>{moneyMask(c.amountRate.toString())}</Typography> */}
                            <Typography
                                sx={{
                                    textAlign: 'end',
                                    fontSize: `${sm ? '13px' : '14px'}`,
                                    color: '#004489'
                                }}>
                                {c.amountRate.toLocaleString('es-AR', {
                                    style: 'currency',
                                    currency: 'ARS'
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography></Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                sx={{ fontSize: `${sm ? '13px' : '14px'}`, color: '#004489' }}>
                                Actualizada
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {/* <Typography
                                sx={{
                                    textAlign: 'end',
                                    fontSize: `${sm ? '13px' : '14px'}`,
                                    color: '#004489'
                                }}>
                                {moneyMask(c.amountPunitiveRate.toString())}
                            </Typography> */}
                            <Typography
                                sx={{
                                    textAlign: 'end',
                                    fontSize: `${sm ? '13px' : '14px'}`,
                                    color: '#004489'
                                }}>
                                {c.amountPunitiveRate.toLocaleString('es-AR', {
                                    style: 'currency',
                                    currency: 'ARS'
                                })}
                            </Typography>
                        </Grid>
                        <div className="divider-loan-active"></div>
                    </Grid>
                ))}
            <DownloadableEvolutionButton />
        </Grid>
    );
};
