import {
    postData,
} from './BaseService';

export const login = async (mail, contrasena) => {
    try {
        const mailLoginUrl = `login/mail`;
        const body = {
            mail: mail,
            contrasenia: contrasena
        }
        let response = await postData(mailLoginUrl, body);
        return response;
    } catch (error) {
        return error;
    }
}

export const externalLogin = async (body) => {
    try {
        const externalLoginUrl = `login/external`;
        let response = await postData(externalLoginUrl, body);
        return response;
    } catch (error) {
        return error;
    }
}


export const addExternalAccount = async (body) => {
    try{
        const url = 'register/addexternalaccount';
        const response = await postData(url, body);
        return response;
    }
    catch(error) {
        return error;
    }
}