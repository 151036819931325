import { Box } from '@mui/system';
import { useContext } from 'react';
import { DescriptionForm } from '../../Components/DescriptionsLayout';
import { DNIForm } from '../../Components/DNIForm';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import LoanRequestContext from '../../Contexts/LoanRequest/LoanRequestContext';

export const LoanRequestPage = () => {

    const title = '¡Sacá tu préstamo ahora!';
    const description =
        'Para comenzar, por favor ingresá tu DNI y así te ofreceremos la mejor opción.';
    const { savePerson } = useContext(LoanRequestContext);
    
    return (
        <Box textAlign={'center'}>
            <ReturnButton />
            <DescriptionForm title={title} description={description} />
            <DNIForm 
                action ={ 
                    savePerson
                }
            />
        </Box>
    );
};
