import { useContext, useState, useEffect } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import AccordionCustom from '../../Components/AccordionCustom';
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { TitleCustom } from '../../Components/TitleCustom';
import './PublicHomeStyle.css';
import { useNavigate } from 'react-router-dom';
import { ColorConstants } from '../../Constants/ColorConstants';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import HomeContext from '../../Contexts/Home/HomeContext';
import parse from 'html-react-parser';
import { MobileCheck } from '../../Helpers/MobileCheck';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export const DoubtsSection = () => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    const navigate = useNavigate();
    const { frequentQuestions } = useContext(HomeContext);
    const [faq, setFaq] = useState(null);

    useEffect(() => {
        setFaq(frequentQuestions);
    }, [frequentQuestions]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3} className="font-blue" sx={{ mt: 4 }}>
                <TitleCustom
                    size={MobileCheck() ? '24px' : '32px'}
                    title="¿Tenés más dudas?"
                    subtitle="¡No te preocupes! Estamos para ayudarte y para responder todo
                    lo que quieras saber."
                />
            </Grid>
            <Grid item xs={12} sm={7} sx={{ mt: 4.5, ml: desktop ? 7 : 0 }}>
                {faq &&
                    faq?.map((x) => {
                        return (
                            <AccordionCustom
                                key={x.question}
                                title={parse(x.question)}
                                information={parse(x.answere)}
                            />
                        );
                    })}
                {!faq && (
                    <SkeletonTheme width={'100%'} height={40}>
                        <Skeleton count={3} />
                    </SkeletonTheme>
                )}
            </Grid>
            <Grid item xs={12} sm={3} md={3} ml={7}></Grid>
            <Grid item xs={12} sm={7} md={7} sx={{ mb: 8 }}>
                <ButtonCustom
                    border
                    bg="transparent"
                    color={ColorConstants.BLUE}
                    icon={<ArrowForwardOutlinedIcon />}
                    text="Ver más preguntas frecuentes"
                    width="320px"
                    padding={desktop && MobileCheck() ? '12px 0px 12px 12px' : '12px 0px 12px 25px'}
                    action={() => navigate(RoutesConstants.FRECUENT_QUESTIONS_PAGE)}
                />
            </Grid>
            <Grid item xs={12} sm={12}></Grid>
            <Grid xs={12} mb={desktop ? 10 : 5} mt={desktop ? '' : -8}>
                <Grid
                    display="flex"
                    justifyContent="center"
                    width={'100%'}
                    maxHeight={desktop ? '100%' : '100%'}>
                    <img
                        src={desktop ? 'endArrowPublicHome.svg' : 'endArrowPublicHomeMobile.svg'}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
