import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { ImageCapture } from './Components/ImageCapture';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../Hooks/useDialog';
import { InformationModal } from '../../Components/Modal/InformationModal';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import { StepsConstants } from '../../Constants/StepsConstants';
import BiometricDataContext from '../../Contexts/BiometricData/BiometricDataContext';
import { GestureFaceConstants } from '../../Constants/GestureFaceConstants';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import smileFace from '../../Assets/smileface.png';
import { BiometricDataConstants } from '../../Constants/BiometricDataConstants';
import { TransitionStep } from './Components/TransitionStep';
import configSegundaSelfie from '../BiometricData/configSegundaSelfie.json';

export const ThirdStep = () => {
    const dialog = useDialog();
    const navigate = useNavigate();

    const { confirmPhoto, images, attempt, error, gestureType } = useContext(BiometricDataContext);

    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        images[StepsConstants.THIRD_STEP].confirmImage ? handleOpenModal() : '';
    }, []);

    const handleSetDataAndExecute = async () => {
        setErrorMessage(null);
        let data = {
            entreConsultasImage: [
                {
                    image: images[StepsConstants.THIRD_STEP].photo,
                    typeGesturePhoto: GestureFaceConstants.NEUTRAL_FACE
                }
            ],
            step: StepsConstants.THIRD_STEP,
            biometricDataType: BiometricDataConstants.NEUTRAL_FACE,
            extension: 'jpg'
        };
        /*  await confirmPhoto(data);
        handleOpenModal(); */

        try {
            const res = await confirmPhoto(data);
            if (res?.ErrorType && Number(attempt) < 2) {
                setErrorMessage({
                    title: res.message.split('.')[0],
                    description: res.message.split('.')[1]
                });
                return;
            }
            if (res.response?.data?.errors && Number(attempt) < 2) {
                setErrorMessage({
                    title: 'No pudimos validar tu identidad',
                    description: 'Te pedimos que vuelvas a tomar la foto.'
                });
                return;
            }
            handleOpenModal();
        } catch {
            console.log('Error..');
        }
    };

    const handleOpenModal = () => {
        let dataGesture;
        Object.keys(configSegundaSelfie).forEach((key) => {
            if (configSegundaSelfie[key].id === gestureType) {
                dataGesture = key;
            }
        });

        dialog.setTitle(configSegundaSelfie[dataGesture].title);
        dialog.setContent(configSegundaSelfie[dataGesture].description);
        dialog.OpenDialog();
    };

    const handleRepeatPhoto = () => {
        navigate(`${RoutesConstants.FACE_CAPTURE_PAGE}/neutra`);
    };

    return (
        <Grid>
            {/* si no hay imagen 3 confirmada debe ir a la confirmacion, si hay debe mostrar popup para pasar a la otra selfie*/}
            {/* {!images[StepsConstants.THIRD_STEP].confirmImage ? */}
            {!images[StepsConstants.THIRD_STEP].photo ? (
                <TransitionStep />
            ) : (
                <Grid>
                    {/*  {images[StepsConstants.THIRD_STEP].confirmImage ? (
                        <InformationModal
                            dialogHook={dialog}
                            buttonPosition="center"
                            image={smileFace}
                            icon={<SentimentSatisfiedOutlinedIcon />}
                            type="sonriendo"
                            buttonText="Entendido"></InformationModal>
                    ) : (
                        ''
                    )} */}
                    <ImageCapture
                        title="Verificá tu foto"
                        description="Si ya lograste una foto clara, avancemos con la segunda selfie."
                        image={images[StepsConstants.THIRD_STEP].photo}
                        action={() => handleSetDataAndExecute()}
                        repeatCapture={() => handleRepeatPhoto()}
                        error={error}
                        attempt={attempt}
                        faceValidation={true}
                        disabled={errorMessage != null && Number(attempt) < 3}
                        errorMessage={errorMessage}
                    />
                </Grid>
            )}
        </Grid>
    );
};
