import { Navigate, useParams } from 'react-router-dom';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { ChangePasswordConstantsLS } from '../../../Contexts/ChangePassword/Actions';
import { setItemService } from '../../../Services/LocalStorageService';

const ChangePasswordValidate = () => {
    const { token } = useParams();

    setItemService(ChangePasswordConstantsLS.TokenChangePassword, token);

    return <Navigate to={RoutesConstants.NEW_PASSWORD_CHANGE_PAGE} /> 
};

export default ChangePasswordValidate;
