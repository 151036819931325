import React from 'react';
import MobileLayout from '../../../Components/Layout/MobileLayout';
import { Outlet } from 'react-router-dom';
import { OfferSelectionState } from '../../../Contexts/OfferSelection/OfferSelectionState';

export const OfferPages = () => {
    return (
        <OfferSelectionState>
            <MobileLayout Form={<Outlet />} />
        </OfferSelectionState>
    );
};
