import { getData } from './BaseService';

export const getCreditListPerPerson = async (personId) => {
    const url = `credit/byperson/${personId}`;
    const response = await getData(url);
    return response;
};

export const getCreditDetailById = async (requestId) => {
    const url = `credit/details/${requestId}`;
    const response = await getData(url);
    return response;
};
