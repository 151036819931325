import { Grid } from '@mui/material';
import React from 'react';
import { useContext } from 'react';
import LoanActiveContext from '../../Contexts/LoanActive/LoanActiveContext';
import DownloadableFiles from './Downloadable/DownloadableFiles';
import downloadableOptions from '../../Configs/downloadableOptions.json';

const DownloadContainer = ({ desktop }) => {
    const { creditDetail } = useContext(LoanActiveContext);

    return (
        <Grid item xs={11} sm={3} mb={1} ml={desktop ? 5 : -5}>
            <DownloadableFiles
                border={false}
                text={false}
                finished={creditDetail?.credits.status == 'Cancelado'}
                optionsList={downloadableOptions.options}
                hasDebt={creditDetail?.credits.hasDebt}
            />
        </Grid>
    );
};

export default DownloadContainer;
