import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useGenericForm } from '../../../Hooks/useGenericForm';
import { GenericForm } from '../../../Components/Form/GenericForm';
import { ButtonCustom } from '../../../Components/Buttons/ButtonCustom';
import { AcceptTermsAndPolicy } from '../../../Components/AcceptTermsAndPolicy';
import { ValidateEmailAndExecute } from '../../../Helpers/ValidateEmailAndExecute';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import contactInfoLoanConfig from '../../../Configs/contactInfoLoanConfig.json';
import LoanRequestContext from '../../../Contexts/LoanRequest/LoanRequestContext';
import { GetValidateFunction } from '../../../Helpers/GetValidateFunction';
import ErrorMessage from '../../../Components/Form/ErrorMessage';

export const ContactInformationForm = () => {
    const { form, config, handleValue, validForm, initForm, setConfig, validateForm } =
        useGenericForm(contactInfoLoanConfig.formControls);
    const { options, saveTotalData, getInitData, data } = useContext(LoanRequestContext);
    const [firstExecuted, setFirstExecuted] = useState(false);
    const [error, setError] = useState('');

    const initValues = {
        area: `${data.phoneCode != null ? data.phoneCode : ''}`,
        phoneNumber: `${data.phoneNumber != null ? data.phoneNumber : ''}`,
        province: `${data.provinceId != 0 ? data.provinceId : ''}`,
        email: `${data.email != null ? data.email : ''}`
    };

    useEffect(() => {
        setError('');
    }, [form]);

    const blockFormControls = () => {
        if (config && !firstExecuted) {
            let newConfig = [...config];
            let configModified;
            setFirstExecuted(true);

            if (data.provinceId != 0) {
                configModified = newConfig.map((e) => {
                    let x = { ...e };

                    if (x.field == 'province') {
                        x.hidden = true;
                    } else {
                        x.hidden = false;
                    }
                    return x;
                });
            }
            if (configModified == undefined) {
                configModified = newConfig;
            }

            if (initValues.email)
                setConfig(
                    configModified.map((e) => {
                        let x = { ...e };
                        /* if (x.field == 'email') {
                            x.disabled = true;
                        } */
                        return x;
                    })
                );
        }
    };

    useEffect(() => {
        getInitData();
        initForm(initValues);
        validateForm(initValues);
    }, []);

    useEffect(() => {
        blockFormControls();
    }, [config]);

    return (
        <Box sx={{ mt: 2, mb: 2, pt: 2, pb: 2 }}>
            <GenericForm
                formControls={config}
                options={options}
                form={form}
                validation={GetValidateFunction}
                handleValue={handleValue}
            />
            {error.trim().length > 0 && (
                <ErrorMessage title={'Ocurrió un error'} description={error} />
            )}
            <AcceptTermsAndPolicy />
            <ButtonCustom
                border
                text="Continuar"
                action={async () => {
                    const formPhoneNumber = `${form.area}${form.phoneNumber}`;
                    const totalDataRes = await ValidateEmailAndExecute(
                        data,
                        form.email,
                        formPhoneNumber,
                        function () {
                            saveTotalData(form);
                        }
                    );
                    if (typeof totalDataRes === 'string') {
                        setError(totalDataRes);
                        return;
                    }
                    if (totalDataRes?.response?.status >= 400) {
                        setError(totalDataRes?.response?.data?.errors[0]?.message);
                        return;
                    }
                    setError('');
                }}
                disabled={!validForm || error.trim().length > 0}
            />
            <BackHomeButton />
        </Box>
    );
};
