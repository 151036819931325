import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#004489'
        },
        secondary: {
            main: '#0069D7'
        },
        info: {
            main: '#CEE6FF'
        }
    },
    typography: {
        fontFamily: 'Public Sans, sans-serif',
        button:{
            textTransform: "none",
            fontFamily: 'Archivo'
        }
    },

});
