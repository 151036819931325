import React from 'react';
import { ReturnButton } from '../../../../Components/LoginBtns/ReturnButton';
import { DescriptionForm } from '../../../../Components/DescriptionsLayout';
import { Box } from '@mui/system';
import SelectionMethodForm from './SelectionMethodForm';

const PaymentMethodPage = () => {
    const titleForm = '¿Como querés pagar tu préstamo?';

    return (
        <Box style={{ textAlign: 'center' }}>
            <ReturnButton />
            <DescriptionForm title={titleForm} />
            <SelectionMethodForm />
        </Box>
    );
};

export default PaymentMethodPage;
