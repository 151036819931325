import React from 'react';
import { Grid, Typography, useTheme, useMediaQuery} from '@mui/material';
import './PublicHomeStyle.css';
import { TitleCustom } from '../../Components/TitleCustom';
import IconContainer from '../../Assets/Icons/IconContainer';

export const NeedsAndPay = () => {

    const theme = useTheme();
    const desktop  = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} sx={{paddingLeft: desktop && '0px !important'}}>
                <TitleCustom size='20px' title="¿Qué vas a necesitar?" />
            </Grid>
            <Grid container xs={12} sm={4} className="mt-15">
                <Grid item xs={1} sm={1} mr={4}>
                    <IconContainer src={'PublicHomeSvg/dni.svg'} />
                </Grid>
                <Grid item sm={3} sx={{display:{xs:'none', sm:'block'}}}></Grid>
                <Grid item xs={7} sm={9} ml={!desktop && 1.5}>
                    <Typography className="font-blue pt-5">
                        Tu celular y DNI a mano.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container xs={12} sm={4} className="mt-15">
                <Grid item xs={1} sm={1} mr={4}>
                    <IconContainer src={'PublicHomeSvg/AccountBalance.svg'} />
                </Grid>
                <Grid item sm={6} sx={{display:{xs:'none', sm:'block'}}}></Grid>
                <Grid item xs={7} sm={6} ml={!desktop && 1.5}>
                    <Typography className="font-blue pt-5">Un CBU a tu nombre.</Typography>
                </Grid>
            </Grid>
            <Grid container xs={12} sm={4} className="mt-15">
                <Grid item xs={1} sm={1} mr={4}>
                    <IconContainer src={'PublicHomeSvg/PersonIcon.svg'} />
                </Grid>
                <Grid item sm={4} sx={{display:{xs:'none', sm:'block'}}}></Grid>
                <Grid item xs={7} sm={8} ml={!desktop && 1.5}>
                    <Typography className="font-blue pt-5">Ser mayor de 18 años.</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} className="font-blue" sx={{paddingLeft: desktop && '0px !important'}}>
                <TitleCustom
                    size='20px'
                    title="¿Cómo lo podés pagar?"
                    subtitle="De acuerdo a tu perfil crediticio y a medida que nos vayamos conociendo, 
          vas a poder acceder a diferentes formas de pago:"
                />
            </Grid>
            <Grid container xs={12} sm={5} mb={desktop ? 4.2 : 0} pt={desktop ? 2 : 3}>
                <Grid item xs={1} sm={1} mr={4}>
                    <IconContainer src={'PublicHomeSvg/DebitCard.svg'} />
                </Grid>
                <Grid item xs={9} sm={12} pb={desktop && 4} ml={!desktop && 1.5}>
                    <Typography className="font-blue">
                        Con tu tarjeta de débito, de una forma simple.{' '}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid container xs={12} sm={5} mb={desktop ? 4 : 2} pt={desktop ? 2 : 1}>
                <Grid item xs={1} sm={1} mr={4}>
                    <IconContainer src={'PublicHomeSvg/Money.svg'} />
                </Grid>
                <Grid item xs={9} sm={12}>
                    <Typography className="font-blue" pb={desktop && 1.5} ml={!desktop && 1.5} >
                        Con efectivo, en Pago Fácil, Rapipago, Mercado Pago, entre otros.{' '}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
