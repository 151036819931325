import { SvgIcon } from '@mui/material';

const DebitCard = (props) => {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="2" width="32" height="32" rx="16" fill="#CEE6FF" />
                <path
                    d="M10.666 15.9998C10.2978 15.9998 9.99935 16.2983 9.99935 16.6665C9.99935 17.0347 10.2978 17.3332 10.666 17.3332V15.9998ZM25.3327 17.3332C25.7009 17.3332 25.9993 17.0347 25.9993 16.6665C25.9993 16.2983 25.7009 15.9998 25.3327 15.9998V17.3332ZM11.9993 13.3332H23.9993V11.9998H11.9993V13.3332ZM23.9993 13.3332C24.3675 13.3332 24.666 13.6316 24.666 13.9998H25.9993C25.9993 12.8953 25.1039 11.9998 23.9993 11.9998V13.3332ZM24.666 13.9998V21.9998H25.9993V13.9998H24.666ZM24.666 21.9998C24.666 22.368 24.3675 22.6665 23.9993 22.6665V23.9998C25.1039 23.9998 25.9993 23.1044 25.9993 21.9998H24.666ZM23.9993 22.6665H11.9993V23.9998H23.9993V22.6665ZM11.9993 22.6665C11.6312 22.6665 11.3327 22.368 11.3327 21.9998H9.99935C9.99935 23.1044 10.8948 23.9998 11.9993 23.9998V22.6665ZM11.3327 21.9998V13.9998H9.99935V21.9998H11.3327ZM11.3327 13.9998C11.3327 13.6316 11.6312 13.3332 11.9993 13.3332V11.9998C10.8948 11.9998 9.99935 12.8953 9.99935 13.9998H11.3327ZM10.666 17.3332H25.3327V15.9998H10.666V17.3332Z"
                    fill="#0069D7"
                />
            </svg>
        </SvgIcon>
    );
};

export default DebitCard;
