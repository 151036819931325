import React, { useContext, useEffect, useState } from 'react';
import { DescriptionForm } from '../../Components/DescriptionsLayout';
import { Box } from '@mui/material';
import { DataUpdateForm } from './DataUpdateForm';
import MyProfileContext from '../../Contexts/MyProfile/MyProfileContext';
import { firstCapitalLetter, maskDNI } from '../../Helpers/Masks';
import AppContext from '../../Contexts/Global/AppContext';

export const DataUpdate = () => {
    const { getPersonData } = useContext(MyProfileContext);
    const { refreshPage } = useContext(AppContext);

    const [personData, setPersonData] = useState({ email: '', userName: '', dni: '' });

    useEffect(() => {
        const getData = async () => {
            const data = await getPersonData();
            setPersonData(data);
        };

        getData();
    }, [refreshPage]);

    const titleForm = firstCapitalLetter(personData.userName);

    const descriptionForm = `D.N.I ${maskDNI(personData.dni)}`;

    return (
        <Box style={{ textAlign: 'center', maxWidth: '400px' }}>
            <DescriptionForm title={titleForm} description={descriptionForm} />
            <DataUpdateForm personData={personData} />
        </Box>
    );
};
