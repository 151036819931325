import { Box, Grid } from '@mui/material';
import React, { useContext } from 'react';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { Img } from '../../../Components/ImageContainer';
import RegistrationLoginButtons from './RegistrationAndLoginButtons';
import AppContext from '../../../Contexts/Global/AppContext';
import { MetaPixel } from '../../../Components/MetaPixel/MetaPixel';

export const DifferentialFeedback = () => {
    const { authentication } = useContext(AppContext);

    const title = '¡Ahora nos toca trabajar a nosotros!';
    const description =
        'Vamos a estar revisando los datos enviados y luego te contactaremos para que puedas disfrutar de tu préstamo.';
    return (
        <Grid container justifyContent="flex-start">
            <MetaPixel />
            <DescriptionForm
                title={title}
                description={description}
                fontFamilyDescription="Public Sans"
            />

            <Img src={'thumbsUp.svg'} />

            {!authentication ? <RegistrationLoginButtons /> : <Box height="400px"></Box>}

            <BackHomeButton />
        </Grid>
    );
};
