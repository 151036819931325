import { Grid } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { useLocation } from 'react-router-dom';

const GridMain = styled(Grid)(({approvedLoan, showBackground}) => ({
    backgroundImage: approvedLoan ? `url("lastWallpaper.svg")` : showBackground ? `url("backgroundMobile.svg")` : '',
    backgroundColor: !showBackground ? '#ecf5ff' : '',
    display: 'flex',
    backgroundRepeat: 'no-repeat',
    paddingBottom: 40,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    justifyContent: 'center'
}));

const GridDescription = styled(Grid)(() => ({
    margin: ' 20px 0px 20px 24px',
    borderRadius: '40px'
}));

const GridForm = styled(Grid)(() => ({
    padding: '0px 24px',
    borderRadius: '40px 40px 0px 0px',
    display: 'flex',
    justifyContent: 'center',
}));

const Img = styled('img')(() => ({
    maxWidth: '152px'
}));

const MobileLayout = ({ Form, approvedLoan }) => {
    const routerLocation = useLocation();
    const showBackground = routerLocation.pathname != RoutesConstants.LOAN_DETAILS_PAGE

    return (
        <GridMain container component="main" justifyContent="start" zIndex={1} 
        approvedLoan={approvedLoan}
        showBackground={showBackground}>
            <GridDescription item xs={12}>
                <Img src="directo-logo.svg" />
            </GridDescription>

            <GridForm item maxWidth="auto" xs={12}>
                {Form}
            </GridForm>
        </GridMain>
    );
};

export default MobileLayout;
