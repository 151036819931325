
import * as React from 'react';
import styled from "styled-components";
import Stack from '@mui/material/Stack';
import { Typography, Grid} from '@mui/material';
import { ColorConstants } from '../../Constants/ColorConstants';
import loading from './../../Assets/Images/loading.gif'

export const ButtonCustom = ({ bg, color, border, size='16px', hover, icon, text, width, height= '48px', padding ='12px 32px', action = {}, disabled, iconPosition = "end", fontSize = '16px', isLoading = false }) => {
  const ColorButton = styled.button`
        background: ${bg ? bg : "#0069D7"};
        color:  ${color ? color : ColorConstants.WHITE};
        border: ${border ? "1.5px solid" : "none"};
        padding: ${padding};
        margin: 10px 0px;
        border-radius: 48px;
        font-size: ${size};
        display: flex;
        align-content: center;
        width: ${width ? width : "100%"};
        justify-content: center;
        align-items: center;
        height: ${height};
        :hover {
          color: white; 
          background: ${hover ? hover : "#004489"}; 
          cursor: pointer;
        }
        :disabled {
          background: #e0e0e0;
          color: #80808094;
          border: 0px;
          cursor: context-menu;
        }
    `;
  return (
    <Stack spacing={2} direction="row">
      <ColorButton variant="contained" onClick={() => action()} disabled={disabled}>
        {!isLoading ?
          icon ?
            <Grid container>
              <Grid xs={2} sx={{
                '& .css-i4bv87-MuiSvgIcon-root':
                {
                  width: '20px',
                  height: '20px,'
                },
                mt: '0px',
                display:{ xs: `${iconPosition === "start" ? 'block' : 'none'}`}
              }}>
                {iconPosition === "start" ? icon ? 
                <Typography>{icon}</Typography> : null : null}
              </Grid>
              <Grid item xs={icon ? 10 : 12} mt={iconPosition === "end" && 0.8}>
                <Typography fontSize={fontSize}>{text}</Typography>  
              </Grid>
              <Grid xs={2} sx={{
                '& .css-i4bv87-MuiSvgIcon-root':
                {
                  width: '20px',
                  height: '20px,'
                },
                mt: '5px',
                display:{ xs: `${iconPosition === "end" ? 'block' : 'none'}`}
              }}>
                {iconPosition === "end" ? icon ? 
                <Typography>{icon}</Typography> : null : null}
              </Grid>
            </Grid>: 
            <Typography fontSize={fontSize}>{text}</Typography>  
          :
          <>
            <img src={loading} style={{width: '12%'}}/>
          </>
        }
      </ColorButton>
    </Stack>
  )
}
