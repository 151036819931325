import { downloadData } from './BaseService';

export const downloadService = ({ personId, requestId, printType }) => {
    const url = 'document';
    
    //TODO: se va a cambiar en el backend, clientId a personId
    const objectRequest = {
        clientId: personId,
        requestId,
        printType
    };

    const response = downloadData(url, objectRequest);

    return response;
};
