import { Typography } from '@mui/material';
import React, { useContext } from 'react';
import FacebookLogin from 'react-facebook-login';
import AppContext from '../../Contexts/Global/AppContext';
const {REACT_APP_FB_APP_ID} = process.env


const LoginFacebook = ({isMyProfile = false}) => {
    const {onLoginFacebook, AssociateFacebookAccount} = useContext(AppContext)

    return (
        <FacebookLogin
            appId={REACT_APP_FB_APP_ID}
            autoLoad={false}
            fields="name,email,picture"
            callback={isMyProfile ? AssociateFacebookAccount : onLoginFacebook}
            textButton={<Typography fontFamily={'Archivo'} fontWeight={500}>Continuar con Facebook</Typography>}
            icon="fa-facebook"
            disableMobileRedirect={true}
        />
    )
}

export default LoginFacebook;