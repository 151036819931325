import { postData } from '../Services/BaseService';
import { savePhoneNumberService } from '../Services/LoanRequestService';

export const ValidateEmailAndExecute = async (data, formEmail, formPhoneNumber, action) => {
    let url = 'person/verifyemail';
    let valid = await postData(url, { email: formEmail });
    if (valid?.response?.status >= 400) {
        return valid?.response?.data?.errors[0]?.message || 'El email no es valido';
    }
    if (valid) {
        if (
            data?.email === formEmail &&
            `${data?.phoneCode}${data?.phoneNumber}` === formPhoneNumber
        ) {
            try {
                await action();
            } catch (error) {
                return error;
            }
        } else {
            const { personId } = data;
            try {
                await savePhoneNumberService({
                    personId,
                    numCelular: formPhoneNumber,
                    email: formEmail
                });
                await action();
            } catch (error) {
                return error;
            }
        }
    }
};

export const ValidateEmailAndExecuteRegister = async (data, action) => {
    let url = 'person/verifyemail';
    let valid = await postData(url, { email: data });
    if (valid?.response?.status >= 400) {
        return valid?.response?.data?.errors[0]?.message || 'El email no es valido';
    }
    if (valid) {
        try {
            await action();
        } catch (error) {
            return error;
        }
    }
};
