import { useState } from 'react';
import { LoanRequestConstantsLS } from '../Contexts/LoanRequest/LoanRequestActions';
import { getItemService } from '../Services/LocalStorageService';
import { getCardsService, getVerifyCardsService } from '../Services/PaymentMethodService';

export const useCardMethod = () => {
    const [cards, setCards] = useState([]);
    const personId = JSON.parse(getItemService(LoanRequestConstantsLS.PersonToGetLoan)).personId;
    const cardSelected = JSON.parse(getItemService(LoanRequestConstantsLS.PaymentMethod));

    const getVerifyCards = async () => {
        const verifyResponse = await getVerifyCardsService({ personId });
        const data = verifyResponse.data;

        return data;
    };

    const getCards = async () => {
        const cardsResponse = await getCardsService({ personId });
        const data = cardsResponse.data.request;

        if (data.length > 0 && data[0].id != null) {
            setCards(data);
        }
        return data;
    };

    return { getCards, getVerifyCards, cards, cardSelected };
};
