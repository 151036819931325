import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import MethodOptions from './MethodOptions';
import PaymentMethodContext from '../../../../Contexts/PaymentMethod/PaymentMethodContext';
import ButtonMethod from './ButtonMethod';
import { useTimer } from '../../../../Hooks/useTimer';
import { useCardMethod } from '../../../../Hooks/useCardMethod';
import {
    getItemService,
    removeItemService,
    setItemService
} from '../../../../Services/LocalStorageService';
import { LoanRequestConstantsLS } from '../../../../Contexts/LoanRequest/LoanRequestActions';
import ErrorMessage from '../../../../Components/Form/ErrorMessage';

const SelectionMethodForm = () => {
    const {
        paymentMethods,
        allowedMethod,
        getPaymentMethods,
        setPayMethod,
        setMethodSelected,
        methodSelected
    } = useContext(PaymentMethodContext);
    const [cardsError, setCardsError] = useState({ successfully: true, title: '', message: '' });
    const { timerAction } = useTimer(2);
    const { getVerifyCards, getCards, cards } = useCardMethod();

    useEffect(() => {
        const init = async () => {
            const verifyCardsError = getItemService('cardVerifyError')
                ? JSON.parse(getItemService('cardVerifyError'))
                : null;
            if (verifyCardsError) {
                setCardsError(verifyCardsError);
            } else {
                setCardsError({ successfully: true, title: '', message: '' });
            }
            getPaymentMethods();
            await getVerifyCards();
            const data = await getCards();
            setItemService(LoanRequestConstantsLS.CardsQuantity, data.length);
        };

        init();
    }, []);

    const setPaymentMethod = async (method) => {
        await setPayMethod(method);
    };

    const onSubmit = async (methodSelected) => {
        /*  console.log(methodSelected);
        console.log(paymentMethods);
        console.log(cards); */
        removeItemService('cardVerifyError');
        const filteredMethod = paymentMethods.find((method) => method.id === methodSelected);
        const tarjetFiltered = cards.find((card) => card.id === methodSelected);
        const method = filteredMethod != undefined ? filteredMethod : tarjetFiltered;
        setMethodSelected(method);
        timerAction(() => setPaymentMethod(method));
    };

    return (
        <Grid
            display={'flex'}
            flexWrap={'wrap'}
            alignContent={'start'}
            justifyContent={'center'}
            height={'480px'}
            width="100%">
            {allowedMethod == 1 ? (
                <ButtonMethod
                    key={MethodOptions[0].id}
                    title={MethodOptions[0].title}
                    description={MethodOptions[0].description}
                    selected={methodSelected?.id}
                    value={MethodOptions[0]?.id}
                    onClick={onSubmit}
                />
            ) : null}

            {cards.length == 0 ? (
                <ButtonMethod
                    key={MethodOptions[1].id}
                    title={MethodOptions[1].title}
                    description={MethodOptions[1].description}
                    selected={methodSelected?.id}
                    value={MethodOptions[1].id}
                    onClick={onSubmit}
                />
            ) : (
                <ButtonMethod
                    key={2}
                    title="Con una nueva tarjeta"
                    description={'Vamos a debitar mes a mes de tu cuenta la cuota correspondiente.'}
                    selected={methodSelected?.id}
                    value={2}
                    onClick={onSubmit}
                />
            )}

            {cards.length != 0
                ? cards.map((card) => {
                      return (
                          <ButtonMethod
                              card
                              key={card.id}
                              title={`Con tu tarjeta ${card.emisor} ****${card.numero.slice(12)}`}
                              selected={methodSelected?.id}
                              value={card.id}
                              onClick={onSubmit}
                          />
                      );
                  })
                : null}
            {cardsError?.successfully === false && (
                <ErrorMessage
                    sx={{ mt: 5 }}
                    title={cardsError?.title}
                    description={cardsError?.message}
                />
            )}
        </Grid>
    );
};

export default SelectionMethodForm;
