import React, { useContext } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import '../../Pages/PrivateHome/PrivateHomeStyle.css';
import logo from '../../Assets/complete-logo-color.svg';
import { ButtonCustom } from '../Buttons/ButtonCustom';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { ColorConstants } from '../../Constants/ColorConstants';
import AppContext from '../../Contexts/Global/AppContext';
import { MobileCheck } from '../../Helpers/MobileCheck';
import { firstName } from '../../Helpers/Masks';
import TitlePrivateHeader from './TitlePrivateHeader';
import SubTitlePrivateHeader from './SubTitlePrivateHeader';
import HeaderContext from '../../Contexts/Header/HeaderContex';
import MaskMoney from '../../Pages/LoanRequest/OfferSelection/MaskMoney';
import { useEffect } from 'react';
import { DetectMobile } from '../../Utils/DetectMobile';

export const PrivateHeader = () => {
    const routerLocation = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    const desktopSM = useMediaQuery(theme.breakpoints.up('sm'));
    const { OnLogout, user, creditsUser } = useContext(AppContext);
    const {
        RequestLoanPrivate,
        previousLoan,
        recommendation,
        authentication,
        getInitData,
        getRecomendations
    } = useContext(HeaderContext);
    const text = `Hola ${firstName(user?.personName)}`;

    useEffect(() => {
        if (authentication && routerLocation.pathname === RoutesConstants.PRIVATE_HOME) {
            getInitData(user.personId);
        }
    }, [authentication, routerLocation]);

    useEffect(() => {
        if (
            recommendation &&
            routerLocation.pathname === RoutesConstants.PRIVATE_HOME &&
            creditsUser &&
            !recommendation?.qualified
        ) {
            getRecomendations(user.personId);
        }
    }, [creditsUser]);

    return (
        <Grid
            container
            className="bg-image-white"
            sx={{
                background: `${
                    routerLocation.pathname != RoutesConstants.PRIVATE_HOME ? '#F5FAFF' : ''
                }`,
                borderRadius: `${
                    routerLocation.pathname != RoutesConstants.PRIVATE_HOME
                        ? '0px'
                        : '0px 0px 80px 80px'
                }`,
                margin: `${
                    routerLocation.pathname != RoutesConstants.PRIVATE_HOME
                        ? '0px'
                        : '0px 0px 20px 0px'
                }`,
                boxShadow: `${
                    routerLocation.pathname != RoutesConstants.PRIVATE_HOME
                        ? '0px'
                        : '0px 12px 16px -4px rgb(0 105 215 / 10%), 0px 4px 6px -2px rgb(0 105 215 / 5%)'
                }`
            }}>
            <Grid
                container
                className="text-aling-center"
                sx={{
                    padding: `${
                        routerLocation.pathname != RoutesConstants.PRIVATE_HOME
                            ? '25px 10px 10px 10px'
                            : '25px 10px 145px 10px'
                    }`
                }}>
                <Grid item lg={2} sm={2}></Grid>
                <Grid
                    item
                    xs={8}
                    sm={1}
                    md={1}
                    lg={1}
                    sx={{
                        textAlign: MobileCheck() && 'left',
                        ml:
                            (MobileCheck() && !desktopSM && 2) ||
                            (MobileCheck() && !desktopSM && 0.5),
                        mt: (MobileCheck() && !desktopSM && 1) || (MobileCheck() && desktopSM && 2)
                    }}>
                    {MobileCheck() ? (
                        <img src={logo} className="img-dimensions" />
                    ) : (
                        <img src={logo} className="img-desktop" />
                    )}
                </Grid>
                <Grid item xs={1} sm={2} md={1} sx={{ display: { sm: 'none' } }}></Grid>
                <Grid
                    item
                    xs={2}
                    sm={1}
                    md={1}
                    lg={1}
                    sx={{
                        display: { md: 'none' },
                        textAlign: `${MobileCheck() ? 'right' : ''}`,
                        position: `${MobileCheck() ? 'absolute' : ''}`,
                        right: `${
                            (MobileCheck() && !desktopSM && '30px') ||
                            (MobileCheck() && desktopSM && '8.5em')
                        }`
                    }}>
                    <ButtonCustom
                        height={MobileCheck() ? '32px' : '48px'}
                        width={MobileCheck() ? '61px' : '61px'}
                        padding={MobileCheck() && '8px 16px 8px 16px'}
                        border
                        bg="transparent"
                        color={ColorConstants.BLUE}
                        text="Salir"
                        action={() => OnLogout()}
                    />
                </Grid>
                <Grid item xs={12} sm={2} md={1}></Grid>
                <Grid
                    item
                    xs={5}
                    sm={3}
                    sx={{
                        display: {
                            xs: `${
                                routerLocation.pathname == RoutesConstants.PRIVATE_HOME
                                    ? 'block'
                                    : 'none'
                            }`,
                            sm: 'block',
                            md: 'none',
                            lg: 'block'
                        },
                        mt:
                            (MobileCheck() && !desktopSM && 3) ||
                            (!MobileCheck() && !desktopSM && 2.5) ||
                            (MobileCheck() && desktopSM && 8),
                        pl: MobileCheck() ? 0 : 10
                    }}
                    md={3}
                    lg={3}>
                    {MobileCheck() ? (
                        <Box
                            width="90%"
                            sx={{
                                textAlign: `${MobileCheck() ? 'left' : ''}`,
                                position: `${MobileCheck() ? 'absolute' : ''}`,
                                left: `${
                                    (MobileCheck() && !desktopSM && '30px') ||
                                    (MobileCheck() && desktopSM && '9em')
                                }`
                            }}>
                            <Typography
                                sx={{ fontSize: '24px !important' }}
                                className="title font-blue font-weight">
                                {text}
                            </Typography>
                        </Box>
                    ) : (
                        <Box width="90%" mt={1.5}>
                            <Typography
                                sx={{ fontSize: '24px !important' }}
                                className="title font-blue font-weight">
                                {text}
                            </Typography>
                        </Box>
                    )}
                </Grid>
                {/* <Grid item sm={1} sx={({display: { sm:'block', xs:'none', lg:'none', md: 'none'}})}></Grid> */}
                <Grid
                    item
                    xs={5}
                    sm={2}
                    md={1}
                    lg={2}
                    sx={{
                        display: {
                            xs: `${
                                routerLocation.pathname == RoutesConstants.PRIVATE_HOME
                                    ? 'block'
                                    : 'none'
                            }`,
                            sm: 'block',
                            md: 'block',
                            lg: 'block'
                        },
                        justifyContent: 'right !important',
                        ml: MobileCheck() ? 0 : 0,
                        mt: MobileCheck() ? 2 : 0,
                        top: `${MobileCheck() ? '67px' : ''}`,
                        textAlign: `${MobileCheck() ? 'right' : ''}`,
                        position: `${MobileCheck() ? 'absolute' : ''}`,
                        right: `${
                            (MobileCheck() && !desktopSM && '30px') ||
                            (MobileCheck() && desktopSM && '8.5em')
                        }`
                    }}>
                    <ButtonCustom
                        padding={MobileCheck() ? '8px 12px' : ''}
                        height={MobileCheck() ? '32px' : '48px'}
                        width={MobileCheck() ? '120px' : '153px'}
                        icon={<ArrowForwardOutlinedIcon />}
                        action={() => navigate(RoutesConstants.UPDATE_USER_DATA, { replace: true })}
                        text="Mi perfil"
                    />
                </Grid>
                <Grid
                    item
                    sx={{
                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'block',
                            lg: 'block'
                        },
                        mr: 5
                    }}>
                    <div className="vertical-divider"></div>
                </Grid>
                <Grid
                    item
                    xs={2}
                    sx={{ display: { xs: 'none', md: 'block' }, pr: 3 }}
                    sm={2}
                    md={1}
                    lg={1}>
                    <ButtonCustom
                        border
                        bg="transparent"
                        color={ColorConstants.BLUE}
                        text="Salir"
                        padding={'8px 16px'}
                        action={() => OnLogout()}
                    />
                </Grid>
                <Grid item xs={1} sm={1}></Grid>
            </Grid>
            <Grid
                container
                sx={{
                    display: {
                        xs: `${
                            routerLocation.pathname != RoutesConstants.PRIVATE_HOME
                                ? 'none'
                                : 'inherit'
                        }`,
                        sm: `${
                            routerLocation.pathname != RoutesConstants.PRIVATE_HOME
                                ? 'none'
                                : 'inherit'
                        }`
                    }
                }}>
                <Grid item xs={1} sm={2}></Grid>
                <Grid item xs={10} sm={8}>
                    {recommendation?.qualified ? (
                        <>
                            <TitlePrivateHeader>
                                Encontramos este préstamo para vos{' '}
                                <b>
                                    {MaskMoney(
                                        recommendation.amount.toLocaleString('es-AR'),
                                        false
                                    )}{' '}
                                    en {recommendation.deadline.quantityRate} cuotas
                                </b>
                            </TitlePrivateHeader>
                            <SubTitlePrivateHeader>
                                Podés ajustarlo a tu medida.
                            </SubTitlePrivateHeader>
                        </>
                    ) : previousLoan ? (
                        <>
                            <TitlePrivateHeader>
                                Tu préstamo de una forma <b>simple, segura e inmediata </b>
                            </TitlePrivateHeader>
                            <SubTitlePrivateHeader>
                                En pocos pasos vamos a saber qué ofrecerte y darte la mejor opción.
                            </SubTitlePrivateHeader>
                        </>
                    ) : (
                        <>
                            <TitlePrivateHeader>
                                <b>¿Querés pedir un préstamo?</b>
                            </TitlePrivateHeader>
                            <SubTitlePrivateHeader>
                                Obtenelo de una forma simple, segura e inmediata.
                            </SubTitlePrivateHeader>
                        </>
                    )}
                </Grid>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={1} sm={2}></Grid>
                <Grid item xs={10} sm={3} sx={{ mb: MobileCheck() ? 1 : 8 }}>
                    <ButtonCustom
                        text={
                            desktop
                                ? 'Quiero mi préstamo'
                                : previousLoan
                                ? 'Pedilo ahora'
                                : '¡Lo quiero ahora!'
                        }
                        action={async () => {
                            (await DetectMobile())
                                ? RequestLoanPrivate()
                                : navigate(RoutesConstants.LOAN_REDIRECT_PAGE, { replace: true });
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
