import { Grid, Paper, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { TitleCustom } from '../../Components/TitleCustom';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { Slider, Chip, useMediaQuery, useTheme } from '@mui/material';
import { ColorConstants } from '../../Constants/ColorConstants';
import {
    GetDateOfRequest,
    GetQuoteExpression,
    GetQuotesCancelled,
    GetStatusPhrase,
    GetChipColor,
    GetExpirationNew
} from '../../Helpers/GetDataOfRequest';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { getItemService, setItemService } from '../../Services/LocalStorageService';
import styled from 'styled-components';
import { display } from '@mui/system';

export const CardActiveLoan = ({
    credit,
    list = true,
    index,
    size = '40px',
    sizeQuoteExpression = '16px',
    sizeQuotes = '16px'
}) => {
    const [disableSlider, setDisableSlider] = useState(false);
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const day = credit.date?.split('/')[0];
    const year = credit.date?.split('/')[2];
    const indexCredit = getItemService('indexCredit') ? getItemService('indexCredit') : '';
    const navigate = useNavigate();

    const getDetail = () => {
        setItemService('creditDetailId', credit.requestId);
        setItemService('indexCredit', index + 1);
        navigate(RoutesConstants.LOAN_ACTIVE_PAGE, { replace: true });
    };

    useEffect(() => {
        if (credit.status.includes('Rechazada')) {
            setDisableSlider(true);
        }
    }, [credit]);

    const getQuoteExpression = () => {
        if (credit.status.includes('Rechazada')) {
            return (
                <Typography
                    sx={{
                        mt: 0,
                        mr: 0.5,
                        fontWeight: '600',
                        color: ColorConstants.LIGHT_BLUE,
                        textAlign: 'start',
                        fontSize: `${sizeQuotes}`
                    }}>
                    {`${credit.quantityRate}`}
                </Typography>
            );
        }
        return `${GetQuotesCancelled(credit?.creditProgress)} / ${credit.quantityRate}`;
    };

    return (
        <Grid container spacing={1}>
            <Grid container>
                {!list && <Grid item sm={12} ml={desktop ? 0 : 1}></Grid>}
                {!list && (
                    <Grid item xs={11} sm={12} sx={{ mt: 5, ml: 0 }}>
                        <ReturnButton text={'Volver'} medium />
                    </Grid>
                )}
                {/* <Grid item xs={1} sm={1}></Grid> */}
                <Grid item xs={12} sm={12} mr={0} ml={0} mt={list ? 5 : 0}>
                    <Paper
                        elevation={1}
                        sx={{
                            borderRadius: '32px',
                            width: '100%',
                            padding: desktop ? '40px' : '25px',
                            mb: 5,
                            cursor: `${
                                list && !credit.status.includes('Rechazada') ? 'pointer' : ''
                            }`
                        }}
                        onClick={() => list && !credit.status.includes('Rechazada') && getDetail()}>
                        <Grid>
                            <Grid container spacing={2}>
                                <Grid item sm={11}>
                                    <TitleCustom
                                        size={size}
                                        title={`Préstamo ${list ? index + 1 : indexCredit}: ${
                                            credit.commerce
                                        }`}
                                        subtitle=""
                                    />
                                </Grid>
                                {list && !credit.status.includes('Rechazada') && (
                                    <Grid item sm={1} sx={{ textAlign: 'right', color: '#0069D7' }}>
                                        <ArrowForwardIcon />
                                    </Grid>
                                )}
                            </Grid>
                            {!list && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography
                                            sx={{
                                                color: ColorConstants.LIGHT_BLUE,
                                                textAlign: 'start',
                                                fontSize: `${desktop ? '16px' : '12px'}`
                                            }}>
                                            Fecha de solicitud {day} de{' '}
                                            {GetDateOfRequest(credit.date)} {year}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            color: ColorConstants.LIGHT_BLUE,
                                            textAlign: 'start',
                                            fontSize: `${sizeQuoteExpression}`
                                        }}>
                                        {GetQuoteExpression(credit.status)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    {/* <Typography
                                        sx={{
                                            mt: 0,
                                            color: ColorConstants.LIGHT_BLUE,
                                            textAlign: 'start',
                                            fontSize: `${sizeQuotes}`,
                                            display: 'flex'
                                        }}>
                                        {getQuoteExpression()} de $
                                        {credit?.creditProgress[
                                            GetQuotesCancelled(credit?.creditProgress) - 1
                                        ]?.amountRate.toLocaleString('es-AR', {
                                            style: 'currency',
                                            currency: 'ARS'
                                        })}
                                    </Typography> */}
                                    <Typography
                                        sx={{
                                            mt: 0,
                                            color: ColorConstants.LIGHT_BLUE,
                                            textAlign: 'start',
                                            fontSize: `${sizeQuotes}`,
                                            display: 'flex'
                                        }}>
                                        {`${credit.nextRate}/${credit.quantityRate}`} de{' '}
                                        {credit.rateAmount.toLocaleString('es-AR', {
                                            style: 'currency',
                                            currency: 'ARS'
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* por ahora que no viene completo los datos */}
                                    {!list && (
                                        <Typography
                                            sx={{
                                                textAlign: 'end',
                                                color: `${GetChipColor(credit.status).label}`,
                                                fontSize: `${desktop ? '16px' : '12px'}`,
                                                fontWeight: '600'
                                            }}>
                                            {GetExpirationNew(credit.nextRateData.date)}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                            <Slider
                                value={credit.status.includes('Rechazada') ? 0 : credit.nextRate}
                                valueLabelDisplay="auto"
                                disabled={disableSlider}
                                max={credit.quantityRate}
                                min={0}
                                // onChangeCommitted={(event, value) => onChangeCommitted(value)}
                                // step={step}
                                // marks={marks}

                                sx={{
                                    color: ColorConstants.LIGHT_BLUE,
                                    height: 8,
                                    pt: 0,
                                    pb: 0,
                                    '& .MuiSlider-thumb': {
                                        backgroundColor: ColorConstants.LIGHT_BLUE,
                                        width: '24px',
                                        height: '24px',
                                        display: 'none'
                                    }
                                }}
                            />
                            <Grid container spacing={2}>
                                {!list ? (
                                    <Grid item xs={6}>
                                        {credit.cfttna && (
                                            <Typography
                                                sx={{
                                                    color: ColorConstants.LIGHT_BLUE,
                                                    textAlign: 'start',
                                                    fontSize: `${desktop ? '16px' : '12px'}`
                                                }}>
                                                TNA {credit.tna}%
                                            </Typography>
                                        )}
                                    </Grid>
                                ) : (
                                    <Grid item xs={6}>
                                        <Typography
                                            sx={{
                                                color: ColorConstants.LIGHT_BLUE,
                                                textAlign: 'start',
                                                fontSize: `${sizeQuotes}`
                                            }}>
                                            {GetStatusPhrase(credit.status)}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={6} sx={{ textAlign: 'end' }}>
                                    <Chip
                                        label={
                                            credit.status != 'Cancelado'
                                                ? credit.status
                                                : 'Finalizado'
                                        }
                                        size="small"
                                        sx={{
                                            color: `${GetChipColor(credit.status).color}`,
                                            fontWeight: 'bold',
                                            backgroundColor: `${
                                                GetChipColor(credit.status).backgroundColor
                                            }`,
                                            fontSize: `${sizeQuoteExpression}`
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {/* {!list && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {credit.tna && (
                                            <Typography
                                                sx={{
                                                    pt: 0,
                                                    mt: 0,
                                                    color: ColorConstants.LIGHT_BLUE,
                                                    textAlign: 'start',
                                                    fontSize: `${desktop ? '16px' : '12px'}`
                                                }}>
                                                CFTNA {credit.cfttna}%
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )} */}
                        </Grid>
                    </Paper>
                </Grid>
                {/* <Grid item xs={1} sm={1}></Grid> */}
            </Grid>
        </Grid>
    );
};
