import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { emailValidate } from '../../../Helpers/Validations';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import InformationLabel from '../../../Components/Form/InformationLabel';
import GrayButton from '../../../Components/LoginBtns/GrayButton';
import { ColorConstants } from '../../../Constants/ColorConstants';

const FormExpiredLink = ({ actionSubmit, disableEmailField }) => {
    const [email, setEmail] = useState('');

    const [errorEmail, setErrorEmail] = useState(false);

    const isWrongEmail = () => {
        setErrorEmail(email != '' && !emailValidate(email));
    };

    const onSubmit = async () => {
        await actionSubmit(email);
    };

    const descriptionFormLabel = 'Ejemplo de mail: nombre@gmail.com';

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'space-between',
                height: '430px'
            }}>
            <Box width={'100%'}>
                {!disableEmailField ? (
                    <>
                        <FormControl fullWidth sx={{ my: 1, borderRadius: '40px' }}>
                            <InputLabel htmlFor="email">Ingresá tu mail</InputLabel>
                            <OutlinedInput
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={() => isWrongEmail()}
                                onClick={() => setErrorEmail(false)}
                                sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                                color="info"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <EmailOutlinedIcon />
                                    </InputAdornment>
                                }
                                label="Ingresá tu email"
                            />
                        </FormControl>
                        {errorEmail && <InformationLabel description={descriptionFormLabel} />}
                    </>
                ) : null}
            </Box>
            <Box width={'100%'}>
                <GrayButton
                    text={'Enviar'}
                    action={() => onSubmit()}
                    disabled={(!disableEmailField&&!emailValidate(email))}
                />

                <BackHomeButton />
            </Box>
        </Box>
    );
};

export default FormExpiredLink;
