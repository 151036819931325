import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { NeedsAndPay } from './NeedsAndPay';
import './PublicHomeStyle.css';
import { TitleCustom } from '../../Components/TitleCustom';
import {MobileCheck} from '../../Helpers/MobileCheck';

export const HowItWorksSection = () => {
  const theme = useTheme();
  const desktop  = useMediaQuery(theme.breakpoints.up("md"))
  const desktopSM = useMediaQuery(theme.breakpoints.up("sm"))

    return (
        <>
            <Grid container spacing={4} className="bg-light" my={5} px={(desktop && 31) || (desktopSM && MobileCheck() && 15)}>
                <Grid item xs={12} sm={3}>
                    <TitleCustom
                        size={MobileCheck() ? '24px' : '32px'}
                        title="¿Cómo funciona?"
                        subtitle="Es súper fácil y rápido."
                        secondSubtitle="La plata del préstamo se acredita en tu 
          cuenta bancaria en el mismo día."
                    />
                </Grid>
                <Grid item sm={1} sx={{display:{xs:'none', sm:'block'}}}></Grid>
                <Grid item xs={12} sm={8}>
                    <NeedsAndPay />
                </Grid>
            </Grid>
            <Grid xs={12} mt={-10}>
                <Grid
                    display="flex"
                    justifyContent="end"
                    sx={{backgroundColor: 'white', height:'95%', mx: (desktop ? '' : '-24px'), mb: (desktop ? '' : '6px'), mt: ( -20)}}>
                    <img src= {desktop ? 'arrows.svg' : 'arrowMobile.svg'} />
                </Grid>
            </Grid>
        </>
    );
};
