import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Option = styled(IconButton)(() => ({
    backgroundColor: '#F5FAFF',
    width: '50px',
    height: '50px',
    margin:'8px',
    ":hover": {
        backgroundColor: '#0069D7',
        color: 'white'
    },
}));

const CalendarOption = ({number, onClick, active}) => {
    return (
        <Option
            color="primary"
            onClick={onClick}
            sx={{backgroundColor: active ?  '#0069D7' : ''}}>
            <Typography fontWeight={600} fontFamily="Archivo" fontSize={16} sx={{color: active ? 'white' : ''}}>
                {number}
            </Typography>
        </Option>
    );
};

export default CalendarOption;
