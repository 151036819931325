import React, { useContext } from 'react';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import TokenForm from './TokenForm';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import LoanRequestContext from '../../../Contexts/LoanRequest/LoanRequestContext';

const ValidateLoanTokenPage = () => {
    const { data } = useContext(LoanRequestContext);
    const titleForm = 'Ingresa el código enviado';    

    const TextDescription = () => {
        return (
            <>
                Te llegó por SMS al {data.phoneCode + data.phoneNumber}
                <br />
                <Link to={RoutesConstants.LOAN_CONTACT_INFORMATION_PAGE}>
                    Quiero cambiar el celular
                </Link>
            </>
        );
    };


    return (
        <Box style={{ textAlign: 'center' }}>
            <ReturnButton />
            <DescriptionForm
                title={titleForm}
                description={<TextDescription />}
            />
            <TokenForm />
        </Box>
    );
};

export default ValidateLoanTokenPage;
