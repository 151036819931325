import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import LoginFacebook from '../../../Components/LoginBtns/LoginFacebook';
import LoginGoogle from '../../../Components/LoginBtns/LoginGoogle';
import { LoginEmailButton } from '../../../Components/LoginBtns/LoginEmailButton';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import SocialAccountsButtons from '../../../Components/Buttons/socialAccountsButtons';

const ButtonsBody = () => {
    const googleButtonRef = useRef(null);
    const facebookButonRef = useRef(null);

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'space-between',
                height: '430px'
            }}>
            <Box width={'100%'}></Box>
            <Box width="100%">
                <LoginEmailButton />
                <LoginGoogle />
                {/* <SocialAccountsButtons
                    title="Continuar con Google"
                    sx={{ height: '60px', mt: 3, textAlign: 'center' }}
                    google
                    action={() => googleButtonRef.current.firstChild.click()}
                /> */}
                <SocialAccountsButtons
                    title="Continuar con Facebook"
                    sx={{ textAlign: 'center' }}
                    action={() => {
                        facebookButonRef.current.children[0].children[1].click();
                    }}
                />
                <Box textAlign={'center'} my="24px">
                    <Link to={RoutesConstants.LOGIN_PAGE}>
                        ¡Ya soy usuario, quiero iniciar sesión!
                    </Link>
                </Box>
            </Box>
            <Box display="none">
                <Box height={50} ref={googleButtonRef} isMyProfile>
                    <LoginGoogle />
                </Box>
                <Box height={50} ref={facebookButonRef} isMyProfile>
                    <LoginFacebook />
                </Box>
            </Box>
            {/* <Box width={'100%'}>
                <LoginEmailButton />
                <Box mt={1} height={60}>
                    <LoginGoogle />
                </Box>
                <Box mt={2} height={60} mb={7}>
                    <LoginFacebook />
                </Box>
                <Box textAlign={'center'} my="24px">
                    <Link to={RoutesConstants.LOGIN_PAGE}>¡Ya soy usuario, quiero iniciar sesión!</Link>
                </Box>
            </Box> */}
        </Box>
    );
};

export default ButtonsBody;
