import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { PublicHome } from '../Pages/PublicHome/PublicHomePage';
import LoginPage from '../Pages/Register/LoginPage/LoginPage';
import CreateAccountPage from '../Pages/Register/CreateAccount/CreateAccountPage';
import { CreateAccountForm } from '../Pages/Register/CreateAccount/CreateAccountForm';
import { Successful } from '../Pages/Register/CreateAccount/Successful';
import { Register } from '../Pages/Register/CreateAccount/Register';
import { NeedCheckAccount } from '../Pages/Register/CreateAccount/NeedCheckAccount';
import { CheckDNI } from '../Pages/Register/CreateAccount/CheckDNI';
import PrivateHome from '../Pages/PrivateHome/PrivateHomePage';
import { ContactPage } from '../Pages/Contact/ContactPage';
import { FrequentQuestionsPage } from '../Pages/FrequentQuestions/FrequentQuestionsPage';
import { ContactInformation } from '../Pages/Register/ContactInformation/ContactInformation';
import NewPasswordPage from '../Pages/Login/changePassword/NewPasswordPage';
import { ChangePassword } from '../Pages/Login/changePassword/ChangePassword';
import { PasswordChangeRequest } from '../Pages/Login/changePassword/PasswordChangeRequest';
import { ExpiredLinkRegister } from '../Pages/Register/CreateAccount/ExpiredLinkRegister';
import { LoanRequestPage } from '../Pages/LoanRequest/LoanRequestPage';
import { LoanRequestGeneralPage } from '../Pages/LoanRequest/LoanRequestGeneralPage';
import TermsConditionsPage from '../Pages/TermsConditions/TermsConditionsPage';
import { TermsConditionsBody } from '../Pages/TermsConditions/TermsConditionsBody';
import { HeaderWrapper } from '../Components/Layout/HeaderWrapper';
import { ContactInformationPage } from '../Pages/LoanRequest/ContactInformation/ContactInformationPage';
import { RedirectPage } from '../Pages/RedirectPage/RedirectPage';
import { OfferPages } from '../Pages/LoanRequest/CloseOffer/OfferPages';
import { SendSuccessEmail } from '../Pages/Login/SendSuccessEmail';
import MyProfilePage from '../Pages/MyProfile/MyProfilePage';
import { ChangePasswordPrivate } from '../Pages/MyProfile/ChangePassword';
import ChangePasswordValidate from '../Pages/Login/changePassword/ChangePasswordValidate';
import RegisterValidateToken from '../Pages/Register/RegisterValidateToken';
import { RoutesConstants } from '../Constants/RoutesConstants';
import { AppState } from './../Contexts/Global/AppState';
import { ExpiredLinkChangePassword } from '../Pages/Login/changePassword/ExpiredLinkChangePassword';
import PolicyPrivacyPage from '../Pages/TermsConditions/PolicyPrivacyPage';
import { DataUpdate } from '../Pages/MyProfile/DataUpdate';
import { BiometricDataContainer } from '../Pages/BiometricData/BiometricDataContainer';
import { BiometricDataPage } from '../Pages/BiometricData/BiometricDataPage';
import { DNICapture2 } from '../Pages/BiometricData/DNICapture2';
import NoOffers from '../Pages/LoanRequest/OffersResult/NoOffers';
import TypeOccupation from '../Pages/LoanRequest/DataManageLoans/TypeOccupation';
import Calendar from '../Pages/LoanRequest/DataManageLoans/Calendar';
import ValidateLoanTokenPage from '../Pages/LoanRequest/Token/ValidateLoanTokenPage';
import ValidateCBUPage from '../Pages/LoanRequest/ValidateCBU/ValidateCBUPage';
import { SummaryBodyDetails } from '../Pages/LoanRequest/CloseOffer/SummaryBodyDetails';
import { FaceCapture } from '../Pages/BiometricData/FaceCapture';
import { DetailsLoanBody } from '../Pages/LoanRequest/Details/DetailsLoanBody';
import OfferSelectionPage from '../Pages/LoanRequest/OfferSelection/OfferSelectionPage';
import PaymentMethodPage from '../Pages/LoanRequest/PaymentMethod/SelectionMethod/PaymentMethodPage';
import { PaymentMethodGeneralPage } from '../Pages/LoanRequest/PaymentMethod/PaymentMethodGeneralPage';
import { FooterWrapper } from '../Components/Layout/FooterWrapper';
import { ActiveLoanGeneralPage } from '../Pages/ActiveLoan/ActiveLoanGeneralPage';
import { ApprovedLoanPage } from '../Pages/LoanRequest/ApprovedLoan/ApprovedLoanPage';
import { Approved } from '../Pages/LoanRequest/ApprovedLoan/Approved';
import { DifferentialFeedback } from '../Pages/LoanRequest/DifferentialFeedback/DifferentialFeedback';
import GoogleAnalytics from '../Components/Layout/GoogleAnalytics(Universal)';
import CardLoadIframe from '../Pages/LoanRequest/PaymentMethod/SelectionMethod/CardLoadIframe';
import SuccessfulCardLoad from '../Pages/LoanRequest/PaymentMethod/SuccessfulCardLoad/SuccessfulCardLoad';
import { CardCredRedirect } from '../Pages/cardCredRedirect';
import { CardCredError } from '../Pages/cardCredError';

export const AppRoutes = () => {
    return (
        <Router>
            <AppState>
                <>
                    <GoogleAnalytics />
                    <HeaderWrapper />
                    <Routes>
                        <Route
                            path={RoutesConstants.CARD_CRED_IFRAME}
                            element={<CardCredRedirect />}
                        />
                        <Route
                            exact
                            path={`${RoutesConstants.CARD_CRED_IFRAME_ERROR}?:error`}
                            element={<CardCredError />}
                        />
                        <Route
                            path={RoutesConstants.CARD_CRED_IFRAME_ERROR}
                            element={<CardCredError />}
                        />
                        <Route
                            path={`${RoutesConstants.CARD_CRED_IFRAME_ERROR}/:error`}
                            element={<CardCredError />}
                        />
                        <Route element={<PrivateRoute />}>
                            <Route
                                exact
                                path={RoutesConstants.PRIVATE_HOME}
                                index
                                element={<PrivateHome />}
                            />

                            <Route element={<MyProfilePage />}>
                                <Route
                                    exact
                                    path={RoutesConstants.PROFILE_CHANGE_PASSWORD}
                                    element={<ChangePasswordPrivate />}
                                />
                                <Route
                                    exact
                                    path={RoutesConstants.UPDATE_USER_DATA}
                                    element={<DataUpdate />}
                                />
                                <Route
                                    exact
                                    path={RoutesConstants.NEW_PASSWORD_SUCCESSFUL_PRIVATE_PAGE}
                                    element={<Successful myProfile />}
                                />
                            </Route>

                            <Route
                                exact
                                path={RoutesConstants.LOAN_ACTIVE_PAGE}
                                index
                                element={<ActiveLoanGeneralPage />}
                            />
                        </Route>

                        <Route element={<PublicRoute />}>
                            <Route
                                exact
                                path={RoutesConstants.PUBLIC_HOME}
                                index
                                element={<PublicHome />}
                            />

                            <Route
                                exact
                                path={RoutesConstants.LOGIN_PAGE}
                                element={<LoginPage />}
                            />

                            <Route element={<CreateAccountPage />}>
                                <Route
                                    exact
                                    path={RoutesConstants.REGISTER_CREATE_ACCOUNT_PAGE}
                                    element={<CreateAccountForm />}
                                />
                                <Route
                                    exact
                                    path={RoutesConstants.REGISTER_SUCCESSFUL_PAGE}
                                    element={<Successful register />}
                                />
                                <Route
                                    exact
                                    path={RoutesConstants.REGISTER_PAGE}
                                    element={<Register />}
                                />
                                <Route
                                    exact
                                    path={RoutesConstants.REGISTER_CHECK_DNI_PAGE}
                                    element={<CheckDNI />}
                                />
                                <Route
                                    exact
                                    path={RoutesConstants.REGISTER_CONTACT_INFORMATION_PAGE}
                                    element={<ContactInformation />}
                                />
                                <Route
                                    exact
                                    path={RoutesConstants.REGISTER_SEND_EMAIL_PAGE}
                                    element={<NeedCheckAccount />}
                                />
                                <Route
                                    exact
                                    path={RoutesConstants.REGISTER_EXPIRED_LINK_PAGE}
                                    element={<ExpiredLinkRegister />}
                                />
                            </Route>

                            <Route
                                exact
                                path="/cambio-password/:token"
                                element={<ChangePasswordValidate />}
                            />
                            <Route
                                exact
                                path="/validacion-dni/:token"
                                element={<RegisterValidateToken />}
                            />

                            <Route element={<NewPasswordPage />}>
                                <Route
                                    exact
                                    path={RoutesConstants.NEW_PASSWORD_CHANGE_PAGE}
                                    element={<ChangePassword />}
                                />
                                <Route
                                    exact
                                    path={RoutesConstants.NEW_PASSWORD_CHANGE_REQUEST_PAGE}
                                    element={<PasswordChangeRequest />}
                                />
                                <Route
                                    exact
                                    path={RoutesConstants.NEW_PASSWORD_SUCCESS_EMAIL_PAGE}
                                    element={<SendSuccessEmail />}
                                />
                                <Route
                                    exact
                                    path={RoutesConstants.NEW_PASSWORD_EXPIRED_LINK_PAGE}
                                    element={<ExpiredLinkChangePassword />}
                                />
                                <Route
                                    exact
                                    path={RoutesConstants.NEW_PASSWORD_SUCCESSFUL_PAGE}
                                    element={<Successful />}
                                />
                            </Route>

                            <Route
                                path={RoutesConstants.ANY_ROUTE}
                                index
                                element={<PublicHome />}
                            />
                        </Route>

                        <Route element={<LoanRequestGeneralPage />}>
                            <Route
                                exact
                                path={RoutesConstants.LOAN_REQUEST_PAGE}
                                element={<LoanRequestPage />}
                            />
                            <Route
                                exact
                                path={RoutesConstants.LOAN_CONTACT_INFORMATION_PAGE}
                                element={<ContactInformationPage />}
                            />
                            <Route
                                exact
                                path={RoutesConstants.LOAN_TOKEN_PAGE}
                                element={<ValidateLoanTokenPage />}
                            />
                            <Route
                                exact
                                path={RoutesConstants.LOAN_REDIRECT_PAGE}
                                element={<RedirectPage />}
                            />
                            <Route
                                exact
                                path={RoutesConstants.LOAN_NO_OFFERS}
                                element={<NoOffers />}
                            />
                            <Route
                                exact
                                path={RoutesConstants.LOAN_TYPE_OCUPPATION}
                                element={<TypeOccupation />}
                            />
                            <Route
                                exact
                                path={RoutesConstants.LOAN_CALENDAR}
                                element={<Calendar />}
                            />
                            <Route
                                exact
                                path={RoutesConstants.LOAN_CBU}
                                element={<ValidateCBUPage />}
                            />
                        </Route>

                        <Route element={<PaymentMethodGeneralPage />}>
                            <Route
                                exact
                                path={RoutesConstants.LOAN_PAY_METHOD}
                                element={<PaymentMethodPage />}
                            />
                            <Route
                                exact
                                path={RoutesConstants.LOAN_CARD_LOAD}
                                element={<CardLoadIframe />}
                            />
                        </Route>

                        <Route element={<OfferPages />}>
                            <Route
                                exact
                                path={RoutesConstants.LOAN_SUMMARY_PAGE}
                                element={<SummaryBodyDetails />}
                            />
                            <Route
                                exact
                                path={RoutesConstants.LOAN_DETAILS_PAGE}
                                element={<DetailsLoanBody />}
                            />
                            <Route
                                exact
                                path={RoutesConstants.LOAN_OFFER_SELECTION}
                                element={<OfferSelectionPage />}
                            />
                        </Route>

                        <Route
                            exact
                            path={RoutesConstants.LOAN_SATISFACTORY_CARD_LOAD}
                            element={<SuccessfulCardLoad />}
                        />

                        <Route element={<ApprovedLoanPage />}>
                            <Route
                                exact
                                path={RoutesConstants.LOAN_LAST_SATISFACTORY_SCREEN}
                                element={<Approved />}
                            />
                            <Route
                                exact
                                path={RoutesConstants.LOAN_LAST_DIFFERENTIAL_SCREEN}
                                element={<DifferentialFeedback />}
                            />
                        </Route>

                        <Route element={<BiometricDataContainer />}>
                            <Route
                                exact
                                path={RoutesConstants.BIOMETRIC_DATA_PAGE}
                                element={<BiometricDataPage />}
                            />
                        </Route>

                        <Route
                            exact
                            path={`${RoutesConstants.DNI_FRONT_PAGE}/:type`}
                            element={<DNICapture2 />}
                        />
                        <Route
                            exact
                            path={`${RoutesConstants.FACE_CAPTURE_PAGE}/:type`}
                            element={<FaceCapture />}
                        />

                        <Route element={<TermsConditionsPage />}>
                            <Route
                                exact
                                path={RoutesConstants.TERMS_PAGE}
                                element={<TermsConditionsBody />}
                            />
                        </Route>

                        <Route element={<PolicyPrivacyPage />}>
                            <Route
                                exact
                                path={RoutesConstants.PRIVACY_PAGE}
                                element={<TermsConditionsBody policy />}
                            />
                        </Route>

                        <Route
                            exact
                            path={RoutesConstants.CONTACT_PAGE}
                            index
                            element={<ContactPage />}
                        />

                        <Route
                            exact
                            path={RoutesConstants.FRECUENT_QUESTIONS_PAGE}
                            index
                            element={<FrequentQuestionsPage />}
                        />
                    </Routes>
                    <FooterWrapper />
                </>
            </AppState>
        </Router>
    );
};
