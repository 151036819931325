export const LoanRequestConstantsLS = {
    PersonToGetLoan: 'PersonToGetLoan',
    PaymentInformation: 'PaymentInformation',
    PaymentMethod: 'PaymentMethod',
    ReturnConfigPage: 'ReturnConfigPage',
    ReturnCbuPage: 'ReturnCbuPage',
    LoanData: 'LoanData',
    RequestId: 'RequestId',
    TokenUrl: 'TokenUrl',
    QuestionTitle: 'QuestionTitle',
    SiisaCode: 'SiisaCode',
    ClientValidation: 'ClientValidation',
    CardsQuantity: 'CardsQuantity',
    CreditDetailId: 'creditDetailId',
    AllowedMethods: 'AllowedMethods'
};

const Actions = {
    SET_PEOPLE_LIST: 'SET_PEOPLE_LIST',

    CLEAR_PEOPLE_LIST: 'CLEAR_PEOPLE_LIST',

    SET_PERSON_TO_GET_LOAN: 'SET_PERSON_TO_GET_LOAN'
};

export default Actions;
