import { Grid } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { ColorConstants } from '../../../Constants/ColorConstants';

const RowDetail = styled(Grid)(() => ({
    display: 'flex',
    width: '100%',
    // flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Archivo',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px'
}));

const Value = styled(Grid)(() => ({
    fontWeight: 600,
    color: ColorConstants.BLUE
}));

const Label = styled(Grid)(() => ({
    color: ColorConstants.BLUE
}));

export const RateDetail = (props) => {
    const { data = { tea: '', tna: '', cftea: '' } } = props;

    return (
        <RowDetail>
            <div>
                <Label>{'TEA'}</Label>
                <Value>{parseFloat(data.tea).toFixed(2)} %</Value>
            </div>

            <div>
                <Label>{'TNA'}</Label>
                <Value>{parseFloat(data.tna).toFixed(2)} %</Value>
            </div>

            <div>
                <Label>{'CFTEA'}</Label>
                <Value>{parseFloat(data.cftea).toFixed(2)} %</Value>
            </div>
        </RowDetail>
    );
};
