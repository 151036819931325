import { Box } from '@mui/material';

import React from 'react';

const ContainerForm = ({children}) => {
    return (
        <Box
            style={{ textAlign: 'center' }}
            maxHeight={'677px'}
            minHeight='520px'
            display="flex"
            flexWrap={'wrap'}
            alignContent={'space-between'}>{children}</Box>
    );
};

export default ContainerForm;
