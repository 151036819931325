import React from 'react';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { Box} from '@mui/material';
import { ContactInformationForm } from './ContactInformationForm';
import { ContactInfoState } from '../../../Contexts/Register/ContactInformation/ContactInfoState';
import ContactInfoContext from '../../../Contexts/Register/ContactInformation/ContactInfoContext';

export const ContactInformation = () => {

  const descriptionForm =
    'Solo nos queda pedirte unos datos más y tendrás tu cuenta creada.';

  return (
    <ContactInfoState>
      <Box style={{ textAlign: 'center' }}>
        <ReturnButton />
        <ContactInfoContext.Consumer>
        {(ctx) => {
          return ctx.data ? (<DescriptionForm title={`¡Último paso ${ctx.data.name}!`} description={descriptionForm} />) : null;
        }}
        </ContactInfoContext.Consumer>
        <ContactInformationForm/>
      </Box>
    </ContactInfoState>
  )
}
