import React, { useState, useEffect } from 'react';
import ContactInfoContext from './ContactInfoContext';
import { getData } from '../../../Services/BaseService';
import { postPersonForm } from '../../../Services/RegisterService';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { getItemService } from '../../../Services/LocalStorageService';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { RegisterConstantsLS } from '../RegisterActions';

export const ContactInfoState = ({ children }) => {

    const [options, setOptions] = useState({ provinces: [] })
    const [personToGetRegister] = useState(getItemService(RegisterConstantsLS.PersonToGetRegister) ? JSON.parse(getItemService(RegisterConstantsLS.PersonToGetRegister)) : {});
    const useSnackbarRef = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        getInitData();
    }, [])

    const getInitData = async () => {
        let url = 'province'
        let provinces = await getData(url);
        setOptions({ provinces: provinces })
    }

    const savePersonForm = async (form) => {
        let formatForm = {
            "phone": `${form.area}` + `${form.phoneNumber}`,
            "provinceId": form.province
        }
        let completeForm = { ...personToGetRegister, ...formatForm };
        await postPersonForm(completeForm);
        useSnackbarRef.enqueueSnackbar('Datos registrados de forma existosa.', 'success');
        navigate(RoutesConstants.REGISTER_SUCCESSFUL_PAGE);
    }

    return (
        <ContactInfoContext.Provider value={{
            options: options,
            data: personToGetRegister,
            savePersonForm: savePersonForm
        }}>
            {children}
        </ContactInfoContext.Provider>
    )
}
