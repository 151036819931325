import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import RegisterContext from './RegisterContext';
import { RegisterReducer, initialState } from './RegisterReducer';
import RegisterActions, { RegisterConstantsLS } from './RegisterActions';
import {
    createUserServiceFast,
    createUserService,
    resendEmailRegisterService,
    savePersonService
} from '../../Services/RegisterService';
import { tokenTimeValidate } from '../../Services/ChangePasswordService';
import jwt from 'jwt-decode';
import { getItemService, setItemService } from '../../Services/LocalStorageService';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import AppContext from './../../Contexts/Global/AppContext';
import { checkAccountService } from '../../Services/LoanRequestService';
import { useState } from 'react';
import { postPersonForm } from '../../Services/RegisterService';

export const RegisterState = ({ children }) => {
    const [state, dispatch] = useReducer(RegisterReducer, initialState);
    const [alreadyExistsAccount, setAlreadyExistsAccount] = useState(false);
    const navigate = useNavigate();
    const { tryRegisterExternal } = useContext(AppContext);

    const autoRegisterPerson = async (person) => {
        try {
            const registerPerson = await postPersonForm(person);
            console.log('registerPerson', registerPerson);
            navigate(RoutesConstants.REGISTER_SUCCESSFUL_PAGE);
        } catch (error) {
            console.log('error registerPerson', error);
        }
    };

    const savePerson = async (person) => {
        const token = getToken();
        delete person.personName;

        let ecomerceID;
        if (!tryRegisterExternal) {
            ecomerceID = jwt(token).Ecommerce;
        } else {
            ecomerceID = getItemService(RegisterConstantsLS.EcommerceAccountID);
        }

        try {
            let validatePerson = { status: true };
            if (person.personId != 0) {
                validatePerson = await checkAccountService({
                    personId: person.personId,
                    ecommerceAccountId: ecomerceID
                });
            }
            if (validatePerson.status) {
                setAlreadyExistsAccount(false);
                savePersonService(ecomerceID, person);
                navigate(RoutesConstants.REGISTER_CONTACT_INFORMATION_PAGE);
            }
        } catch (error) {
            if (
                error.response.data.errors[0].message.slice(0, 30) ==
                'El usuario ya posee una Cuenta'
            ) {
                setAlreadyExistsAccount(true);
            }
        }
    };

    const resendEmailRegisterWithToken = async () => {
        const token = getItemService(RegisterConstantsLS.TokenRegister);
        const ecommerceId = jwt(token).Ecommerce;

        await resendEmailRegisterService(ecommerceId);
        navigate(RoutesConstants.REGISTER_SEND_EMAIL_PAGE);
    };

    const resendEmailRegister = async () => {
        const ecommerceId = getItemService(RegisterConstantsLS.EcommerceAccountID);

        await resendEmailRegisterService(ecommerceId);
    };

    const getToken = () => {
        return getItemService(RegisterConstantsLS.TokenRegister);
    };

    const tokenValidate = () => {
        const token = getItemService(RegisterConstantsLS.TokenRegister);

        if (token == null) {
            return false;
        }

        return tokenTimeValidate(token);
    };

    const createUserMail = async (values) => {
        const response = await createUserService(values);

        setItemService(RegisterConstantsLS.EcommerceAccountID, response.data.ecommerceAccountId);

        dispatch({
            type: RegisterActions.SET_ECOMMERCE_ACCOUNT_ID,
            payload: response.data.ecommerceAccountId
        });
        if (response?.data?.result) {
            return response.data.result;
        }
        return response;
    };

    const createUserMailFast = async (values, autoRegisterPerson) => {
        const { cuit, dni, name, personId, phone, provinceId, sexTypeId } = autoRegisterPerson;
        const newValues = {
            identifier: values.email,
            password: values.password,
            personData: {
                cuit,
                dni,
                name,
                phone,
                personId: String(personId),
                provinceId: String(provinceId),
                sexTypeId: String(sexTypeId)
            }
        };

        const response = await createUserServiceFast(newValues);

        setItemService(RegisterConstantsLS.EcommerceAccountID, response.data.ecommerceAccountId);

        dispatch({
            type: RegisterActions.SET_ECOMMERCE_ACCOUNT_ID,
            payload: response.data.ecommerceAccountId
        });
        if (response?.data?.result) {
            return response.data.result;
        }
        return response;
    };

    return (
        <RegisterContext.Provider
            value={{
                ecommerceAccountId: state.ecommerceAccountId,
                alreadyExistsAccount,
                setAlreadyExistsAccount,
                createUserMail: createUserMail,
                savePerson: savePerson,
                getToken: getToken,
                tokenValidate: tokenValidate,
                resendEmailRegisterWithToken: resendEmailRegisterWithToken,
                resendEmailRegister: resendEmailRegister,
                createUserMailFast,
                autoRegisterPerson
            }}>
            {children}
        </RegisterContext.Provider>
    );
};

RegisterContext.propTypes = {
    children: PropTypes.element
};
