import { useState } from 'react';
import { getPersonByDNI } from '../Services/LoanRequestService';

export const useDNISearch = () => {
    const [peopleList, setPeopleList] = useState([]);

    const getByDNI = async (data) => {
        try {
            let peopleList = await getPersonByDNI(data);
            setPeopleList(peopleList);
        } catch (error) {
            setPeopleList(undefined);
        }
    };

    const clearPeopleList = () => {
        setPeopleList([]);
    };

    return { getByDNI, clearPeopleList, peopleList };
};
