import React from 'react';
import { Grid } from '@mui/material';
import HomeLayout from '../../../Components/HomeLayouts';
import { Description } from '../../../Components/DescriptionsLayout';
import { Outlet } from 'react-router-dom';
import { ChangePasswordState } from '../../../Contexts/ChangePassword/ChangePasswordState';

const titleDesc = 'Mi cuenta';
const descriptionDesc =
    'El detalle de tu préstamo en un mismo sitio, para que tengas una experiencia unificadora.';

const NewPasswordPage = () => {
    return (
        <ChangePasswordState>
            <Grid container>
                <HomeLayout
                    ContainerPanel={<Description title={titleDesc} description={descriptionDesc} />}
                    Form={<Outlet />}
                />
            </Grid>
        </ChangePasswordState>
    );
};

export default NewPasswordPage;
