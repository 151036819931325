import { Container } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import LoanRequestContext from '../../../Contexts/LoanRequest/LoanRequestContext';
import { isEmpty } from '../../../Helpers/Utils';
import { getItemService } from '../../../Services/LocalStorageService';
import CalendarOption from './CalendarOption';

const Calendar = () => {
    const { savePayDay, questionTitle } = useContext(LoanRequestContext);
    const [optionSelected, setOptionSelected] = useState();
    const [localQuestionTitle, setLocalQuestionTitle] = useState(
        getItemService('QuestionTitle') ? getItemService('QuestionTitle') : questionTitle
    );

    let timeout;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleOptionSelected = (option) => {
        clearInterval(timeout);
        setOptionSelected(option);
    };

    const sendOptionSelected = () => {
        savePayDay(optionSelected);
    };

    useEffect(() => {
        if (!isEmpty(optionSelected))
            timeout = setTimeout(() => {
                sendOptionSelected();
            }, 2000);
    }, [optionSelected]);

    const createCalendar = () => {
        let calendar = [];
        for (let i = 1; i < 32; i++) {
            calendar.push(
                <CalendarOption
                    key={`key${i}`}
                    active={optionSelected == i}
                    number={i}
                    onClick={() => handleOptionSelected(i)}
                />
            );
        }
        return calendar;
    };

    return (
        <Container>
            <ReturnButton fontSizeIcon={'medium'} />
            <DescriptionForm title={localQuestionTitle} />
            <DescriptionForm
                description={
                    'Atención: El vencimiento de la cuota de tu préstamo no dependerá de tu respuesta'
                }
                sx={{ mt: '-15px' }}
            />
            <Box display={'flex'} justifyContent={'center'}>
                <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
                    {createCalendar()}
                </Box>
            </Box>
            <BackHomeButton />
        </Container>
    );
};

export default Calendar;
