export const ChangePasswordConstantsLS = {
    TokenChangePassword: "TokenChangePassword"
}


const Actions = {

    SET_USER_EMAIL: 'SET_USER_EMAIL',
    
}


export default Actions;