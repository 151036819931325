import { Grid, styled, Typography } from '@mui/material';
import React from 'react';
import { ColorConstants } from '../../../Constants/ColorConstants';
import useFileDownload from '../../../Hooks/useFileDownload';
import DownloadButton from './DownloadButton';

const OptionContainer = styled(Grid)(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center'
}));

const Option = styled(Typography)(() => ({
    fontFamily: 'Archivo',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.02em',
    color: ColorConstants.BLUE
}));

const DownloadableOption = ({ option, text = true }) => {
    const { optionNumber, name, fileName } = option;
    const { downloadFile } = useFileDownload();

    const handleDownload = async () => {
        await downloadFile(optionNumber, fileName);
    }

    return (
        <OptionContainer onClick={() => handleDownload()}>
            <Grid item xs={10}>
                <Option>{name}</Option>
            </Grid>
            <Grid item xs={2} justifyContent='end' display='flex'>
                <DownloadButton text={text} />
            </Grid>
        </OptionContainer>
    );
};

export default DownloadableOption;
