import React, { useReducer, useEffect, useState, useContext } from 'react';
import AppContext from '../Global/AppContext';
import Actions from './HomeActions';
import HomeContext from './HomeContext';
import { HomeReducer, initialState } from './HomeReducer';
import { postContactForm } from '../../Services/ContactService';
import { useSnackbar } from 'notistack';
import {
    getFrequentQuestions,
    getFrequentQuestionsFetch
} from '../../Services/FrequentQuestionsService';
import { getItemService, setItemService } from '../../Services/LocalStorageService';
import { getDataPersonService } from '../../Services/ChangePasswordService';
import { getCreditDetailById } from '../../Services/LoanActiveService';
/* import { getCreditListPerPerson } from '../../Services/LoanActiveService'; */
import { useLocation } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';

let flag = true;

export const HomeState = ({ children }) => {
    const location = useLocation();
    const { creditsUser, creditDetailAppState } = useContext(AppContext);
    const [state, dispatch] = useReducer(HomeReducer, initialState);
    const [frequentQuestions, setFrequentQuestions] = useState(null);
    const useSnackbarRef = useSnackbar();
    const requestLoan = 'Solicitar';
    const [data, setData] = useState(null);
    const [creditList, setCreditList] = useState(null);
    const [creditDetail, setcreditDetail] = useState(null);

    useEffect(() => {
        if (creditsUser !== null) {
            setCreditList(creditsUser);
        }
    }, [creditsUser]);

    useEffect(() => {
        if (getItemService('Token')) {
            if (creditDetailAppState !== null) {
                setcreditDetail(creditDetailAppState);
            }
        }
    }, [creditDetailAppState]);

    useEffect(() => {
        if (flag) {
            getInitData();
            if (!data?.dni || !data?.email) {
                getContactInfo();
            }
            flag = false;
        } else {
            const frequentQuestions = JSON.parse(getItemService('FrequentQuestions'));
            if (frequentQuestions) {
                setFrequentQuestions(frequentQuestions);
            }
            if (!data?.dni || !data?.email) {
                getContactInfo();
            }
        }
    }, []);

    const getInitData = async () => {
        if (location.pathname === RoutesConstants.PUBLIC_HOME) {
            let data = await getFrequentQuestionsFetch();
            data = data.filter((x) => x.faqCategory == requestLoan);
            let frequentQuestions = data[0].faQs.filter((x, index) => index < 3);
            setItemService('FrequentQuestions', JSON.stringify(frequentQuestions));
            setFrequentQuestions(frequentQuestions);
        }
        if (!data?.dni || !data?.email) {
            getContactInfo();
        }
    };

    const getContactInfo = async () => {
        let token = getItemService('Token') ? getItemService('Token') : null;
        let contactInfo = JSON.parse(getItemService('ContactInfo'))
            ? JSON.parse(getItemService('ContactInfo'))
            : null;
        if (token) {
            if (!contactInfo) {
                let response = await getDataPersonService(
                    JSON.parse(getItemService('Person')).personId
                );
                setItemService('ContactInfo', JSON.stringify(response));
                setData(response);
            } else {
                setData(contactInfo);
            }
        }
    };

    const setExample = (value) => {
        dispatch({ type: Actions.SET_EXAMPLE, payload: value });
    };

    const saveContactForm = async (form, action) => {
        await postContactForm(form);
        action();
        useSnackbarRef.enqueueSnackbar('Su consulta fue enviada con éxito.', 'success');
    };

    /* const getCreditList = async () => {
        const person = getItemService('Person');
        if (person != null) {
            let creditList = await getCreditListPerPerson(
                JSON.parse(getItemService('Person')).personId
            );
            setCreditList(creditList);
        }
    }; */

    /*  const getCreditDetail = async (requestId) => {
        let creditDetail = await getCreditDetailById(requestId);
        setcreditDetail(creditDetail);
    }; */

    return (
        <HomeContext.Provider
            value={{
                example: state.example,
                setExample: setExample,
                frequentQuestions: frequentQuestions,
                saveContactForm: saveContactForm,
                data,
                creditList,
                /* getCreditDetail, */
                creditDetail
            }}>
            {children}
        </HomeContext.Provider>
    );
};
