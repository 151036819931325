import React, { useState, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppContext from './AppContext';
import { AppReducer, initialState } from './AppReducer';
import Actions, { AppConstantsLS, ACCESS_METHOD } from './AppActions';
import { RegisterConstantsLS } from './../Register/RegisterActions';
import {
    setItemService,
    removeItemService,
    getItemService
} from '../../Services/LocalStorageService';
import { login, externalLogin, addExternalAccount } from '../../Services/AuthService';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getCredits } from '../../Services/HeaderService';
import { getCreditDetailById } from '../../Services/LoanActiveService';
import { except_pages_chatBox } from '../../Configs/exceptionPaths';
import { InactiveDialog } from '../../Components/InactiveDialog/InactiveDialog';
import { timeout_routes } from '../../Configs/exceptionPaths';

import { useExpiredTime } from '../../Hooks/useExpiredTime';
import { AsyncScriptWrapper } from '../../Components/AsyncScriptWrapper/AsyncScriptWrapper';
import { postPersonForm } from '../../Services/RegisterService';

export const AppState = ({ children }) => {
    const isIdle = useExpiredTime({ time: 1000 * 60 * 5 });
    const location = useLocation();
    const dispatch = useReducer(AppReducer, initialState);
    const [authentication, setAuthentication] = useState(
        getItemService(AppConstantsLS.Person) ? getItemService(AppConstantsLS.Token) : null
    );
    const [user, setUser] = useState(
        getItemService(AppConstantsLS.Person)
            ? JSON.parse(getItemService(AppConstantsLS.Person))
            : null
    );
    const [accessMethod, setAccessMethod] = useState(getItemService(AppConstantsLS.AccessMethod));
    const [tryRegisterExternal, setTryRegisterByExternal] = useState(false);
    const [errorLogin, setErrorLogin] = useState(false);
    const [refreshPage, setRefreshPage] = useState(false);

    // Create for unify calls of the credits in login
    const [creditsUser, setCreditsUser] = useState(null);
    const [personId, setPersonId] = useState(null);
    const [creditDetailAppState, setCreditDetailAppState] = useState(null);

    const [inactive, setInactive] = useState(false);

    useEffect(() => {
        if (isIdle && timeout_routes.some((loc) => loc === location.pathname)) {
            setInactive(true);
        }
        if (location.pathname !== RoutesConstants.REGISTER_CHECK_DNI_PAGE) {
            removeItemService('REGISTER_METHOD');
        }
    }, [isIdle, location.pathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const getCreditDetail = async (requestId) => {
        const cd = await getCreditDetailById(requestId);
        setCreditDetailAppState(cd);
    };
    //

    const navigate = useNavigate();

    const getCreditsAppState = async () => {
        return await getCredits(user.personId);
    };

    useEffect(() => {
        if (user?.personId) {
            if (creditsUser === null && user?.personId !== null) {
                getCreditsAppState().then((response) => {
                    setCreditsUser(response);
                });
            }
        }
    }, [user?.personId]);

    const OnLogin = async (user, pass) => {
        const response = await login(user, pass);

        const errorLogin = 'El mail/Contraseña no se encuentra registrado.';
        if (response.response?.data.errors[0].message == errorLogin) {
            setErrorLogin(true);
        } else {
            setParametersStateLoginAccess(
                response.data.token,
                response.data.person,
                ACCESS_METHOD.MAIL,
                response.data.ecommerceAccountId
            );
            navigate(RoutesConstants.PRIVATE_HOME);
        }

        return response;
    };

    const AssociateFacebookAccount = async (data) => {
        if (data.status === 'unknown') return;
        const body = {
            personId: user.personId,
            identifier: data.userID,
            serviceResponse: JSON.stringify({
                id: data.id,
                email: data.email,
                name: data.name
            }),
            accountType: 1
        };

        const response = await addExternalAccount(body);
        if (Object.hasOwnProperty.call(response, 'errors') && response.errors) {
            return false;
        }

        setRefreshPage(!refreshPage);
    };

    const AssociateGoogleAccount = async (data) => {
        if (data?.error) return false;
        const body = {
            personId: user.personId,
            identifier: data.googleId,
            serviceResponse: JSON.stringify(data.profileObj),
            accountType: 2
        };

        const response = await addExternalAccount(body);
        if (Object.hasOwnProperty.call(response, 'errors') && response.errors) {
            return false;
        }

        setRefreshPage(!refreshPage);
    };

    const onLoginFacebook = async (data) => {
        if (data.status === 'unknown') return;
        const autoRegisterPerson = getItemService('PersonToRegisterAfterLoan')
            ? JSON.parse(getItemService('PersonToRegisterAfterLoan'))
            : null;
        setItemService('REGISTER_METHOD', ACCESS_METHOD.FACEBOOK);
        const body = {
            identifier: data.userID, //UserID (FacebookId o GoogleId)
            serviceResponse: JSON.stringify({
                id: data.id,
                email: data.email,
                name: data.name
            }), // Json de respuesta de Google o Facebook
            accountType: 1 //Facebook = 1 | Google = 2 | Mail = 3
        };
        const response = await externalLogin(body);
        if (Object.hasOwnProperty.call(response, 'errors') && response.errors) {
            return false;
        }

        if (!response.data.nominated) {
            setTryRegisterByExternal(true);
            setItemService(
                RegisterConstantsLS.EcommerceAccountID,
                response.data.ecommerceAccountId
            );
            if (autoRegisterPerson) {
                autoRegisterPerson.ecommerceAccountId = response.data.ecommerceAccountId;
                await postPersonForm(autoRegisterPerson);
                removeItemService('PersonToRegisterAfterLoan');
                setItemService('contactInfo', JSON.stringify(autoRegisterPerson));
                const person = {
                    personId: autoRegisterPerson.personId,
                    personName: autoRegisterPerson.name,
                    cuit: autoRegisterPerson.cuit
                };
                setParametersStateLoginAccess(
                    response.data.token,
                    person,
                    ACCESS_METHOD.FACEBOOK,
                    response.data.ecommerceAccountId
                );
                navigate(RoutesConstants.PRIVATE_HOME);
                return;
            }

            navigate(RoutesConstants.REGISTER_CHECK_DNI_PAGE);
            return;
        }

        setParametersStateLoginAccess(
            response.data.token,
            response.data.person,
            ACCESS_METHOD.FACEBOOK,
            response.data.ecommerceAccountId
        );
        navigate(RoutesConstants.PRIVATE_HOME);
        return;
    };

    const onLoginGoogle = async (data) => {
        if (data?.error) return false;
        const autoRegisterPerson = getItemService('PersonToRegisterAfterLoan')
            ? JSON.parse(getItemService('PersonToRegisterAfterLoan'))
            : null;
        setItemService('REGISTER_METHOD', ACCESS_METHOD.GOOGLE);
        const body = {
            identifier: data.googleId, //UserID (FacebookId o GoogleId)
            serviceResponse: JSON.stringify(data.profileObj), // Json de respuesta de Google o Facebook
            accountType: 2 //Facebook = 1 | Google = 2 | Mail = 3
        };
        const response = await externalLogin(body);
        if (Object.hasOwnProperty.call(response, 'errors') && response.errors) {
            return false;
        }

        if (!response?.data?.nominated) {
            setTryRegisterByExternal(true);
            setItemService(
                RegisterConstantsLS.EcommerceAccountID,
                response.data.ecommerceAccountId
            );
            if (autoRegisterPerson) {
                autoRegisterPerson.ecommerceAccountId = response.data.ecommerceAccountId;
                await postPersonForm(autoRegisterPerson);
                removeItemService('PersonToRegisterAfterLoan');
                setItemService('contactInfo', JSON.stringify(autoRegisterPerson));
                const person = {
                    personId: autoRegisterPerson.personId,
                    personName: autoRegisterPerson.name,
                    cuit: autoRegisterPerson.cuit
                };
                setParametersStateLoginAccess(
                    response.data.token,
                    person,
                    ACCESS_METHOD.GOOGLE,
                    response.data.ecommerceAccountId
                );
                navigate(RoutesConstants.PRIVATE_HOME);
                return;
            }

            navigate(RoutesConstants.REGISTER_CHECK_DNI_PAGE);
            return;
        }
        setParametersStateLoginAccess(
            response.data.token,
            response.data.person,
            ACCESS_METHOD.GOOGLE,
            response.data.ecommerceAccountId
        );
        navigate(RoutesConstants.PRIVATE_HOME);
        return;
    };

    const OnLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        setParametersStateLoginAccess();
        /*         navigate(RoutesConstants.PUBLIC_HOME); */
        window.location.replace(RoutesConstants.PUBLIC_HOME);
        return;
    };

    //Ejemplo de uso
    const setExample = (value) => {
        dispatch({ type: Actions.SET_EXAMPLE, payload: value });
    };

    const setParametersStateLoginAccess = (
        token = null,
        person = null,
        method = null,
        ecommerceAccountId = null
    ) => {
        if (token !== null && token !== undefined) {
            setItemService(AppConstantsLS.Token, token);
            setAuthentication(token);
        } else {
            removeItemService(AppConstantsLS.Token);
            setAuthentication(null);
        }

        if (person !== null && person !== undefined) {
            setItemService(AppConstantsLS.Person, person ? JSON.stringify(person) : null);
            setUser(person);
        } else {
            removeItemService(AppConstantsLS.Person);
            setUser(null);
        }

        if (ecommerceAccountId !== null && ecommerceAccountId !== undefined) {
            setItemService(
                AppConstantsLS.EcommerceAccountId,
                ecommerceAccountId ? ecommerceAccountId : null
            );
        } else {
            removeItemService(AppConstantsLS.EcommerceAccountId);
        }

        if (method !== null && method !== undefined) {
            setAccessMethod(method);
            setItemService(AppConstantsLS.AccessMethod, method);
        } else {
            removeItemService(AppConstantsLS.AccessMethod);
            setAccessMethod(null);
        }
    };

    return (
        <AppContext.Provider
            value={{
                authentication,
                tryRegisterExternal,
                user,
                accessMethod,
                errorLogin,
                refreshPage,
                setExample: setExample,
                OnLogin: OnLogin,
                OnLogout: OnLogout,
                onLoginFacebook,
                onLoginGoogle,
                setErrorLogin,
                AssociateFacebookAccount,
                AssociateGoogleAccount,
                setPersonId,
                creditsUser,
                setCreditsUser,
                creditDetailAppState,
                getCreditDetail
            }}>
            <AsyncScriptWrapper>{children}</AsyncScriptWrapper>
            {inactive && timeout_routes.some((loc) => loc === location.pathname) && (
                <InactiveDialog open={inactive} setOpen={setInactive} />
            )}
        </AppContext.Provider>
    );
};

AppState.propTypes = {
    children: PropTypes.element
};
