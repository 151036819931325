import { useState, useEffect } from "react";
import { GetIcon } from "../Helpers/GetIcon";
import { GetValidateFunction } from "../Helpers/GetValidateFunction";
// import moment from "moment";

export const useGenericForm = (formControls) => {
    const [form, setForm] = useState({});
    const [validForm, setValidForm] = useState(false);
    const [config, setConfig] = useState(null);

    useEffect(() => {
        setEmptyForm();
    }, [])

    useEffect(()=> {
        let config=[...formControls]
        config.forEach( x =>{
          x.icon = GetIcon(x.iconKey)
          x.validation = GetValidateFunction(x.validationKey);
          return x;
        })
        setConfig(config)
    },[])

    const setEmptyForm = () => {
        let emptyForm = {};
        for (var j = 0; j < formControls.length; j++) {
            emptyForm[formControls[j].field] = '';
        }
        setForm(emptyForm)
    }

    const setEmptyMessageField = () => {
        let emptyForm = {...form};
        emptyForm['message'] = '';
        setForm(emptyForm)
    }

    const initForm = (formValues) => {
        setForm(formValues);
    }

    const handleValue = (value, field) => {
        let newForm = { ...form };
        newForm[field] = value;
        setForm(newForm);
        validateForm(newForm);
    }

    //este metodo tiene que crecer
    const formatForm = (postFormatAction) => {
        let formatedForm = { ...form }
        for (var j = 0; j < formControls.length; j++) {
            if (formControls[j].type === 'int' && form[formControls[j].field]) {
                formatedForm[formControls[j].field] = parseInt(form[formControls[j].field]);
            }
            // if (formControls[j].type === 'date' && form[formControls[j].field]) {
            //     formatedForm[formControls[j].field] = moment(form[formControls[j].field]).format('YYYY-MM-DD')
            // }
        }
        if (postFormatAction) {
            postFormatAction();
        }
        return formatedForm;
    }

    const isEmpty = (config, value) => {
        switch (config.type){
            case 'text': return !value
            case 'autocomplete': if (config.multiple){
                return !value || (value && value.length == 0);
            }
            else return !value
            default: 
                return !value
        }
    }

    const validateForm= (form) => {
        let valid = [], isValid = true;
        let invalidFields = config ? config.filter(x => x.required && isEmpty(x, form[x.field])) : '';
        let isRequired = invalidFields.length === 0;
        config && config.forEach(x => {
           if(x.validationKey && form[x.field]) valid.push(x.validation(form[x.field], x))
        })
        for (const property in form) {
            if(form[property]=='') isValid=false 
            else isValid = true;
        }
        valid.forEach(x=>{ if (!x.isValid) isValid = false;})
        let validForm = isValid && isRequired;
        setValidForm(validForm)
    }

    return { form, config, validForm, initForm, handleValue, formatForm, setEmptyForm, setEmptyMessageField, validateForm, setConfig }
}