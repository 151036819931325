import { putData } from './BaseService';

export const dataUpdateService = async(personId, values) => {
    const url = 'register/change/emailphone';
    const response = await putData(url, {
        personId: personId,
        email: values.email,
        numCelular: values.phoneCode + values.phoneNumber
    });
    return response;
};
