import React from 'react';
import PropTypes from 'prop-types';
import TermsConditionsContext from './TermsConditionsContext';
import { getPolicyPrivacyService, getTermsConditionsService } from '../../Services/TermsConditionsService';

export const TermsConditionsState = ({ children }) => {

    const getTermsConditions = async () => {
        const response = await getTermsConditionsService();

        return response;
    }

    const getPolicyPrivacy = async () => {
        const response = await getPolicyPrivacyService();

        return response;
    }


    return (
        <TermsConditionsContext.Provider
            value={{
                getTermsConditions,
                getPolicyPrivacy
            }}>
            {children}
        </TermsConditionsContext.Provider>
    );
};

TermsConditionsContext.propTypes = {
    children: PropTypes.element
};
