import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { ColorConstants } from '../../Constants/ColorConstants';
import style from './socialBtn.module.css';

const SocialAccountsButtons = ({ google, associated = false, action, sx, title }) => {
    return (
        <Button
            className={style.socialBtnParent}
            variant="outlined"
            fullWidth
            onClick={action}
            disabled={associated}
            /* startIcon={<img src={google ? 'googleIcon.svg' : 'facebookIcon.svg'}></img>} */
            endIcon={<img src={associated ? 'associatedAndCheck.svg' : ''}></img>}
            sx={{
                borderRadius: '30px',
                border: '1px solid #dadce0',
                height: 40,
                px: 0.5,
                mt: 1,
                ':disabled': { color: ColorConstants.BLUE },
                ...sx
            }}>
            <Grid item xs={1} ml={0} justifyContent={'center'} display={'flex'}>
                <img src={google ? 'googleIcon.svg' : 'facebookIcon.svg'}></img>
            </Grid>
            <Grid item xs={12} textAlign={'center'}>
                {!title ? (
                    <Typography
                        fontWeight={500}
                        alignItems="center"
                        ml={associated ? 6 : -4}
                        fontFamily={'Archivo'}>
                        {google ? 'Google' : 'Facebook'}
                    </Typography>
                ) : (
                    <Typography
                        className={style.socialBtn}
                        fontSize={14}
                        fontWeight={500}
                        alignItems="center"
                        ml={associated ? 6 : 0}>
                        {title}
                    </Typography>
                )}
            </Grid>
        </Button>
    );
};

export default SocialAccountsButtons;
