import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { ContactInformationForm } from './ContactInformationForm';
import LoanRequestContext from '../../../Contexts/LoanRequest/LoanRequestContext';
import { firstName } from '../../../Helpers/Masks';

export const ContactInformationPage = () => {
    const descriptionForm =
        'Te enviaremos un código por SMS a tu celular para que veas cuanto te podemos ofrecer.';
    const { data } = useContext(LoanRequestContext);

    return (
        <Box style={{ textAlign: 'center' }}>
            <ReturnButton />
            <DescriptionForm
                title={`${firstName(data?.personName)}, necesitamos algunos datos para continuar`}
                description={descriptionForm}
            />
            <ContactInformationForm />
        </Box>
    );
};
