import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import MobileStepper from '@mui/material/MobileStepper';
import { GetElementToRender } from '../../Helpers/GetElementToRender';
import BiometricDataContext from '../../Contexts/BiometricData/BiometricDataContext';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { cleanSteps } from '../../Services/BiometricDataService';
import { StepsConstants } from '../../Constants/StepsConstants';
import { set } from 'react-ga';
import { Spinner } from '../../Components/Layout/Spinner';

export const BiometricDataPage = () => {
    const { activeStep, inputRef, saveImage, images } = useContext(BiometricDataContext);
    const [activeComponent, setActiveComponent] = useState();

    useEffect(() => {
        const timeOut = setTimeout(() => {
            const component = GetElementToRender(activeStep);
            setActiveComponent(component);
        }, 250);

        return () => {
            clearTimeout(timeOut);
        };
    }, [activeStep]);

    return (
        <Box textAlign={'center'}>
            <Grid container sx={{ textAlign: 'center' }}>
                <Grid item xs={2} sm={2}>
                    <ReturnButton
                        route={
                            images[StepsConstants.FIRST_STEP].photo
                                ? RoutesConstants.BIOMETRIC_DATA_PAGE
                                : RoutesConstants.LOAN_OFFER_SELECTION
                        }
                        additionalAction={() => {
                            cleanSteps();
                            location.reload();
                        }}
                    />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid
                    item
                    xs={3}
                    sm={4}
                    sx={{ ml: 4, textAlign: 'center' }}
                    justifyContent={'center'}>
                    <MobileStepper
                        variant="dots"
                        steps={4}
                        position="static"
                        activeStep={activeStep}
                        sx={{ maxWidth: 500, flexGrow: 1 }}
                    />
                </Grid>
                <Grid item xs={4}></Grid>
                <Box ref={inputRef} display="none">
                    <input
                        id="camera"
                        type="file"
                        accept="image/*"
                        capture="camera"
                        onChangeCapture={(e) => saveImage(e)}
                    />
                </Box>
            </Grid>
            {activeComponent}
        </Box>
    );
};
