import React from 'react';
import { Grid } from '@mui/material';
import HomeLayout from '../../../Components/HomeLayouts';
import { Description } from '../../../Components/DescriptionsLayout';
import { Outlet } from 'react-router-dom';
import { RegisterState } from '../../../Contexts/Register/RegisterState';

const titleDesc = 'Crea tu cuenta';
const descriptionDesc = 'Consultá el estado de tu préstamo en un solo lugar, de forma simple y clara.';

const CreateAccountPage = () => {
    return (
        <RegisterState>
            <Grid container>
                <HomeLayout
                    ContainerPanel={<Description title={titleDesc} description={descriptionDesc} />}
                    Form={<Outlet />}
                />
            </Grid>
        </RegisterState>
    );
};

export default CreateAccountPage;
