import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";

const PeopleOption = ({ people, valueCheck, actionCheck }) => {
    return (
        <Grid container>
            <Grid item xs={10} alignContent={'start'} display={'flex'}>
                <Typography
                    variant="h6"
                    component="h4"
                    color="primary"
                    sx={{ fontWeight: 'bold', m: '3%' }}>
                    {people.personName}
                </Typography>
            </Grid>
            <Grid item xs={2} justifyContent={'rigth'} display={'flex'}>
                <FormControlLabel
                    control={<Checkbox />}
                    onChange={(e) =>
                        actionCheck({ value: e.target.checked, name: people.personName })
                    }
                    checked={ valueCheck ? people.personName == valueCheck.name : false}
                    label=""
                    labelPlacement="start"
                />
            </Grid>
        </Grid>
    );
};

export default PeopleOption;