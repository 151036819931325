import { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { ImageCapture } from './Components/ImageCapture';
import { TransitionStep } from './Components/TransitionStep';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import BiometricDataContext from '../../Contexts/BiometricData/BiometricDataContext';
import { StepsConstants } from '../../Constants/StepsConstants';
import { BiometricDataConstants } from '../../Constants/BiometricDataConstants';
import { useNavigate } from 'react-router-dom';
import Toast from '../../Components/Toast';
import { useDialog } from '../../Hooks/useDialog';
import { DNIModal } from './Components/DNIModal';

export const SecondStep = () => {
    const { confirmPhoto, images, error, attempt, openCamera, setAttempt } =
        useContext(BiometricDataContext);

    const [errorMessage, setErrorMessage] = useState(null);

    const [blocked, setBlocked] = useState(false);

    const dialog = useDialog();

    const handleOpenModal = () => {
        dialog.setTitle('Foto Dorso DNI');
        dialog.setContent('Por favor, ahora captura una foto del dorso de tu DNI.');
        dialog.OpenDialog();
    };

    useEffect(() => {
        handleOpenModal();
    }, []);

    const handleSetDataAndExecute = async () => {
        setErrorMessage(null);
        setBlocked(false);
        let data = {
            backDNI: images[StepsConstants.SECOND_STEP].photo,
            entreConsultasImage: [],
            step: StepsConstants.SECOND_STEP,
            biometricDataType: BiometricDataConstants.BACK_DNI,
            extension: 'png'
        };
        /*  await confirmPhoto(data, RoutesConstants.BIOMETRIC_DATA_PAGE); */
        try {
            if (!data.backDNI) {
                Toast.error('Ocurrió un error al capturar la foto, por favor volvé a tomarla.');
                setErrorMessage({
                    title: 'Error al tomar la foto',
                    description: 'Ocurrió un error al capturar la foto, por favor volvé a tomarla.'
                });
                return;
            }
            const res = await confirmPhoto(data);
            /*  alert(`RES: ${JSON.stringify(res)}`); */
            if (res?.ErrorType && Number(attempt) < 2) {
                setErrorMessage({
                    title: res.message.split('.')[0],
                    description: res.message.split('.')[1]
                });
                return;
            }

            const errors = res?.response?.data?.errors[0].message;

            if (
                errors.includes('El DNI Ingresado no Coincide') ||
                errors.toLowerCase().includes('el dni ingresado no coincide')
            ) {
                setAttempt((prev) => prev - 1);
                setErrorMessage({
                    title: 'No pudimos validar tu identidad',
                    description: errors.split(':')[1]
                });
                setBlocked(true);
                return;
            }

            if (res.response?.data?.errors && Number(attempt) < 2) {
                if (errors && errors.split(':').length > 1) {
                    setErrorMessage({
                        title: 'No pudimos validar tu identidad',
                        description: errors.split(':')[1]
                    });
                    return;
                }

                if (errors && Number(attempt) < 2) {
                    if (errors.includes('validar tu identidad')) {
                        setErrorMessage({
                            title: errors.split('.')[0],
                            description: errors.split('.')[1]
                        });
                    } else {
                        setErrorMessage({
                            title: 'No pudimos validar tu identidad',
                            description: errors
                        });
                        return;
                    }
                }
            }
            if (res.response?.data?.errors && Number(attempt) < 2) {
                const errors = res.response.data.errors[0].message.split('.');
                setErrorMessage({ title: errors[0], description: errors[1] });
                return;
            }
            setBlocked(false);
            setErrorMessage(null);
        } catch {
            console.log('Error..');
        }
    };

    return (
        <Grid>
            <Grid>
                <ImageCapture
                    title={`Confirmemos tu foto del dorso`}
                    description="Si ya lograste una foto nítida, avancemos con el siguiente paso."
                    image={images[StepsConstants.SECOND_STEP].photo}
                    action={() => handleSetDataAndExecute()}
                    repeatCapture={() => {
                        openCamera(StepsConstants.SECOND_STEP, true);
                        setErrorMessage(null);
                    }}
                    error={error}
                    attempt={attempt}
                    disabled={errorMessage != null && Number(attempt) < 3}
                    errorMessage={errorMessage}
                    dialog={dialog}
                />
            </Grid>
        </Grid>
    );
};
