import React from 'react';
import { Typography, Link } from '@mui/material';
import { RoutesConstants } from '../Constants/RoutesConstants';
import { useLocation } from 'react-router-dom';

export const AcceptTermsAndPolicy = () => {
    const location = useLocation();
    return (
        <Typography
            variant="h7"
            component="h5"
            color="#004489"
            textAlign="start"
            fontFamily="Archivo"
            sx={{ fontWeight: 'regular', paddingTop: '101px' }}>
            Al continuar declaro que soy mayor de edad y acepto los{' '}
            <b>
                <Link
                    onClick={() => history.push(location.pathname)}
                    href={RoutesConstants.TERMS_PAGE}>
                    Términos y condiciones
                </Link>
            </b>{' '}
            y las{' '}
            <b>
                <Link
                    onClick={() => history.push(location.pathname)}
                    href={RoutesConstants.PRIVACY_PAGE}>
                    Políticas de privacidad
                </Link>
            </b>{' '}
            de Directo.
        </Typography>
    );
};
