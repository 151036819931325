import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { RoutesConstants } from '../Constants/RoutesConstants';
import AppContext from '../Contexts/Global/AppContext';

const PrivateRoute = () => {

    const { authentication } = useContext(AppContext);
    
    return authentication ? <Outlet /> : <Navigate to={RoutesConstants.LOGIN_PAGE} />;
}

export default PrivateRoute;