import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import HeaderContext from './HeaderContex';
import { setItemService } from '../../Services/LocalStorageService';
import { useState } from 'react';
import { getDataPersonService } from '../../Services/ChangePasswordService';
import { getPersonByDNI } from '../../Services/LoanRequestService';
import { LoanRequestConstantsLS } from '../LoanRequest/LoanRequestActions';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { useContext } from 'react';
import AppContext from '../Global/AppContext';
import {
    getCredits,
    obtainRecommendations,
    obtainRecommendationsFetch
} from '../../Services/HeaderService';
import { MobileCheck } from '../../Helpers/MobileCheck';
import { useEffect } from 'react';
import { DetectMobile } from '../../Utils/DetectMobile';

export const HeaderState = ({ children }) => {
    const { authentication, user, creditsUser } = useContext(AppContext);
    const [previousLoan, setPreviousLoan] = useState(false);
    const [recommendation, setRecommendation] = useState({ qualified: false });
    const navigate = useNavigate();

    const getInitData = async (personId) => {
        if (creditsUser === null) return;
        const previousCredits = creditsUser;
        const hasPreviousCredits =
            previousCredits.credits.length > 0 || previousCredits.creditsCanceled.length > 0;
        setPreviousLoan(hasPreviousCredits);
        if (hasPreviousCredits) {
            const recommendation = await obtainRecommendationsFetch(personId);
            setRecommendation(recommendation);
        }
    };

    const getRecomendations = () => {
        if (!user?.personId) return;
        obtainRecommendationsFetch(user.personId).then((response) => {
            setRecommendation(response);
        });
    };

    const RequestLoanPrivate = async () => {
        if (await DetectMobile()) {
            const userData = await getDataPersonService(user.personId);

            const peopleList = await getPersonByDNI({
                dni: userData.dni,
                sexTypeId: userData.sexTypeId
            });

            const filteredPerson = peopleList.filter(
                (people) => people.personName == userData.userName
            );

            const personData = {
                ...filteredPerson[0],
                name: filteredPerson[0].personName,
                dni: userData.dni,
                sexTypeId: userData.sexTypeId,
                skipToOfferSelection: true
            };

            setItemService(LoanRequestConstantsLS.PersonToGetLoan, JSON.stringify(personData));

            if (recommendation?.qualified) {
                recoverPreviousRequest();
            }
            navigate(RoutesConstants.LOAN_CONTACT_INFORMATION_PAGE);
        } else {
            navigate(RoutesConstants.LOAN_REDIRECT_PAGE);
        }
    };

    const recoverPreviousRequest = () => {
        const loanData = {
            pendingRequestId: recommendation.pendingRequestId,
            recommendation: true,
            offer: [
                {
                    maximumAmount: recommendation.amount
                }
            ]
        };
        setItemService(LoanRequestConstantsLS.LoanData, JSON.stringify(loanData));
    };

    return (
        <HeaderContext.Provider
            value={{
                previousLoan,
                recommendation,
                authentication,
                getInitData,
                RequestLoanPrivate,
                getRecomendations
            }}>
            {children}
        </HeaderContext.Provider>
    );
};

HeaderState.propTypes = {
    children: PropTypes.element
};
