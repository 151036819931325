import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { CardActiveLoanDetail } from './CardActiveLoanDetail';
import { LoanActiveState } from '../../Contexts/LoanActive/LoanActiveState';
import LoanActiveContext from '../../Contexts/LoanActive/LoanActiveContext';
import { LoanEvolution } from './LoanEvolution';
import DownloadContainer from './DownloadContainer';

export const ActiveLoanGeneralPage = () => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <LoanActiveState>
            <Grid container>
                <Grid item sm={2}></Grid>
                <Grid item xs={10} sm={8} ml={desktop ? 3 : 4}>
                    <LoanActiveContext.Consumer>
                        {(ctx) => {
                            return ctx.creditDetail ? (
                                <CardActiveLoanDetail
                                    list={false}
                                    size={desktop ? '40px' : '18px'}
                                    sizeQuoteExpression={!desktop && '12px'}
                                    sizeQuotes={!desktop && '14px'}
                                    credit={ctx?.creditDetail.credits}
                                />
                            ) : (
                                ''
                            );
                        }}
                    </LoanActiveContext.Consumer>
                </Grid>
                <Grid item xs={1} sm={2}></Grid>
                <Grid container spacing={desktop ? 10 : 2} ml={desktop ? -8 : 4}>
                    <Grid item sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
                    <DownloadContainer desktop={desktop} />
                    <Grid xs={1} sx={{ display: { xs: 'block', sm: 'none' } }}></Grid>

                    <Grid item xs={10} sm={5} md={4} mb={3}>
                        <LoanEvolution />
                    </Grid>
                    <Grid sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
                </Grid>
            </Grid>
        </LoanActiveState>
    );
};
