export const getItemService = (name) => {
    return sessionStorage.getItem(name) || localStorage.getItem(name);
};

export const setItemService = (name, item) => {
    localStorage.setItem(name, item);
    sessionStorage.setItem(name, item);
};

export const removeItemService = (name) => {
    localStorage.removeItem(name);
    sessionStorage.removeItem(name);
};
