import React from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Paper } from '@mui/material'
import AccordionCustom from '../../Components/AccordionCustom'
import parse from 'html-react-parser';
import FrequentQuestionsContext from '../../Contexts/FrequentQuestions/FrequentQuestionsContext';


export const FrequentQuestionsTabs = () => {

    const {frequentQuestions} = React.useContext(FrequentQuestionsContext);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  return (
    <Paper sx={{borderRadius: '15px'}}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', textAlign: 'center' }}>
                    <TabList centered onChange={handleChange} aria-label="lab API tabs example" sx={{textAlign: 'center', justifyContent: 'center'}}>
                        { frequentQuestions && frequentQuestions.map((x, index) => {
                                return (<Tab key={x.faqCategory} label={x.faqCategory} value={index} sx={{textAlign: 'center'}} />) 
                            })
                        }
                    </TabList>
                </Box>
                <TabPanel value={value}>
                    { frequentQuestions && frequentQuestions[value].faQs.map(x => {
                            return (<AccordionCustom key={x.question} title = {parse(x.question)}
                                information ={parse(x.answere)}/>) 
                        })
                    }
                </TabPanel>
            </TabContext>
        </Box>
    </Paper>
  )
}
