import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Footer } from './Footer';
import { RoutesConstants } from '../../Constants/RoutesConstants';

export const FooterWrapper = () => {
    const [footer, setFooter] = useState(null);
    const routerLocation = useLocation();

    useEffect(() => {
        if (routerLocation.pathname === RoutesConstants.CARD_CRED_IFRAME) {
            setFooter(null);
            return;
        }
        setFooter(renderFooter(routerLocation.pathname));
    }, [routerLocation.pathname]);

    const renderFooter = (pathname) => {
        switch (pathname) {
            case `${RoutesConstants.DNI_FRONT_PAGE}/dorso`:
                break;
            case `${RoutesConstants.DNI_FRONT_PAGE}/frente`:
                break;
            case `${RoutesConstants.FACE_CAPTURE_PAGE}/neutra`:
                break;
            case `${RoutesConstants.FACE_CAPTURE_PAGE}/sonriendo`:
                break;
            case `${RoutesConstants.LOAN_SATISFACTORY_CARD_LOAD}`:
                break;
            default:
                return <Footer />;
        }
    };

    return <>{footer}</>;
};
