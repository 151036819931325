export const RoutesConstants = {
    ANY_ROUTE: '*',
    PUBLIC_HOME: '/',
    PRIVATE_HOME: '/inicio-privado',
    //PAGINAS DEL FOOTER
    CONTACT_PAGE: '/contacto',
    FRECUENT_QUESTIONS_PAGE: '/preguntas-frecuentes',
    PRIVACY_PAGE: '/politica-privacidad',
    TERMS_PAGE: '/terminos-condiciones',
    //PRESTAMOS
    LOAN_REQUEST_PAGE: '/solicitud-prestamo',
    LOAN_CONTACT_INFORMATION_PAGE: '/informacion-contacto-prestamo',
    LOAN_TOKEN_PAGE: '/validacion-token-prestamo',
    LOAN_REDIRECT_PAGE: '/redireccion-prestamo',
    LOAN_NO_OFFERS: '/sin-oferta',
    LOAN_TYPE_OCUPPATION: '/tipo-ocupacion',
    LOAN_CALENDAR: '/dia-cobro',
    LOAN_SUMMARY_PAGE: '/resumen-prestamo',
    LOAN_DETAILS_PAGE: '/detalles-prestamo',
    LOAN_OFFER_SELECTION: '/seleccion-oferta',
    LOAN_CBU: '/validacion-cbu',
    LOAN_PAY_METHOD: '/metodo-pago',
    LOAN_ACTIVE_PAGE: '/prestamo-activo',
    LOAN_LAST_SATISFACTORY_SCREEN: '/prestamo-aprobado',
    LOAN_SATISFACTORY_CARD_LOAD: '/carga-satisfactoria',
    LOAN_LAST_DIFFERENTIAL_SCREEN: '/prestamo-pendiente',
    LOAN_CARD_LOAD: '/nueva-tarjeta',
    //LOGIN
    LOGIN_PAGE: '/iniciar-sesion',
    //REGISTRO
    REGISTER_PAGE: '/registro',
    REGISTER_CREATE_ACCOUNT_PAGE: '/crear-cuenta',
    REGISTER_SUCCESSFUL_PAGE: '/registro-exito',
    REGISTER_CHECK_DNI_PAGE: '/registro-validacion-dni',
    REGISTER_CONTACT_INFORMATION_PAGE: '/informacion-contacto',
    REGISTER_SEND_EMAIL_PAGE: '/registro-envio-correo',
    REGISTER_EXPIRED_LINK_PAGE: '/registro-link-expirado',
    //NUEVA CONTRASEÑA
    NEW_PASSWORD_CHANGE_PAGE: '/proceso-cambio-password',
    NEW_PASSWORD_CHANGE_REQUEST_PAGE: '/solicitud-cambio-password',
    NEW_PASSWORD_SUCCESS_EMAIL_PAGE: '/correo-cambio-password',
    NEW_PASSWORD_EXPIRED_LINK_PAGE: '/link-expirado',
    NEW_PASSWORD_SUCCESSFUL_PAGE: '/cambio-password-exito',
    //PERFIL
    PROFILE_CHANGE_PASSWORD: '/cambio-password-perfil',
    UPDATE_USER_DATA: '/actualizacion-datos',
    NEW_PASSWORD_SUCCESSFUL_PRIVATE_PAGE: '/cambio-password-perfil-exito',
    //DATOS BIOMETRICOS
    BIOMETRIC_DATA_PAGE: '/datos-biometricos',
    DNI_FRONT_PAGE: '/datos-biometricos/DNI-front',
    FACE_CAPTURE_PAGE: '/datos-biometricos/Face-capture',
    //IFRAME
    CARD_CRED_IFRAME: '/cardCredIframe',
    CARD_CRED_IFRAME_ERROR: '/cardCredIframeError'
};
