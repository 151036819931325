import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormControl, Grid, OutlinedInput, Typography } from '@mui/material';
import GrayButton from '../../../Components/LoginBtns/GrayButton';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { Box } from '@mui/system';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { allValuesComplete, isEmpty } from '../../../Helpers/Utils';
import LoanRequestContext from '../../../Contexts/LoanRequest/LoanRequestContext';
import ErrorLabel from '../../../Components/Form/ErrorLabel';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { trackPromise } from 'react-promise-tracker';
import { setItemService, getItemService } from '../../../Services/LocalStorageService';
import { LoanRequestConstantsLS } from '../../../Contexts/LoanRequest/LoanRequestActions';
import { Helmet } from 'react-helmet';

const TokenForm = () => {
    const { verifySMSCode, verifySMSCodeSuccess, resendSMSCode, errorToken, setErrorToken } =
        useContext(LoanRequestContext);

    let text;
    const boxRef = useRef(null);
    const initialStateValues = { 1: '', 2: '', 3: '', 4: '' };
    const TokenPosition = {
        First: 1,
        Second: 2,
        Third: 3,
        Fourth: 4
    };

    const navigate = useNavigate();
    const [values, setValues] = useState(initialStateValues);
    const [valuesRef] = useState({ 1: useRef(), 2: useRef(), 3: useRef(), 4: useRef() });
    const [onFocus, setOnFocus] = useState(TokenPosition.First);
    const [lapseTime, setLapseTime] = useState(true);
    const [countdown, setCountdown] = useState(15);

    useEffect(() => {
        setErrorToken(false);
    }, []);

    useEffect(() => {
        let intervalId;
        if (countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000);
        } else {
            setLapseTime(false);
        }

        return () => clearInterval(intervalId);
    }, [countdown]);

    let timeout;
    const handleChange = (prop) => (event) => {
        setErrorToken(false);
        clearInterval(timeout);
        const value = event.target.value.replace(/\s/g, '');

        //Borra y retrocede un campo
        if (value.length === 0) {
            setValues({ ...values, [prop]: value });
        }

        //Escribe y avanza al próximo campo
        if (value.length === 1) {
            setValues({ ...values, [prop]: value });
            if (prop + 1 != 5) {
                setOnFocus(prop + 1);
                valuesRef[prop + 1].current.click();
            }
        }

        //Caso especial: Segundo valor en el mismo campo
        if (value.length === 2) {
            const splitValues = String(value).split('');
            setValues({ ...values, [prop + 1]: splitValues[1] });
            if (prop + 1 != 5) {
                setOnFocus(Number([prop]) + 1);
                valuesRef[prop + 1].current.click();
            }
        }

        //Al pegar un token completo
        if (value.length == 4) {
            const splitValues = String(value).split('');
            setValues({
                ...values,
                [prop]: splitValues[0],
                [prop + 1]: splitValues[1],
                [prop + 2]: splitValues[2],
                [prop + 3]: splitValues[3]
            });
        }
    };

    useEffect(() => {
        if (!isEmpty(values[4]) && allValuesComplete(values)) {
            timeout = setTimeout(() => {
                setCountdown(0);
                verifySMSCode(values[1] + values[2] + values[3] + values[4]);
            }, 1000);
        }
    }, [values]);

    useEffect(() => {
        if (verifySMSCodeSuccess) {
            setTimeout(() => {
                navigate(RoutesConstants.LOAN_OFFER_SELECTION);
            }, 2000);
        }
    }, [verifySMSCodeSuccess]);

    const cleanToken = () => {
        setValues(initialStateValues);
        setErrorToken(false);
        setOnFocus(1);
        valuesRef[1].current.click();
    };

    const onSubmit = async () => {
        await getFingerPrint();
        cleanToken();
        setCountdown(15);
        setLapseTime(true);
        resendSMSCode();
    };

    const getFingerPrint = async () => {
        if (boxRef.current) {
            const getSiisaCode = async () => {
                await trackPromise(
                    new Promise((resolve, reject) => {
                        // eslint-disable-next-line no-undef
                        fillSiisaData('jsdata');
                        const code = boxRef.current?.firstChild?.value;
                        if (code) {
                            setItemService(LoanRequestConstantsLS.SiisaCode, code);
                            resolve();
                        } else {
                            reject();
                        }
                    })
                );
            };

            if (getItemService(LoanRequestConstantsLS.SiisaCode) == null) {
                getSiisaCode();
            }
        }
    };

    const timerMessage = `Si aún no lo recibiste en 0:${
        countdown > 9 ? countdown : '0' + countdown
    } podrás pedir otro.`;

    const errorTokenMessage =
        'El código ingresado no es válido, revisá que coincida con el enviado o pedí uno nuevo';

    return (
        <Grid
            display={'flex'}
            flexWrap={'wrap'}
            alignContent={'space-between'}
            height={'480px'}
            width="100%">
            <Grid container justifyContent={'center'}>
                <Box display="flex">
                    <Box mx={0.5} my={1}>
                        <FormControl fullWidth focused={onFocus == TokenPosition.First}>
                            <OutlinedInput
                                id={`${TokenPosition.First}`}
                                value={values[TokenPosition.First]}
                                className={`tokenInput ${
                                    verifySMSCodeSuccess ? 'tokenSuccess' : ''
                                }`}
                                autoFocus={true}
                                onChange={handleChange(TokenPosition.First)}
                                color="info"
                                ref={valuesRef[1]}
                                error={errorToken}
                                inputProps={{
                                    inputMode: 'numeric'
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box mx={0.5} my={1}>
                        <FormControl fullWidth focused={onFocus == TokenPosition.Second}>
                            <OutlinedInput
                                id={`${TokenPosition.Second}`}
                                value={values[TokenPosition.Second]}
                                className={`tokenInput ${
                                    verifySMSCodeSuccess ? 'tokenSuccess' : ''
                                }`}
                                onChange={handleChange(TokenPosition.Second)}
                                color="info"
                                ref={valuesRef[2]}
                                error={errorToken}
                                inputProps={{
                                    inputMode: 'numeric'
                                }}
                            />
                        </FormControl>
                    </Box>{' '}
                    <Box mx={0.5} my={1}>
                        <FormControl fullWidth focused={onFocus == TokenPosition.Third}>
                            <OutlinedInput
                                id={`${TokenPosition.Third}`}
                                value={values[TokenPosition.Third]}
                                className={`tokenInput ${
                                    verifySMSCodeSuccess ? 'tokenSuccess' : ''
                                }`}
                                onChange={handleChange(TokenPosition.Third)}
                                color="info"
                                ref={valuesRef[3]}
                                error={errorToken}
                                inputProps={{
                                    inputMode: 'numeric'
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box mx={0.5} my={1}>
                        <FormControl fullWidth focused={onFocus == TokenPosition.Fourth}>
                            <OutlinedInput
                                id={`${TokenPosition.Fourth}`}
                                value={values[TokenPosition.Fourth]}
                                className={`tokenInput ${
                                    verifySMSCodeSuccess ? 'tokenSuccess' : ''
                                }`}
                                onChange={handleChange(TokenPosition.Fourth)}
                                color="info"
                                ref={valuesRef[4]}
                                error={errorToken}
                                inputProps={{
                                    inputMode: 'numeric'
                                }}
                            />
                        </FormControl>
                    </Box>
                </Box>
                <Box width={'100%'} display="flex" justifyContent="center" flexWrap="wrap">
                    {errorToken ? (
                        <Grid item xs={10} display="flex" justifyContent="center">
                            <ErrorLabel fontSizeText={'14px'} description={errorTokenMessage} />
                        </Grid>
                    ) : null}
                    {lapseTime ? (
                        <Typography fontFamily={'Archivo'} color={ColorConstants.BLUE}>
                            {timerMessage}
                        </Typography>
                    ) : null}
                </Box>
            </Grid>
            <Box width={'100%'}>
                <GrayButton
                    text={'Quiero un código nuevo'}
                    action={() => onSubmit()}
                    disabled={lapseTime}
                />
                <BackHomeButton />
            </Box>
            <Helmet>
                <script
                    className="siisa-validation"
                    async
                    src="https://motor.siisa.com.ar/fdsb/fdsb.js?v=1"></script>
            </Helmet>
            <div ref={boxRef}>
                <textarea id="jsdata" style={{ display: 'none' }} value={text}></textarea>
            </div>
        </Grid>
    );
};

export default TokenForm;
