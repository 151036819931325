import { ThemeProvider } from '@emotion/react';
import { Box, CssBaseline, Zoom } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import './App.css';
import { Spinner } from './Components/Layout/Spinner';
import { SnackbarUtilsConfigurator } from './Components/Toast';
import { MainPage } from './Pages/MainPage';
import { theme } from './Styles/theme';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { MetaPixel } from './Components/MetaPixel/MetaPixel';

function App() {
    useEffect(() => {
        // eslint-disable-next-line no-undef
        const { REACT_APP_TRACKING_ID } = process.env;
        ReactGA.initialize(REACT_APP_TRACKING_ID);
    }, []);

    return (
        <SnackbarProvider TransitionComponent={Zoom} autoHideDuration={1500} maxSnack={2}>
            <CssBaseline />
            <SnackbarUtilsConfigurator />
            <Box style={{ height: '100vh', backgroundColor: '#eeeeee' }}>
                <ThemeProvider theme={theme}>
                    <>
                        <Spinner />
                        <MainPage />
                    </>
                </ThemeProvider>
            </Box>
        </SnackbarProvider>
    );
}

export default App;
