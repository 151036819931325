import { useState } from 'react';
import PropTypes from 'prop-types';
import OfferSelectionContext from './OfferSelectionContext';
import {
    getOffersService,
    selectCreditService,
    verifyBiometryRequirements
} from '../../Services/OfferService';
import {
    getItemService,
    removeItemService,
    setItemService
} from '../../Services/LocalStorageService';
import { LoanRequestConstantsLS } from '../LoanRequest/LoanRequestActions';
import {
    confirmLoanService,
    creditDetailsService,
    getDetailsOfTyC
} from '../../Services/LoanRequestService';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { cleanSteps } from '../../Services/BiometricDataService';
import { getDataOfRequestLoan } from '../../Services/LoanRequestService';
import { getProvinceById } from '../../Services/OfferService';
import { siisaValidationService } from '../../Services/LoanRequestService';

export const OfferSelectionState = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const offersInitial = {
        deadlines: [
            {
                quantityRate: 0,
                amountRate: 0,
                expirationDate: '2000-01-01T00:00:00'
            }
        ]
    };
    const [loanData, setLoanData] = useState(
        getItemService(LoanRequestConstantsLS.LoanData)
            ? JSON.parse(getItemService(LoanRequestConstantsLS.LoanData))
            : null
    );
    const personToGetLoan = JSON.parse(getItemService(LoanRequestConstantsLS.PersonToGetLoan));
    const [offers, setOffers] = useState(offersInitial);
    const [details, setDetails] = useState({
        amount: '000,00',
        quantityRate: 1,
        amountRate: '0',
        cbu: '...',
        deadline: {
            expirationDate: '2020-12-07T00:00:00'
        },
        rates: {
            tea: '00,00',
            tna: '00,00',
            cftna: '00,00'
        }
    });
    const paymentMethod = getItemService(LoanRequestConstantsLS.PaymentMethod)
        ? JSON.parse(getItemService(LoanRequestConstantsLS.PaymentMethod))
        : null;
    const [returnConfigPage, setReturnConfigPage] = useState(
        getItemService(LoanRequestConstantsLS.ReturnConfigPage)
    );
    const [dataOfLoanRequest, setdataOfLoanRequest] = useState([]);
    const [dataOfTyC, setdataOfTyC] = useState([]);

    const getOffers = async (amount = loanData.offer[0]?.maximumAmount || 1000) => {
        const response = await getOffersService(loanData.pendingRequestId, amount);
        setOffers(response.data);
        if (loanData.recommendation) {
            const newLoanData = { ...loanData, recommendation: false };
            setLoanData(newLoanData);
            setItemService(LoanRequestConstantsLS.LoanData, JSON.stringify(newLoanData));
        }

        return response;
    };

    const selectCredit = async (values) => {
        const response = await selectCreditService(loanData.pendingRequestId, values);

        return response;
    };

    const creditDetails = async () => {
        try {
            const response = await creditDetailsService(loanData.pendingRequestId);
            setDetails(response);

            return response;
        } catch {
            navigate(RoutesConstants.PUBLIC_HOME);
        }
    };

    const confirmLoan = async () => {
        const clientValidation =
            getItemService(LoanRequestConstantsLS.ClientValidation) == 'true' ? true : false;
        const allowedMethods = getItemService(LoanRequestConstantsLS.AllowedMethods);

        try {
            const response = await confirmLoanService({
                pendingRequestId: loanData.pendingRequestId,
                debitPayment: paymentMethod.id != 1
            });

            cleanSteps();
            const creditDetailId = response.data.requestId;
            setItemService('creditDetailId', creditDetailId);
            setItemService('creditNumber', response.data.creditNumber);
            setItemService('debitPaymentFinal', response.data.debitPayment);

            await siisaLastValidation(creditDetailId);

            if (response.data.completed && clientValidation) {
                navigate(RoutesConstants.LOAN_LAST_SATISFACTORY_SCREEN);
                return;
            } else {
                navigate(RoutesConstants.LOAN_LAST_DIFFERENTIAL_SCREEN);
                return;
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('No se pudo confirmar la operacion');
        }
    };

    const goToNextPage = async () => {
        const biometryStatus = await verifyBiometryRequirements(loanData.pendingRequestId);
        cleanSteps();
        if (biometryStatus.hasBiometricData) {
            nextRoute();
        } else {
            navigate(RoutesConstants.BIOMETRIC_DATA_PAGE);
        }
    };

    const nextRoute = () => {
        removeItemService(LoanRequestConstantsLS.ReturnConfigPage);
        setReturnConfigPage(false);
        if (returnConfigPage == 'lastStep') {
            navigate(RoutesConstants.LOAN_DETAILS_PAGE);
            return;
        } else {
            navigate(RoutesConstants.LOAN_SUMMARY_PAGE);
        }
        return;
    };

    const returnToConfig = (position) => {
        setItemService(LoanRequestConstantsLS.ReturnConfigPage, position);
        setReturnConfigPage(position);
        navigate(RoutesConstants.LOAN_OFFER_SELECTION);
    };

    const returnToCBU = () => {
        setItemService(LoanRequestConstantsLS.ReturnCbuPage, true);
        navigate(RoutesConstants.LOAN_CBU);
    };

    const getDataRequestLoan = async () => {
        const response = await getDataOfRequestLoan(
            JSON.parse(getItemService(LoanRequestConstantsLS.LoanData)).pendingRequestId
        );
        const dataTyC = await getDetailsOfTyC();
        let province = await getProvinceById(
            JSON.parse(getItemService('PersonToGetLoan')).provinceId
        );
        setdataOfLoanRequest(response);
        setdataOfTyC(dataTyC);
        setItemService('province', province);
    };

    const siisaLastValidation = async (creditDetailId) => {
        const id = getItemService('creditDetailId');
        const creditNumber = getItemService('creditNumber');
        const code = getItemService(LoanRequestConstantsLS.SiisaCode);
        const data = JSON.parse(getItemService(LoanRequestConstantsLS.PersonToGetLoan));
        const loanData = JSON.parse(getItemService(LoanRequestConstantsLS.LoanData));

        if (creditDetailId) {
            const response = await siisaValidationService({
                id: creditDetailId ? Number(creditDetailId) : Number(id),
                document: Number(data.dni),
                fingerPrint: code
            });
            return response;
        }

        const response = await siisaValidationService({
            id: Number(loanData.pendingRequestId) || 0,
            document: Number(data.dni),
            fingerPrint: code,
            creditNumber: creditNumber
        });

        return response;
    };

    return (
        <OfferSelectionContext.Provider
            value={{
                offersAPI: offers.deadlines,
                amountRequest: loanData.offer[0]?.maximumAmount
                    ? loanData.offer[0]?.maximumAmount
                    : 1000,
                isRecommendation: loanData?.recommendation,
                personName: personToGetLoan.name,
                skipToOfferSelection: personToGetLoan?.skipToOfferSelection,
                paymentMethod,
                details,
                dataOfLoanRequest,
                dataOfTyC,
                goToNextPage,
                returnToCBU,
                confirmLoan,
                getOffers,
                selectCredit,
                creditDetails,
                returnToConfig,
                getDataRequestLoan,
                siisaLastValidation
            }}>
            {children}
        </OfferSelectionContext.Provider>
    );
};

OfferSelectionContext.propTypes = {
    children: PropTypes.element
};
