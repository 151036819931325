import { useContext, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import ApprovedLoanContext from '../../../Contexts/ApprovedLoan/ApprovedLoanContext';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { Img } from '../../../Components/ImageContainer';
import DownloadIcon from '../../../Assets/Icons/DownloadIcon';
import LinkCustom from './LinkButton';
import RegistrationAndLoginButtons from '../DifferentialFeedback/RegistrationAndLoginButtons';
import { firstName } from '../../../Helpers/Masks';
import AppContext from '../../../Contexts/Global/AppContext';
import useFileDownload from '../../../Hooks/useFileDownload';
import { FilesToDownloadConstant } from '../../../Constants/FilesToDownloadConstant';
import { getItemService } from '../../../Services/LocalStorageService';
import { LoanRequestConstantsLS } from '../../../Contexts/LoanRequest/LoanRequestActions';

export const Approved = () => {
    const { personName } = useContext(ApprovedLoanContext);
    const { authentication } = useContext(AppContext);
    const { downloadFile } = useFileDownload();
    /* const [showCoupon, setShowCoupon] = useState(false); */

    const paymentMethod = getItemService(LoanRequestConstantsLS.PaymentMethod)
        ? JSON.parse(getItemService(LoanRequestConstantsLS.PaymentMethod))
        : null;

    const title = `¡Felicitaciones ${firstName(personName)}!`;
    const description = 'Ya tenés el dinero disponible en tu cuenta bancaria.';

    /* useEffect(() => {
        const debitPaymentFinal = getItemService('debitPaymentFinal');
        if (debitPaymentFinal) {
            if (debitPaymentFinal === 'true' || debitPaymentFinal === true) {
                setShowCoupon(false);
            } else {
                setShowCoupon(true);
            }
        }
    }, []); */

    return (
        <Grid container justifyContent="flex-start">
            <DescriptionForm
                title={title}
                description={description}
                fontFamilyDescription="Public Sans"
            />

            <LinkCustom
                disabled={paymentMethod && paymentMethod.id != 1}
                icon={<DownloadIcon />}
                text="Descargá tu cuponera de pago"
                action={() =>
                    downloadFile(FilesToDownloadConstant.PaymentCoupon, 'Cuponera de Pago.pdf')
                }
            />
            <LinkCustom
                icon={<DownloadIcon />}
                text="Descargá tu solicitud de préstamo"
                action={() =>
                    downloadFile(
                        FilesToDownloadConstant.LoanApplication,
                        'Solicitud de préstamo.pdf'
                    )
                }
            />

            <Box my={!authentication ? 0 : '20%'}>
                <Img src={'okeyImage.svg'} />
            </Box>

            {!authentication ? <RegistrationAndLoginButtons /> : null}

            <BackHomeButton />
        </Grid>
    );
};
