import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { RoutesConstants } from '../../Constants/RoutesConstants';

export const ReturnButtonRounded = () => {
    const navigate = useNavigate(); 

    return (
        <Button
            sx={{ borderRadius: '30px' }}
            variant="outlined"
            onClick={() => navigate(RoutesConstants.PUBLIC_HOME, { replace: true })}>
            <ArrowBackIcon />
            Volver al inicio
        </Button>
    );
};
