import React,
 { useContext, useEffect} 
 from 'react'
import {useGenericForm} from '../../Hooks/useGenericForm'
import contactConfig from '../../Configs/contactConfig.json'
import { Box, Paper, useMediaQuery, useTheme } from '@mui/material'
import { GenericForm } from '../../Components/Form/GenericForm'
import HomeContext from '../../Contexts/Home/HomeContext'
import { GetValidateFunction } from '../../Helpers/GetValidateFunction'
import GrayButton from '../../Components/LoginBtns/GrayButton'
import { maskDNI } from '../../Helpers/Masks'
import { getItemService } from '../../Services/LocalStorageService'

export const ContactForm = () => {

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const token = getItemService('Token');

    const { form, config, validForm, handleValue, initForm, validateForm, setEmptyForm, setEmptyMessageField} = useGenericForm(contactConfig.formControls)
    const { saveContactForm, data} = useContext(HomeContext)
    
    const getName = (completeName) =>{
      let name = completeName.split(',');
      return name[1];
    }

    const initValues = {
      name: `${data && data.userName ? getName(data.userName) : ''}`,
      DNI: `${data && data.dni ? maskDNI(data.dni) : ''}`,
      email: `${data && data.email ? data.email : ''}`,
    };


    useEffect(() => {
      initForm(initValues);
      validateForm(initValues);
    }, [data]);

    return (
      <Paper sx={{borderRadius: '32px', zIndex:'-1', py:(desktop ? 3 : 0)}} elevation={0}>
          <Box sx={{ mr: 5, mt: 2, ml: 5, mb: 2, pt: 2, pb: 2}}>
              <GenericForm formControls={config} form={form} validation = {GetValidateFunction} handleValue = {handleValue}/>
              <GrayButton
                text={'Enviar'}
                action={ () => {
                  saveContactForm(form, token ? setEmptyMessageField : setEmptyForm)
                }}
                disabled={!validForm}
              />
          </Box>
      </Paper>
    )
}
