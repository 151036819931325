import React, { useEffect, useState } from 'react';
import { getFrequentQuestions } from '../../Services/FrequentQuestionsService';
import FrequentQuestionsContext from './FrequentQuestionsContext';
import { useLocation } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { setItemService, getItemService } from '../../Services/LocalStorageService';

export const FrequentQuestionsState = ({ children }) => {
    const location = useLocation();
    const [frequentQuestions, setFrequentQuestions] = useState(null);

    useEffect(() => {
        const frequentQuestions = JSON.parse(getItemService('FrequentQuestionsLarge'));
        if (frequentQuestions) {
            setFrequentQuestions(frequentQuestions);
            return;
        }
        if (location.pathname === RoutesConstants.FRECUENT_QUESTIONS_PAGE) {
            getInitData();
        }
    }, [location.pathname]);

    const getInitData = async () => {
        let data = await getFrequentQuestions();
        setFrequentQuestions(data);
        setItemService('FrequentQuestionsLarge', JSON.stringify(data));
    };

    return (
        <FrequentQuestionsContext.Provider
            value={{
                frequentQuestions: frequentQuestions
            }}>
            {children}
        </FrequentQuestionsContext.Provider>
    );
};
