import React, { useRef } from 'react';
import { Grid } from '@mui/material';
import { HowItWorksSection } from './HowItWorksSection';
import { DoubtsSection } from './DoubtsSection';
import { LoanSection } from './LoanSection';
import { ContactPage } from '../Contact/ContactPage';
import { HomeState } from '../../Contexts/Home/HomeState';
import { MobileCheck } from '../../Helpers/MobileCheck';
import { useEffect } from 'react';
import { getItemService, setItemService } from '../../Services/LocalStorageService';
import { Box } from '@mui/system';
import { useTimer } from '../../Hooks/useTimer';
import { LoanRequestConstantsLS } from '../../Contexts/LoanRequest/LoanRequestActions';
import { trackPromise } from 'react-promise-tracker';

export const PublicHome = () => {
    let text;
    const boxRef = useRef();
    const { timerAction } = useTimer(2);
    useEffect(() => {
        if (boxRef.current) {
            const getSiisaCode = async () => {
                await trackPromise(
                    new Promise((resolve, reject) => {
                        timerAction(() => {
                            // eslint-disable-next-line no-undef
                            fillSiisaData('jsdata');

                            timerAction(() => {
                                const code = boxRef.current?.firstChild?.value;
                                if (code) {
                                    setItemService(LoanRequestConstantsLS.SiisaCode, code);
                                    resolve();
                                } else {
                                    reject();
                                }
                            });
                        });
                    })
                );
            };

            if (getItemService(LoanRequestConstantsLS.SiisaCode) == null) {
                getSiisaCode();
            }
        }
    }, [text, boxRef]);

    return (
        <Grid container className="bg-aqua">
            <HomeState>
                <Box ref={boxRef}>
                    <textarea id="jsdata" style={{ display: 'none' }} value={text}></textarea>
                </Box>

                <Grid container>
                    <Grid item sm={2}></Grid>
                    <Grid item xs={11} sm={8} sx={{ ml: MobileCheck() ? 2 : 0 }}>
                        <LoanSection />
                    </Grid>
                    <Grid item sm={2}></Grid>
                </Grid>
                <Grid container sx={{ backgroundColor: 'white' }}>
                    <Grid item sm={2}></Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{ ml: MobileCheck() ? 4 : 0, mr: MobileCheck() ? 3 : 0 }}>
                        <HowItWorksSection />
                    </Grid>
                    <Grid item sm={2}></Grid>
                </Grid>
                <Grid
                    container
                    sx={{ backgroundColor: 'white', borderRadius: '0px 0px 40px 40px' }}
                    mb={2}>
                    <Grid item xs={1} sm={2}></Grid>
                    <Grid item xs={10} sm={9}>
                        <DoubtsSection />
                    </Grid>
                    <Grid item xs={1} sm={2}></Grid>
                </Grid>
                <Grid container sx={{ backgroundColor: '#F5FAFF' }}>
                    <Grid item sm={2}></Grid>
                    <Grid item xs={12} sm={8}>
                        <ContactPage />
                    </Grid>
                    <Grid item sm={2}></Grid>
                </Grid>
            </HomeState>
        </Grid>
    );
};
