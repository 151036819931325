import { Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

const SubTitlePrivateHeader = ({ children }) => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <Typography py={1} fontSize={desktop ? '24px' : '16px' } className="font-blue">
            {children}
        </Typography>
    );
};

export default SubTitlePrivateHeader;
