export const isEmpty = (value) => {
    if(value!=undefined&&value!=null){
        const splitValues = String(value).split('');
        return splitValues.length<1;
    }

    return true;
};

export const allValuesComplete = (values) => {
    const valuesObject = Object.values(values);
    const emptyValues = valuesObject.filter((v) => isEmpty(v));
    const valuesComplete = isEmpty(emptyValues);

    return valuesComplete;
};

export const formatURL = (url) => {
    const domain = url.slice(7);
    const newURL = `https://${domain}`;

    return newURL;
}
