import { Grid, styled, Typography } from '@mui/material';
import React from 'react';
import { ColorConstants } from '../../../Constants/ColorConstants';
import DownloadableOption from './DownloadableOption';

const Container = styled(Grid)(({ border }) => ({
    borderRadius: '32px',
    border: border ? '1px solid' : '',
    borderColor: ColorConstants.BLUE,
    padding: 24,
    width: '100%',
    margin: '0% 8% 8% 8%'
}));

const Title = styled(Typography)(() => ({
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '28px',
    letterSpacing: '-0.02em',
    color: ColorConstants.BLUE
}));

const DividingLine = styled('hr')(() => ({
    color: ColorConstants.BLUE,
    borderTop: '1px solid',
    marginBottom: '12px'
}));

const OptionDividingLine = styled('hr')(() => ({
    color: ColorConstants.MIDDLE_LIGHT_BLUE,
    borderTop: '1px solid',
    margin: '10px 0px 10px 0px'
}));

const generateOption = (option, text) => {
    return (
        <>
            <DownloadableOption option={option} text={text} />
            <OptionDividingLine />
        </>
    );
};

const DownloadableFiles = ({
    optionsList,
    border = true,
    text = true,
    finished = false,
    hasDebt = false
}) => {
    return (
        <Container border={border}>
            <Title>Descargables</Title>
            <DividingLine />

            {optionsList.map((option) => {
                if (!hasDebt && option.optionNumber === 6) {
                    return;
                }
                if (option.onlyNotFinished && finished) {
                    return;
                } else {
                    return generateOption(option, text);
                }
            })}
        </Container>
    );
};

export default DownloadableFiles;
