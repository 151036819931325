import { Box } from '@mui/material';
import { DescriptionForm } from '../../Components/DescriptionsLayout';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import FormChangePassword from './FormChangePassword';

export const ChangePasswordPrivate = () => {
    const titleForm = 'Cambiar contraseña';

    return (
        <Box style={{ textAlign: 'center' }}>
            <ReturnButton route={RoutesConstants.UPDATE_USER_DATA} />
            <DescriptionForm title={titleForm} />
            <FormChangePassword />
        </Box>
    );
};
