import { getData } from "./BaseService"

export const getTermsConditionsService = async () => {
    const url = `general/information/${1}`
    const response = await getData(url);

    return response.body;
}

export const getPolicyPrivacyService = async () => {
    const url = `general/information/${2}`;
    const response = await getData(url);

    return response.body;
}