import React, { useState, useEffect } from 'react';
import AppContext from '../../Contexts/Global/AppContext';
import { PrivateHeader } from './PrivateHeader';
import { Header } from './Header';
import { useLocation } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { HeaderState } from '../../Contexts/Header/HeaderState';
import { useTheme, useMediaQuery } from '@mui/material';

export const HeaderWrapper = () => {
    const { authentication } = React.useContext(AppContext);
    const [header, setHeader] = useState(null);
    const routerLocation = useLocation();
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        if (routerLocation.pathname === RoutesConstants.CARD_CRED_IFRAME) {
            setHeader(null);
            return;
        }
        setHeader(renderHeader(routerLocation.pathname));
    }, [routerLocation.pathname]);

    const renderHeader = (pathname) => {
        switch (pathname) {
            case RoutesConstants.PUBLIC_HOME:
                return <Header />;
            case RoutesConstants.PRIVATE_HOME:
                return <PrivateHeader />;
            case RoutesConstants.CONTACT_PAGE:
                return authentication ? <PrivateHeader /> : <Header />;
            case RoutesConstants.FRECUENT_QUESTIONS_PAGE:
                return authentication ? <PrivateHeader /> : <Header />;
            case RoutesConstants.PRIVACY_PAGE:
                return authentication ? <PrivateHeader /> : <Header />;
            case RoutesConstants.TERMS_PAGE:
                return authentication ? <PrivateHeader /> : <Header />;
            case RoutesConstants.LOAN_ACTIVE_PAGE:
                return <PrivateHeader />;
            case RoutesConstants.UPDATE_USER_DATA:
                return !desktop ? <PrivateHeader /> : '';
            case RoutesConstants.PROFILE_CHANGE_PASSWORD:
                return !desktop ? <PrivateHeader /> : '';
            case RoutesConstants.NEW_PASSWORD_SUCCESSFUL_PRIVATE_PAGE:
                return !desktop ? <PrivateHeader /> : '';
            default:
                return;
        }
    };

    return <HeaderState>{header}</HeaderState>;
};
