import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { BounceLoader } from 'react-spinners';
import { useLocation } from 'react-router-dom';
import GoogleAnalytics from '../Components/Layout/GoogleAnalytics(Universal)';
import ReactGA from 'react-ga';

export const CardCredRedirect = () => {
    const location = useLocation();
    const [counter, setCounter] = useState(10);

    useEffect(() => {
        // eslint-disable-next-line no-undef
        const { REACT_APP_TRACKING_ID } = process.env;
        ReactGA.initialize(REACT_APP_TRACKING_ID);
    }, []);

    useEffect(() => {
        if (counter === 0) {
            const btn = window.parent.document.getElementById('continueButton');
            if (btn) {
                btn.click();
            }
            return;
        }
        const interval = setInterval(() => {
            setCounter((counter) => counter - 1);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [counter]);

    useEffect(() => {
        const script = document.body.getElementsByClassName('s1-script');
        if (script.length > 0) {
            script[0]?.remove();
        }
        const chat = document.body.getElementsByClassName('s1-channel-active');
        if (chat.length > 0) {
            chat[0]?.remove();
        }
    }, []);

    useEffect(() => {
        const script = document.body.getElementsByClassName('s1-script');
        if (script.length > 0) {
            script[0]?.remove();
        }
        const chat = document.body.getElementsByClassName('s1-channel-active');
        if (chat.length > 0) {
            chat[0]?.remove();
        }
    }, [location.pathname]);

    return (
        <Box display={'flex'} width="100%" justifyContent={'center'} alignItems={'center'}>
            <GoogleAnalytics />
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                width="315px"
                height={'315px'}>
                <CheckCircleIcon sx={{ fontSize: 100, color: 'green' }} />
                <Typography textAlign={'center'} variant={'body1'} fontWeight={'600'}>
                    ¡Tu tarjeta se ha registrado con éxito!
                </Typography>
                {/*  <Typography
                    textAlign={'center'}
                    variant={'body1'}
                    fontWeight={'600'}
                    sx={{ mb: 2 }}>
                    Aguarda unos instantes mientras te redirigimos a la siguiente página.
                </Typography> */}
                <Typography
                    textAlign={'center'}
                    variant={'body1'}
                    fontWeight={'600'}
                    sx={{ mb: 2 }}>
                    {`Aguarda unos instantes mientras te redirigimos a la siguiente página en `}
                </Typography>
                <Typography
                    textAlign={'center'}
                    variant={'h4'}
                    fontWeight={'600'}
                    color={'#000000ab'}>
                    {counter}
                </Typography>
                <BounceLoader size={60} color="#0069d7" />
            </Box>
        </Box>
    );
};
