/* eslint-disable no-console */
// /* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PaymentMethodContext from './PaymentMethodContext';
import { useNavigate } from 'react-router-dom';
import {
    getAllowedMethodsService,
    getPaymentMethodsService,
    getUrlTokenService,
    savePaymentMethod
} from '../../Services/PaymentMethodService';
import { getItemService, setItemService } from '../../Services/LocalStorageService';
import { LoanRequestConstantsLS } from '../LoanRequest/LoanRequestActions';
import { RoutesConstants } from '../../Constants/RoutesConstants';

export const PaymentMethodState = ({ children }) => {
    const personData = JSON.parse(getItemService(LoanRequestConstantsLS.PersonToGetLoan));
    const pendingRequestId = getItemService(LoanRequestConstantsLS.LoanData)
        ? JSON.parse(getItemService(LoanRequestConstantsLS.LoanData)).pendingRequestId
        : '';
    const navigate = useNavigate();
    const [paymentMethods, setPaymentMethods] = useState();
    const [allowedMethod, setAllowedMethod] = useState();
    const [urlToken, setUrlToken] = useState(
        getItemService(LoanRequestConstantsLS.TokenUrl)
            ? JSON.parse(getItemService(LoanRequestConstantsLS.TokenUrl))
            : null
    );
    const [methodSelected, setMethodSelected] = useState();

    const getPaymentMethods = async () => {
        try {
            const methods = await getPaymentMethodsService();
            const allowedMethods = await getAllowedMethodsService({
                dni: personData.dni,
                sexTypeId: personData.sexTypeId
            });
            setPaymentMethods(methods);
            setItemService(LoanRequestConstantsLS.AllowedMethods, allowedMethods.paymentId);
            setAllowedMethod(allowedMethods.paymentId);
        } catch (error) {
            console.log(error);
        }
    };

    const setPayMethod = async (method) => {
        setItemService(LoanRequestConstantsLS.PaymentMethod, JSON.stringify(method));
        console.log(method);

        if (method?.id !== 1 && method?.id !== 2) {
            await setPaymentMethod(true);
            navigate(RoutesConstants.LOAN_DETAILS_PAGE);
            return;
        }
        if (method?.id === 1) {
            await setPaymentMethod(false);
            navigate(RoutesConstants.LOAN_DETAILS_PAGE);
            return;
        }
        if (method?.id === 2) {
            try {
                const response = await getUrlTokenService({
                    personId: personData.personId,
                    phoneCode: personData.phoneCode,
                    phoneNumber: personData.phoneNumber,
                    cuit: personData.cuit
                });

                setUrlToken(response.data.request[0]);
                setItemService(
                    LoanRequestConstantsLS.TokenUrl,
                    JSON.stringify(response.data.request[0])
                );

                await setPaymentMethod(true);
                navigate(RoutesConstants.LOAN_CARD_LOAD);
                return;
            } catch {
                setMethodSelected();
                return;
            }
        }

        navigate(RoutesConstants.LOAN_DETAILS_PAGE);
        return;
    };

    const setPaymentMethod = async (debitMethod) => {
        await savePaymentMethod({
            personId: personData.personId,
            pendingRequestId,
            debitPayment: debitMethod
        });
    };

    return (
        <PaymentMethodContext.Provider
            value={{
                paymentMethods,
                urlToken,
                allowedMethod,
                methodSelected,
                setMethodSelected,
                getPaymentMethods,
                setPayMethod
            }}>
            {children}
        </PaymentMethodContext.Provider>
    );
};

PaymentMethodContext.propTypes = {
    children: PropTypes.element
};
