import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import TermsConditionsContext from '../../Contexts/TermsConditions/TermsConditionsContext';

export const TermsConditionsBody = ({ policy }) => {
    const { getTermsConditions, getPolicyPrivacy } = useContext(TermsConditionsContext);
    const [htmlComponent, setHtmlComponent] = useState();
    const error = false;

    const responseError = `Ocurrió un error. No se pudieron obtener los datos`;

    useEffect(() => {
        const getHtml = async () => {
            let htmlTermsConditions;

            if (policy) {
                htmlTermsConditions = await getPolicyPrivacy();
            } else {
                htmlTermsConditions = await getTermsConditions();
            }

            const htmlPurify = DOMPurify.sanitize(htmlTermsConditions);

            setHtmlComponent(parse(htmlPurify));
        };

        getHtml();
    }, []);

    // const handleError = () => {
    //     if (htmlComponent == undefined) {
    //         setError();
    //     }
    // }

    return (
        <Box height={error ? '400px' : '100%'}>
            <Typography sx={{ height: '100%' }} color="primary" textAlign={'left'}>
                {!error ? htmlComponent : responseError}
            </Typography>
        </Box>
    );
};
