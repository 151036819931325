import fileDownload from 'js-file-download';
import { AppConstantsLS } from '../Contexts/Global/AppActions';
import { downloadService } from '../Services/ApprovedLoanService';
import { getItemService } from '../Services/LocalStorageService';

const useFileDownload = () => {
    const requestId = getItemService('creditDetailId');
    const person = getItemService(AppConstantsLS.Person)
        ? JSON.parse(getItemService(AppConstantsLS.Person))
        : null;

    const downloadFile = async (printType, fileTitle) => {
        const response = await downloadService({
            personId: person.personId,
            requestId,
            printType
        });

        const file = fileDownload(response.data, `${fileTitle}.pdf`);

        return file;
    };

    return { downloadFile };
};

export default useFileDownload;
