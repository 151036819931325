import React from 'react';
import { Grid } from '@mui/material';
import { AppRoutes } from '../Routes/AppRoutes';
import '../Styles/GlobalStyle.css'

export const MainPage = () => {
    //reutilizar este componente para agregar el router

    return (
        <Grid container>
            <Grid
                container
                item
                xs={12}
                justifyContent="center"
                className='bg-aqua'
                style={{ height: '100%', width: '100%', position: 'relative', minHeight: '100vh'}}
                >
                <AppRoutes />
            </Grid>
        </Grid>
    );
};
