import React, { useRef } from 'react';
import { Camera } from 'react-camera-pro';
import styled from 'styled-components';
import Fab from '@mui/material/Fab';
import { Grid, Typography, Box } from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import FaceMarco from '../../Assets/Images/RostroMacroVertical.png';
import { BiometricDataState } from '../../Contexts/BiometricData/BiometricDataState';
import BiometricDataContext from '../../Contexts/BiometricData/BiometricDataContext';
import { StepsConstants } from '../../Constants/StepsConstants';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import CloseIcon from '@mui/icons-material/Close';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import {
    removeItemService,
    setItemService,
    getItemService
} from '../../Services/LocalStorageService';

const Wrapper = styled.div`
    position: absolute;
    top: ${window.screen.height * 0.008}em;
    width: ${window.screen.width * 0.05}em;
    height: ${window.screen.height * 0.03}em;
    left: ${window.screen.height * 0.0036}em;
    z-index: 1;
`;

const styles = {
    img: {
        position: 'relative',
        height: `${window.screen.height * 0.9}px`,
        top: 0,
        width: `${window.screen.width}px`,
        zIndex: 2
    },
    div: {
        position: 'relative',
        top: '30vh'
    }
};

export const FaceCapture = () => {
    const camera = useRef(null);
    const { type } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const getSelfieType = () => {
        if (location.pathname.includes('neutra')) {
            return 'expresión neutra';
        }
        const selfieType = Number(getItemService('gestureType'));
        if (selfieType) {
            if (selfieType === 5) {
                return 'sonriendo';
            }
            if (selfieType === 4) {
                return 'guiño izquierdo';
            }
            if (selfieType === 6) {
                return 'boca abierta';
            }
        }
        return 'expresión neutra';
    };

    return (
        <DeviceOrientation lockOrientation={'portrait'} sx={{ textAling: 'center' }}>
            <Orientation orientation="portrait" alwaysRender={false}>
                <div>
                    <BiometricDataState>
                        <Grid sx={{ zIndex: 2 }}>
                            <img src={FaceMarco} style={styles.img} />
                        </Grid>
                        <Grid
                            sx={{
                                zIndex: 3,
                                textAlign: 'center',
                                position: 'absolute',
                                width: '65%',
                                left: '9vh',
                                top: 15
                            }}>
                            <Typography sx={{ color: 'white', fontSize: '1.25rem' }}>
                                Foto Selfie {getSelfieType()}
                            </Typography>
                            <Box sx={{ textAlign: 'center', mr: 5 }}>
                                <CloseIcon
                                    sx={{
                                        fontSize: '1.8rem',
                                        color: 'white',
                                        zIndex: 2,
                                        position: 'absolute',
                                        top: 2,
                                        right: -50
                                    }}
                                    onClick={() => {
                                        navigate(RoutesConstants.BIOMETRIC_DATA_PAGE);
                                        setItemService('isSameSelfie', true);
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Wrapper>
                            <Camera
                                ref={camera}
                                aspectRatio="cover"
                                errorMessages={{
                                    noCameraAccessible:
                                        'Ningún dispositivo de cámara accesible. Conecta tu cámara o prueba con otro navegador.',
                                    permissionDenied:
                                        'Permiso Denegado. Por favor actualice y dé permiso a la cámara.',
                                    switchCamera:
                                        'No es posible cambiar la cámara a una diferente porque solo hay un dispositivo de video accesible.',
                                    canvas: 'Canvas no soportado.'
                                }}
                                sx={{ width: '50%', height: '50%' }}
                            />
                        </Wrapper>
                        <BiometricDataContext.Consumer>
                            {(ctx) => {
                                return ctx.setImage ? (
                                    <Box sx={{ textAlign: 'center', mr: 5 }}>
                                        <Fab
                                            color="secondary"
                                            sx={{
                                                position: 'absolute',
                                                bottom: '17.5%',
                                                rigth: '50vh',
                                                backgroundColor: 'white'
                                            }}
                                            onClick={() => {
                                                ctx.setImage(
                                                    ctx.images[StepsConstants.THIRD_STEP]
                                                        .confirmImage
                                                        ? StepsConstants.FOURTH_STEP
                                                        : StepsConstants.THIRD_STEP,
                                                    camera.current.takePhoto()
                                                );
                                                removeItemService('isSameSelfie');
                                            }}></Fab>
                                    </Box>
                                ) : null;
                            }}
                        </BiometricDataContext.Consumer>
                        <Fab
                            size="small"
                            color="secondary"
                            sx={{
                                background: '#524d4d30 !important',
                                position: 'absolute',
                                bottom: '18%',
                                right: 30
                            }}
                            onClick={() => {
                                if (camera.current) {
                                    camera.current.switchCamera();
                                }
                            }}>
                            <LoopIcon sx={{ color: 'white' }} />
                        </Fab>
                    </BiometricDataState>
                </div>
            </Orientation>
            <Orientation orientation="landscape" alwaysRender={false}>
                <div style={styles.div}>
                    <p>Por favor, desactiva la rotación automática del dispositivo</p>
                </div>
            </Orientation>
        </DeviceOrientation>
    );
};
