/* eslint-disable no-undef */
import { Typography, Grid } from '@mui/material';
import QR_STAGE_CredDirecto from '../../Assets/qr-code_stage.svg';
import QR_APP_CredDirecto from '../../Assets/qr-code_app-directo.svg';
////
import QR_PROD_CredDirecto from '../../Assets/QR_PROD_CredDirecto.svg';


export const QRCode = () => {
    const setQrCodeImage = () => {
        /*  if (
            window.location.host.includes('localhost') ||
            window.location.host.includes('stage-directo')
        ) {
            return QR_STAGE_CredDirecto;
        } */
        if (window.location.host.includes('nuevo.directo')) {
            return QR_PROD_CredDirecto;
        }
        if (
            window.location.host.includes('stage-directo') ||
            window.location.host.includes('uat.creditodirecto')
        ) {
            return QR_STAGE_CredDirecto;
        }
        return QR_APP_CredDirecto;
    };

    return (
        <Grid>
            <Typography fontWeight={'bold'} color={'#004489'} mb={2}>
                Escaneá el código QR
            </Typography>
            {/* acá va el QR cuando se pase a prod */}
            <img src={setQrCodeImage()} style={{ width: '60%' }} />
        </Grid>
    );
};
