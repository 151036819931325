import { Box, Button, styled, Typography } from '@mui/material';
import React from 'react';

const ButtonCustom = styled(Button)(() => ({
    borderRadius: '30px',
    width: '100%',
    height: '32px',
    display: 'flex',
    justifyContent: 'start',
    ':hover': {
        background: 'transparent'
    },
}));

const LinkCustom = ({ text, action, disabled, icon }) => {
    return (
        <ButtonCustom onClick={() => action()} disabled={disabled} disableRipple>
            <Typography
                fontSize={16}
                fontWeight={500}
                fontFamily={'Archivo'}
                display="flex"
                color= {disabled ? "#80808094" :"secondary"}>
                <Box mt={0.4}> {icon ? icon : ''}</Box>
                <u>{text}</u>
            </Typography>
        </ButtonCustom>
    );
};

export default LinkCustom;
