export const GetValidateFunction = (validationKey) => {
    switch(validationKey){
        case "onlyText":
            return onlyTextValidation;
        case "text":
            return textValidation;
        case "number":
            return numberValidation;
        case "email":
           return emailValidation;
        case "dni":
           return dniValidation;
        default: 
            return none;
    }
}

const textValidation = (value, config) =>{
    let message = {isValid: true, errorMessage: ''};
    message = lengthValidation(value, config.maxLength, config.minLength);
    return message;
}

const none = () =>{ return {isValid: true, errorMessage: ''};}

const onlyTextValidation = (value, config) =>{
    let message = {isValid: true, errorMessage: ''}
    const regex = /^[A-Z][A-ZÀ-ÿ\s]+$/i;
    if (value && !regex.test(value)) return message = {isValid: false, errorMessage: `Formato incorrecto, solo debe contener carácteres alfabéticos.`};
    message = lengthValidation(value, config.maxLength, config.minLength);
    return message
}

const numberValidation = (value, config) =>{
    let message = {isValid: true, errorMessage: ''}
    const regex = /^[1-9][0-9]*$/;
    if (value && !regex.test(value)) return message = {isValid: false, errorMessage: `Formato incorrecto, solo debe contener números.`};
    message = lengthValidation(value, config.maxLength, config.minLength)
    return message;
}

const dniValidation = (value, config) =>{
    let message = {isValid: true, errorMessage: ''}
    const regex = /^[0]*[1-9][0-9.,]*$/;
    if (value && !regex.test(value)) return message = {isValid: false, errorMessage: `Formato incorrecto, solo debe contener números.`};
    message = lengthValidation(value, config.maxLength, config.minLength, config.field)
    return message;
}

const emailValidation = (value, config) =>{
    let message = {isValid: true, errorMessage: ''};
    if (value && !value.match('[aA-zZ0-9._%+-]+@[aA-zZ0-9.-]+[aA-zZ]{2,4}$')) return message = {isValid: false, errorMessage: 'Formato de email incorrecto.'};
    message = lengthValidation(value, config.maxLength, config.minLength);
    return message;
}

const lengthValidation = (value, maxlength, minlength, type) =>{
    if (value.length > 0 && value.length > maxlength) return {isValid: false, errorMessage: `Este campo debe tener como máximo ${type=== 'DNI' ? maxlength - 2 : maxlength} caracteres.`};
    else if (value.length > 0 && value.length < minlength) return {isValid: false, errorMessage: `Este campo debe tener como mínimo ${type=== 'DNI' ? minlength -2 : minlength} caracteres.`};
    else return {isValid: true, errorMessage: ''};
}