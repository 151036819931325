import React from 'react'
import { Grid,  useMediaQuery, useTheme} from '@mui/material'
import { TitleCustom } from '../../Components/TitleCustom'
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useNavigate } from 'react-router-dom'
import { ColorConstants } from '../../Constants/ColorConstants';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { MobileCheck } from '../../Helpers/MobileCheck';

const DoubtsSection = () => {

  const navigate = useNavigate();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"))
  const desktopSM  = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Grid container spacing={2} sx={{pb:2, pt:1.5}} className='text-aling-center bg-aqua' >
      <Grid item xs={1} sm={12} sx={{display:{xs:'block', sm:'none'}}}></Grid>
      <Grid item xs={10} sm={12}>
        <TitleCustom size={MobileCheck() ? '24px' : '32px !important'} title="¿Tenés dudas?" subtitle="¡No te preocupes! Estamos para ayudarte y responder todo lo que quieras saber."/>
      </Grid>
      <Grid item xs={1} sm={2} md={4} lg={4}></Grid>
      <Grid item xs={11} sm={8} md={4} lg={4} ml={desktop ? 7 : 2.2}>
        <ButtonCustom width='320px' border bg="transparent" 
        color={ColorConstants.BLUE} 
        icon = {<ArrowForwardOutlinedIcon/>} 
        padding='12px 0px 12px 32px'
        text="Ver más preguntas frecuentes" 
        action={() => navigate(RoutesConstants.FRECUENT_QUESTIONS_PAGE)}/>
      </Grid>
      <Grid item xs={5} sm={4}></Grid>
      <Grid item xs={12} mb={desktop ? 10 : 1} mt={desktop ? '' : 1} mr={desktopSM && 8}>
          <img src={desktop ? 'lineDesktop.svg' : 'lineMobile.svg'} style={{width: desktopSM && !desktop && '110%'}} />
      </Grid>
    </Grid>
  )
}

export default DoubtsSection