import React from 'react';
import { Grid } from '@mui/material';
import HomeLayout from '../../../Components/HomeLayouts';
import { Outlet } from 'react-router-dom';
import { PaymentMethodState } from '../../../Contexts/PaymentMethod/PaymentMethodState';

export const PaymentMethodGeneralPage = () => {
    return (
        <PaymentMethodState>
            <Grid container>
                <HomeLayout
                    Form={<Outlet />}
                />
            </Grid>
        </PaymentMethodState>
    );
};