import { useState } from "react"

export const useDialog = () => {
    const [showDialog, setShowDialog] = useState(false)
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');

    const OpenDialog = () => setShowDialog(true);
    const CloseDialog = () => setShowDialog(false);


    return { showDialog, content, title, OpenDialog, CloseDialog, setContent, setTitle }
}