import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReturnButtonRounded } from './LoginBtns/ReturnButtonRounded';

export const DescriptionForm = ({
    title,
    description,
    lineHeightDescription = '30px',
    fontSizeDescription = '16px',
    fontFamilyDescription = 'Archivo',
    sx
}) => {
    return (
        <Box pb={3} sx={{ textAlign: 'left', ...sx }}>
            <Typography
                variant="h5"
                fontSize={24}
                color="primary"
                fontWeight={700}
                letterSpacing="-0.02em"
                // lineHeight='30px'
                sx={{ mb: '8px' }}>
                {title}
            </Typography>
            <Typography
                color="primary"
                lineHeight={lineHeightDescription}
                fontFamily={fontFamilyDescription}
                letterSpacing={fontFamilyDescription ? '-0.02em' : ''}
                fontSize={fontSizeDescription}
                fontWeight="regular">
                {description}
            </Typography>
        </Box>
    );
};

export const Description = ({ title, description }) => (
    <Box py={3}>
        <ReturnButtonRounded />
        <Typography
            variant="h3"
            component="h4"
            color={'#004489'}
            sx={{ fontWeight: 'bold', mt: '54px' }}>
            {title}
        </Typography>
        <Typography
            variant="h6"
            component="h5"
            fontFamily={'Archivo'}
            color={'#004489'}
            sx={{ fontWeight: 'regular' }}>
            {description}
        </Typography>
    </Box>
);
