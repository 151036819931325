import { RoutesConstants as r } from '../Constants/RoutesConstants';

export const except_locations = [
    '/crear-cuenta',
    '/datos-biometricos',
    '/resumen-prestamo',
    '/metodo-pago',
    '/validacion-cbu',
    '/seleccion-oferta',
    '/informacion-contacto-prestamo',
    '/validacion-token-prestamo'
];

export const except_pages_chatBox = [
    //PRESTAMO
    r.LOAN_REQUEST_PAGE,
    r.LOAN_CONTACT_INFORMATION_PAGE,
    r.LOAN_TOKEN_PAGE,
    r.LOAN_REDIRECT_PAGE,
    r.LOAN_NO_OFFERS,
    r.LOAN_TYPE_OCUPPATION,
    r.LOAN_CALENDAR,
    r.LOAN_SUMMARY_PAGE,
    r.LOAN_DETAILS_PAGE,
    r.LOAN_OFFER_SELECTION,
    r.LOAN_CBU,
    r.LOAN_PAY_METHOD,
    r.LOAN_ACTIVE_PAGE,
    r.LOAN_LAST_SATISFACTORY_SCREEN,
    r.LOAN_SATISFACTORY_CARD_LOAD,
    r.LOAN_LAST_DIFFERENTIAL_SCREEN,
    r.LOAN_CARD_LOAD,
    //LOGIN
    r.LOGIN_PAGE,
    //REGISTRO
    r.REGISTER_PAGE,
    r.REGISTER_CREATE_ACCOUNT_PAGE,
    r.REGISTER_SUCCESSFUL_PAGE,
    r.REGISTER_CHECK_DNI_PAGE,
    r.REGISTER_CONTACT_INFORMATION_PAGE,
    r.REGISTER_SEND_EMAIL_PAGE,
    r.REGISTER_EXPIRED_LINK_PAGE,
    //DATOS BIOMETRICOS
    r.BIOMETRIC_DATA_PAGE,
    `${r.BIOMETRIC_DATA_PAGE}/Face-capture/neutra`,
    `${r.BIOMETRIC_DATA_PAGE}/Face-capture/sonriendo`,
    r.DNI_FRONT_PAGE,
    r.FACE_CAPTURE_PAGE,
    //IFRAME
    r.CARD_CRED_IFRAME,
    r.CARD_CRED_IFRAME_ERROR
];

export const timeout_routes = [
    //PRESTAMO
    r.LOAN_REQUEST_PAGE,
    r.LOAN_CONTACT_INFORMATION_PAGE,
    r.LOAN_TOKEN_PAGE,
    r.LOAN_REDIRECT_PAGE,
    r.LOAN_NO_OFFERS,
    r.LOAN_TYPE_OCUPPATION,
    r.LOAN_CALENDAR,
    r.LOAN_SUMMARY_PAGE,
    r.LOAN_DETAILS_PAGE,
    r.LOAN_OFFER_SELECTION,
    r.LOAN_CBU,
    r.LOAN_PAY_METHOD,
    r.LOAN_LAST_SATISFACTORY_SCREEN,
    r.LOAN_SATISFACTORY_CARD_LOAD,
    r.LOAN_LAST_DIFFERENTIAL_SCREEN,
    r.LOAN_CARD_LOAD,
    //DATOS BIOMETRICOS
    r.BIOMETRIC_DATA_PAGE,
    `${r.BIOMETRIC_DATA_PAGE}/Face-capture/neutra`,
    `${r.BIOMETRIC_DATA_PAGE}/Face-capture/sonriendo`,
    r.DNI_FRONT_PAGE,
    r.FACE_CAPTURE_PAGE
];
