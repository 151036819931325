/* eslint-disable no-undef */
/* eslint-disable no-console */
import { getData } from './BaseService';
import { trackPromise } from 'react-promise-tracker';
import { getItemService } from './LocalStorageService';
import { AppConstantsLS } from '../Contexts/Global/AppActions';

export const getCredits = async (personId) => {
    const url = `credit/byperson/${personId}`;
    const response = await getData(url);

    return response;
};

export const obtainRecommendations = async (personId) => {
    const url = `credit/recommended/${personId}`;
    const response = await getData(url);

    return response;
};

export const obtainRecommendationsFetch = async (personId) => {
    const token = getItemService(AppConstantsLS.Token);

    let config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    config.headers['Credentials'] = process.env.REACT_APP_CREDENTIAL;
    config.headers['ApiKey'] = process.env.REACT_APP_APIKEY_BACKEND;

    if (process.env.REACT_APP_SUBSCRIPTION_KEY) {
        config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_SUBSCRIPTION_KEY;
    }

    const url = `credit/recommended/${personId}`;

    const newPromise = new Promise((resolve, reject) => {
        resolve(fetch(`${process.env.REACT_APP_URL_BACKEND}${url}`, config));
    });

    const response = await trackPromise(newPromise, { area: 'recommendation' });

    const data = await response.json();

    return data;
};
