import { Box } from '@mui/material';
import { useState, useEffect, useRef, useContext } from 'react';
import Iframe from 'react-iframe';
import { useNavigate } from 'react-router-dom';
import BackHomeButton from '../../../../Components/Buttons/BackHomeButton';
import { DescriptionForm } from '../../../../Components/DescriptionsLayout';
import ErrorMessage from '../../../../Components/Form/ErrorMessage';
import GrayButton from '../../../../Components/LoginBtns/GrayButton';
import { ReturnButton } from '../../../../Components/LoginBtns/ReturnButton';
import { RoutesConstants } from '../../../../Constants/RoutesConstants';
import { LoanRequestConstantsLS } from '../../../../Contexts/LoanRequest/LoanRequestActions';
import { useCardMethod } from '../../../../Hooks/useCardMethod';
import { useTimer } from '../../../../Hooks/useTimer';
import {
    getItemService,
    removeItemService,
    setItemService
} from '../../../../Services/LocalStorageService';
import PaymentMethodContext from '../../../../Contexts/PaymentMethod/PaymentMethodContext';
import AppContext from '../../../../Contexts/Global/AppContext';

const CardLoadIframe = () => {
    const { navigateToLoanDetail, setNavigateToLoanDetail } = useContext(AppContext);
    const { urlToken } = useContext(PaymentMethodContext);
    const [noNewCards, setNoNewCards] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const { timerAction } = useTimer(8);
    const { getVerifyCards, getCards, cards } = useCardMethod();
    const cardsQuantity = getItemService(LoanRequestConstantsLS.CardsQuantity);
    const navigate = useNavigate();

    useEffect(() => {
        removeItemService('cardVerifyError');
        const script = document.body.getElementsByClassName('s1-script');
        if (script.length > 0) {
            script[0]?.remove();
        }
        const chat = document.body.getElementsByClassName('s1-channel-active');
        if (chat.length > 0) {
            chat[0]?.remove();
        }
    }, []);

    useEffect(() => {
        if (cards.length > 0) {
            setRedirect();
        }
    }, [cards]);

    const setRedirect = async () => {
        const number = parseInt(cardsQuantity);
        if (cards.length > number) {
            navigate(RoutesConstants.LOAN_DETAILS_PAGE);
            setNoNewCards(false);
        } else {
            setNoNewCards(true);
        }
    };

    const disableContinueButton = () => {
        setDisableButton(true);
        timerAction(() => {
            setDisableButton(false);
            setNoNewCards(false);
        });
    };

    const handleButton = async () => {
        const verifyCards = await getVerifyCards();
        if (!verifyCards.successfully) {
            setItemService('cardVerifyError', JSON.stringify(verifyCards));
            navigate(RoutesConstants.LOAN_PAY_METHOD);
            return;
        }
        getCards();
        disableContinueButton();
    };

    return (
        <Box alignContent={'center'} display="flex" flexWrap={'wrap'}>
            <ReturnButton route={RoutesConstants.LOAN_PAY_METHOD} />
            <DescriptionForm title="Completa los datos de tu tarjeta de débito" />
            {/*  <Iframe
                url={urlToken.url_debito}
                id="iframe_id"
                width="100%"
                height="550px"
                className="iframe"
                display="block"
                position="relative"
                sandbox={[
                    'allow-forms',
                    'allow-pointer-lock',
                    'allow-popups',
                    'allow-same-origin',
                    'allow-scripts',
                    'allow-top-navigation'
                ]}
            /> */}
            <iframe
                src={urlToken.url_debito}
                id="iframe_id"
                width={'100%'}
                height={'550px'}
                className="iframe"
                sandbox="allow-forms allow-modals allow-orientation-lock allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
            />
            {noNewCards ? <ErrorMessage title={'Primero debe cargar una nueva tarjeta '} /> : null}
            <Box display={'flex'} width="100%" justifyContent={'center'}>
                <Box width="315px">
                    <GrayButton
                        id="backButton"
                        text="Volver a métodos de pago"
                        height="46px"
                        action={() => navigate(RoutesConstants.LOAN_PAY_METHOD)}
                        sx={{ display: 'none' }}
                    />
                    <GrayButton
                        id="continueButton"
                        text="Continuar al último paso"
                        height="46px"
                        action={() => handleButton()}
                        sx={{ display: 'none' }}
                    />
                </Box>
            </Box>
            <BackHomeButton />
        </Box>
    );
};

export default CardLoadIframe;
