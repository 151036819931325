import {
    Box
} from '@mui/material';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import FormCreateAccount from './FormCreateAccount';
import { RoutesConstants } from '../../../Constants/RoutesConstants';

export const CreateAccountForm = () => {
    const titleForm = 'Crear mi cuenta';
    const descriptionForm =
        'Primero creá tu contraseña e ingresá tu mail. Te enviaremos un link para continuar con tu registro.';
    return (
        <Box style={{ textAlign: 'center' }}>
            <ReturnButton route={RoutesConstants.REGISTER_PAGE}/>
            <DescriptionForm title={titleForm} description={descriptionForm} />
            <FormCreateAccount />
        </Box>
    );
};
