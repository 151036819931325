const MethodOptions = [
    {
        id: 1,
        title: 'Con efectivo',
        description:
            'Contarás con la opción de descargar el cupón para pagarlo mes a mes en Pago Fácil, Rapipago, Mercado Pago, entre otros.',
        icon: 'MoneyIcon'
    },
    {
        id: 2,
        title: 'Con tarjeta débito',
        description:
            'De forma simple y segura, vamos a debitar mes a mes de tu cuenta. Para eso a continuación, necesitaremos pedirte los datos de tu tarjeta.'
    },
];

export default MethodOptions;