import {
    Box,
    FormControl,
    InputLabel,
    OutlinedInput,
    // TextField,
    InputAdornment
    // Typography,
} from '@mui/material';
import { FormHelperText } from '@mui/material';
import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import { maskDNI } from '../../Helpers/Masks';
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import moment from "moment";
import { useEffect, useState } from 'react';
import { ColorConstants } from '../../Constants/ColorConstants';

export const GenericFormInput = ({
    label,
    type = 'text',
    field,
    valueData = '',
    handle = {},
    list = [],
    required,
    disabled,
    icon,
    rows,
    validationFunction = {}
}) => {
    const [value, setValue] = useState(valueData);
    const [validation, setValidation] = useState({
        errorMessage: '',
        isValid: true
    });
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        setValue(valueData);
    }, [valueData]);

    const onChangeValue = (newValue) => {
        validate(newValue);
        setTouched(true);
        setValue(newValue);
        handle(newValue);
    };

    const validate = (value) => {
        let result;
        if (validationFunction) {
            result = validationFunction(value);
            setValidation(result);
        }
        if (required && value.length == 0)
            setValidation({
                errorMessage: 'Este campo es requerido.',
                isValid: false
            });
    };

    return (
        <Box sx={{ mt: 1, mb: 1, mr: 1, ml: 1 }}>
            {(type == 'text' || type == 'int') && (
                <FormControl fullWidth sx={{ my: 0.5, borderRadius: '40px' }}>
                    <InputLabel htmlFor="outlined-adornment-amount">{label}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        sx={{ borderRadius: '16px', color: ColorConstants.BLUE, minHeight: '48px' }}
                        autoComplete="off"
                        size="small"
                        value={field != 'DNI' ? value : maskDNI(value)}
                        disabled={disabled}
                        required={required}
                        multiline={rows != undefined}
                        rows={rows}
                        error={touched && !validation.isValid}
                        onKeyUp={(event) => onChangeValue(event.target.value)}
                        onChange={(event) => setValue(event.target.value)}
                        fullWidth
                        label={label}
                        startAdornment={<InputAdornment position="start">{icon}</InputAdornment>}
                        inputProps={{
                            inputMode: `${type == 'int' ? 'numeric' : ''}`,
                            // inputMode: 'numeric',
                            'aria-label': 'Account Id'
                        }}
                    />
                    {!validation.isValid && (
                        <FormHelperText error id="accountId-error">
                            {validation.errorMessage}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
            {/* {type == "date" && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <>
            <DesktopDatePicker
              fullWidth
              type='datetime-local'
              value={value ? value : moment().toDate()}
              inputFormat='dd/MM/yyyy'
              required={required}
              disabled={disabled}
              // onChange={newValue =>DataRes(newValue)}
              onChange={(newValue) => onChangeValue(newValue)}
              renderInput={(params) => (
                <TextField
                  size='small'
                  {...params}
                  sx={{ width: "100%", backgroundColor: "#F5F9FC" }}
                />
              )}
            />
          </>
        </LocalizationProvider>
      )} */}
            {type == 'select' && (
                <FormControl fullWidth size="small">
                    {!value && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}
                    <Select
                        sx={{ borderRadius: '16px', color: ColorConstants.BLUE, minHeight: '48px' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        required={required}
                        disabled={disabled}
                        error={touched && required && !value}
                        onChange={(event) => onChangeValue(event.target.value)}>
                        {list?.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                                {e.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {!validation.isValid && (
                        <FormHelperText error id="accountId-error">
                            {validation.errorMessage}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        </Box>
    );
};
