import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { ReturnButton } from './LoginBtns/ReturnButton';
import { RoutesConstants } from '../Constants/RoutesConstants';
import { useLocation } from 'react-router-dom';

const GridMain = styled(Grid)(() => ({
    display: 'flex',
    height: '100%',
    backgroundColor: '#ecf5ff',
    justifyContent: 'center'
}));

const GridDescription = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        margin: ' 20px 0px 20px 24px'
    },
    [theme.breakpoints.up('md')]: {
        margin: '40px 30px 0px 0px'
    },
    [theme.breakpoints.up('lg')]: {
        margin: '40px 5px 0px 0px',
        padding: '0px 95px 0px 0px'
    },
    borderRadius: '40px'
}));

const GridForm = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        padding: '29px 24px',
        borderRadius: '40px 40px 0px 0px'
    },
    [theme.breakpoints.up('md')]: {
        margin: '40px 0px 0px 30px',
        padding: '64px',
        borderRadius: '40px'
    },
    [theme.breakpoints.up('lg')]: {
        margin: '40px 0px 0px 5px',
        padding: '64px'
    },
    display: 'flex',
    justifyContent: 'center',
    // rounded: 'true',
    backgroundColor: 'white'
}));

const TermsConditionsLayout = ({ Form, ContainerPanel }) => {
    const theme = useTheme();
    const location = useLocation();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    const paddingBottomDesktop = desktop ? '50px' : '0';
    return (
        <GridMain container sx={{ paddingBottom: paddingBottomDesktop }} justifyContent="start">
            <Grid item sm={1}></Grid>
            <GridDescription item xs={12} md={4}>
                <ReturnButton
                    text={'Volver'}
                    medium
                    /* route={RoutesConstants.LOAN_CONTACT_INFORMATION_PAGE} */
                    route={() => history.back()}
                />

                <Box>{ContainerPanel}</Box>
            </GridDescription>

            <GridForm item xs={12} md={4}>
                {Form}
            </GridForm>
            <Grid item sm={1}></Grid>
        </GridMain>
    );
};

export default TermsConditionsLayout;
