export const LoanRequestDetailConstants = {
    operationDetails: 'operationDetails',
    creditRequest: 'creditRequest',
    requestTyC: 'requestTyC',
    operationDetails_amount:'amount',
    operationDetails_quantityRate: 'quantityRate',
    operationDetails_amountRate: 'amountRate',
    operationDetails_date: 'deadLines',
    creditRequest_applicantDetails_date: 'date',
    creditRequest_applicantDetails_name: 'name',
    creditRequest_applicantDetails_document: 'document',
    creditRequest_applicantDetails_sexType: 'sexType',
    creditRequest_applicantDetails_birthday: 'birthday',
    creditRequest_applicantDetails_taxId: 'taxId',
    creditRequest_applicantDetails_iva: 'iva',
    creditRequest_applicantDetails_province: 'province',
    creditRequest_applicantDetails_email: 'email',
    creditRequest_applicantDetails_phone: 'phone',
    creditRequest_creditConditions_amountCredit: 'amountCredit',  
    creditRequest_creditConditions_currency: 'currency', 
    creditRequest_creditConditions_quantityRateCredit: 'quantityRateCredit',    
    creditRequest_creditConditions_firstAmountRate: 'firstAmountRate',    
    creditRequest_creditConditions_amortizationSystem: 'amortizationSystem',   
    creditRequest_creditConditions_amountInWords: 'amountInWords',  
    creditRequest_creditConditions_netAmount: 'netAmount',    
    creditRequest_creditConditions_tna: 'tna',    
    creditRequest_creditConditions_tea: 'tea',    
    creditRequest_creditConditions_cftna: 'cftna',    
    creditRequest_creditConditions_tnaName: 'tnaName', 
    creditRequest_creditConditions_teaName: 'teaName',    
    creditRequest_creditConditions_cftnaName: 'cftnaName',    
    creditRequest_paymentMethod_description: 'description',    
    creditRequest_accreditationForm_cbu: 'cbu',    
    creditRequest_accreditationForm_bank: 'bank',    
    requestTyC_descriptionRequest: 'descriptionRequest', 
}
