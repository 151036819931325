import { Link } from 'react-router-dom';
import GrayButton from '../../../Components/LoginBtns/GrayButton';
import { Img } from '../../../Components/ImageContainer';
import { Box, Typography } from '@mui/material';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { useEffect, useState } from 'react';
import { RoutesConstants } from '../../../Constants/RoutesConstants';

const BodySendEmail = ({ resendEmail, action }) => {
    const [lapseTime, setLapseTime] = useState(true);

    const timerLapseTime = () => {
        setTimeout(() => {
            setLapseTime(false);
        }, 15000);
    };

    const onSubmit = async () => {
        setLapseTime(true);
        timerLapseTime();
        action();
    };

    useEffect(() => {
        timerLapseTime();
    }, []);

    return (
        <>
            {resendEmail && (
                <Box textAlign={'start'}>
                    <Link to={RoutesConstants.REGISTER_CREATE_ACCOUNT_PAGE}>Quiero cambiar el mail</Link>
                </Box>
            )}

            <Img src="email.svg" />

            <Typography
                variant="h7"
                component="h5"
                color={'#004489'}
                sx={{ fontWeight: 'regular', pt: '78px' }}>
                Si aún no lo recibiste, en 0:15 podrás pedir otro.
                <br />
                No te olvides de revisar tu bandeja de Spam.
            </Typography>

            <GrayButton
                text={'Quiero un link nuevo'}
                action={() => onSubmit()}
                disabled={lapseTime}
            />

            <BackHomeButton />
        </>
    );
};

export default BodySendEmail;