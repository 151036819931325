import styled from '@emotion/styled';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import DownloadIcon from '../../../Assets/Icons/DownloadIcon';
import { ColorConstants } from '../../../Constants/ColorConstants';

const OptionButton = styled(Button)(({ desktop, text}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    borderRadius: '30px',
    padding: desktop ? '0px 15px 0px 20px' : '',
    border: desktop && text ? '2px solid' : '',
}));

const DownloadButton = ({text = true}) => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <OptionButton desktop={desktop} text={text}>
            {desktop && text ? 'Descargar' : ''}
            <DownloadIcon fontSize={desktop ? 'medium' : 'large'} sx={{ color: ColorConstants.BLUE, mt: 1, ml: 1 }} />
        </OptionButton>
    );
};

export default DownloadButton;
