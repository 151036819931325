import {
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, { useContext } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import GrayButton from '../../Components/LoginBtns/GrayButton';
import {
    alphanumericTest,
    passwordValidate,
    threePasswordsValidate
} from '../../Helpers/Validations';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BackHomeButton from '../../Components/Buttons/BackHomeButton';
import MyProfileContext from '../../Contexts/MyProfile/MyProfileContext';
import ErrorLabel from '../../Components/Form/ErrorLabel';
import { ColorConstants } from '../../Constants/ColorConstants';
import ErrorModal from '../../Components/Modal/ErrorModal'

const FormChangePassword = () => {
    const { changePassword, notConnection,
        setNotConnection } = useContext(MyProfileContext);

    const [errorPassword, setErrorPassword] = useState(false);
    const [alphanumericError, setAlphanumericError] = useState(false);

    const handleCloseModal = () => {
        setNotConnection(false);
        setValues({
            currentPassword: '',
            password: '',
            passwordRepeat: '',
        })
    }

    const [values, setValues] = useState({
        currentPassword: '',
        password: '',
        passwordRepeat: '',
        showCurrentPassword: false,
        showPassword: false,
        showPasswordRepeat: false
    });

    const handleChange = (prop) => (event) => {
        errorPassword&&setErrorPassword(false);
        setValues({ ...values, [prop]: event.target.value.replace(/\s/g, '') });
    };

    const handleShowPassword = (prop) => {
        isWrongPassword();
        setValues({ ...values, [prop]: !values[prop] });
    };

    const isWrongPassword = () => {
        setErrorPassword(
            values.password != '' &&
                values.passwordRepeat != '' &&
                !passwordValidate(values.password, values.passwordRepeat)
        );
        setAlphanumericError(values.password != '' && !alphanumericTest(values.password));
    };

    const onSubmit = async () => {
        await changePassword(values);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'space-between',
                height: '430px'
            }}>
            <div>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <InputLabel htmlFor="currentPassword">Ingresá tu contraseña actual</InputLabel>
                    <OutlinedInput
                        id="currentPassword"
                        type={values.showCurrentPassword ? 'text' : 'password'}
                        value={values.currentPassword}
                        onChange={handleChange('currentPassword')}
                        sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                        color="info"
                        startAdornment={
                            <InputAdornment position="start">
                                <LockOutlinedIcon />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => handleShowPassword('showCurrentPassword')}
                                    edge="end">
                                    {values.showCurrentPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Ingresá tu contraseña actual"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <InputLabel htmlFor="password">Ingresá tu nueva contraseña</InputLabel>
                    <OutlinedInput
                        id="password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        onBlur={() => isWrongPassword()}
                        sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                        color="info"
                        error={errorPassword || alphanumericError}
                        startAdornment={
                            <InputAdornment position="start">
                                <LockOutlinedIcon />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => handleShowPassword('showPassword')}
                                    edge="end">
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Ingresá tu nueva contraseña"
                    />
                    {alphanumericError && (
                        <ErrorLabel description={'Debe introducir una contraseña alfanumérica'} />
                    )}
                </FormControl>

                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <InputLabel htmlFor="passwordRepeat">Repetí tu nueva contraseña</InputLabel>
                    <OutlinedInput
                        id="passwordRepeat"
                        type={values.showPasswordRepeat ? 'text' : 'password'}
                        value={values.passwordRepeat}
                        onChange={handleChange('passwordRepeat')}
                        onBlur={() => isWrongPassword()}
                        sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                        color="info"
                        error={errorPassword}
                        required={true}
                        startAdornment={
                            <InputAdornment position="start">
                                <LockOutlinedIcon />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => handleShowPassword('showPasswordRepeat')}
                                    edge="end">
                                    {values.showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Repetí tu nueva contraseña"
                    />
                {errorPassword && (
                    <FormHelperText sx={{ display: 'flex' }} id="outlined-adornment-amount">
                        <ErrorOutlineIcon color="error" fontSize="small" sx={{ mr: '2%' }} /> Las
                        contraseñas no coinciden
                    </FormHelperText>
                )}
                </FormControl>
            </div>

            <Box width={'100%'}>
                <GrayButton
                    text={'Confirmar'}
                    action={() => onSubmit()}
                    disabled={!threePasswordsValidate(values) || !alphanumericTest(values.password)}
                />

                <BackHomeButton />
            </Box>
            {notConnection && <ErrorModal open={notConnection} handleOpen={() => handleCloseModal()}></ErrorModal>}
        </Box>
    );
};

export default FormChangePassword;
